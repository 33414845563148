import React from "react";
// import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/Header";

import "./Help.css";

import Faqs from "./Faqs";
import HelpPageHeader from "./Hero";

function Help() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page content */}

      <HelpPageHeader />
      <Faqs />
    </div>
  );
}

export default Help;
