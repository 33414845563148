// in test mode for netlify
const stripe = require("stripe")(process.env.REACT_APP_STRIPE_TEST_KEY);

const cancelSubscriptionRenewal = async (subscriptionId) => {
  try {
    const canceledSubscription = await stripe.subscriptions.update(
      subscriptionId,
      {
        cancel_at_period_end: true,
      }
    );

    return canceledSubscription?.current_period_end;
  } catch (error) {
    console.error("Error canceling subscription:", error);
    return false;
  }
};

const getSubscriptionInfo = async (subscriptionId) => {
  try {
    const subscription = await stripe.subscriptions.retrieve(subscriptionId);
    return subscription;
  } catch (error) {
    console.error("Error canceling subscription:", error);
    throw error;
  }
};

export { cancelSubscriptionRenewal, getSubscriptionInfo };
