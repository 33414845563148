import { Dialog, Transition } from "@headlessui/react";
import { Tooltip } from "@mui/material";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import arrow from "../../assets/images/arrow_down.png";
import { storage } from "../../config/firebase";
import Camera from "../../images/icons/camera";
import IconWrapper from "../IconWrapper";
import { playAudio } from "../../utils/btnAudio";

export default function EditRecipe({
  isOpen,
  closeModal,
  openModal,
  close,
  handleCHange,
  value,
  name,
  deleteRecipe,
  handleChangeL,
  listValue,
  lists,
  listName,
  nameD,
  valueD,
  handleCHangeD,
  image,
}) {
  const [updatedImage, setUpdatedImage] = useState(null);
  const [imageBlobUrl, setImageBlobUrl] = useState(image);

  const userId = useSelector((state) => state.tokens.userId);

  let generalObj = {
    id: null,
    name: "General",
    order: 0,
    userId: userId,
  };

  lists = [generalObj, ...lists];

  const { subscriptionId, subScriptionPlan, subscriptionStatus } = useSelector(
    (state) => state.tokens
  );
  const [loading, setLoading] = useState(false);
  const uploadFile = async () => {
    playAudio();
    if (updatedImage) {
      setLoading(true);
      const storageRef = ref(storage, `images/${updatedImage?.name ?? name}`);
      try {
        const snapshot = await uploadBytes(storageRef, updatedImage, {
          contentType: updatedImage?.type,
        });
        const url = await getDownloadURL(snapshot.ref);

        closeModal(url);
        setLoading(false);

        // If you want to store the URL in Firestore, do it here
        // updateDoc(doc(db, "CookBook", id), { imageUrl: url });
      } catch (error) {
        console.error("Error uploading the file", error);
        setLoading(false);
        Swal({
          icon: "info",
          title: error.message,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } else {
      closeModal(image);
    }
  };

  const onImageUploading = (e) => {
    if (e.target.files[0]) {
      setUpdatedImage(e.target.files[0]);
      const blobUrl = URL.createObjectURL(e.target.files[0]);
      setImageBlobUrl(blobUrl);
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50 border" onClose={close}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto h-full">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className="w-full h-full mt-20 max-w-2xl xl:max-w-5xl transform overflow-hidden rounded-2xl
                                 bg-white p-6 text-left align-middle shadow-xl transition-all"
                >
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Edit Recipe
                  </Dialog.Title>
                  <div className="mt-2 flex flex-col">
                    <div className="w-[100px] justify-center relative">
                      <div>
                        <img
                          className="mb-3 rounded-xl w-[100px] h-[100px]"
                          width="100"
                          height="100"
                          src={imageBlobUrl}
                          alt="Icon 03"
                        />

                        <div className="absolute bottom-[55px] right-[-48px] w-[100px] h-[100px] flex justify-center items-center">
                          <label
                            {...(subScriptionPlan !== "Basic" && {
                              htmlFor: "imageInput",
                            })}
                            {...(subScriptionPlan === "Basic" && {
                              onClick: () =>
                                Swal.fire({
                                  icon: "info",
                                  title:
                                    "Upgrade to Explorer or Foodie Membership to edit image",
                                  showConfirmButton: false,
                                  timer: 2000,
                                }),
                            })}
                            className="cursor-pointer"
                          >
                            <Tooltip
                              title={
                                subScriptionPlan === "Basic"
                                  ? "Upgrade to Explorer or Foodie Membership generate new image"
                                  : "Click here to generate new image"
                              }
                              placement="top"
                            >
                              <IconWrapper className="bg-white bg-opacity-50 rounded-full p-1">
                                <Camera className="w-4 h-4" />
                              </IconWrapper>
                            </Tooltip>
                          </label>
                          <input
                            type="file"
                            id="imageInput"
                            accept="image/*"
                            style={{
                              visibility: "hidden",
                              width: "1px",
                              height: "1px",
                            }}
                            onChange={(e) => onImageUploading(e, "E")}
                          />
                        </div>
                        {/* <input
                          type="file"
                          id="imageInput"
                          accept="image/*"
                          // style={{
                          //   visibility: "hidden",
                          //   width: "1px",
                          //   height: "1px",
                          // }}
                          onChange={(e) => onImageUploading(e, "E")}
                        /> */}
                      </div>
                    </div>

                    <input
                      className="px-2 py-2 my-2 border border-gray-500 focus:border-orange-500 "
                      placeholder="Enter a title"
                      value={value}
                      onChange={handleCHange}
                      name={name}
                    />
                    <div className="w-full flex flex-col">
                      <textarea
                        rows={8}
                        className="resize-handle px-2 py-2 mt-2"
                        placeholder="Enter your description"
                        value={valueD}
                        onChange={handleCHangeD}
                        name={nameD}
                      />
                      <p className="w-full text-right lg:flex justify-end pr-2 m-0 hidden">
                        <p>stretch the text area here</p>
                        <img
                          src={arrow}
                          className="w-4 h-4 rotate-[-120deg] ml-1"
                          alt=""
                        />
                      </p>
                    </div>

                    <p className="py-2 font-medium">Add To List</p>
                    <select
                      name={listName}
                      value={listValue}
                      onChange={handleChangeL}
                      className="form-select"
                    >
                      {lists.map((itemValue, index) => {
                        return (
                          <option key={index} value={itemValue.id}>
                            {itemValue.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex text-white justify-center rounded-md border border-transparent !bg-orange-50 px-4 py-2 text-sm font-medium  hover:bg-orange-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={uploadFile}
                      disabled={loading}
                    >
                      Update
                    </button>
                    <button
                      type="button"
                      className="inline-flex text-orange-700 justify-center rounded-md border border-transparent  px-4 py-2 text-sm font-medium  focus-visible:ring-2 "
                      onClick={() => {
                        playAudio();
                        close();
                      }}
                    >
                      Cancel
                    </button>
                    {/* <button
                                            type="button"
                                            className="inline-flex text-white justify-center rounded-md border border-transparent bg-red-400 px-4 py-2 text-sm font-medium  hover:bg-orange-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={() => {
                                                deleteRecipe()
                                                close()
                                            }}
                                        >
                                            Delete
                                        </button> */}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
