import { Button, Modal } from "flowbite-react";

function TermsOfServiceModal({ open, onClose }) {
  return (
    <>
      <Modal show={open} onClose={() => onClose(false)}>
        <Modal.Header
          style={{
            backgroundColor: "#F9FAFB",
            padding: "2rem",
            borderBottom: "1px solid #aaaaaa",
            color: "#2863e3",
          }}
        >
          Terms of Service
        </Modal.Header>
        <Modal.Body
          style={{
            maxHeight: "500px", // Set a maximum height
            overflowY: "auto", // Enable vertical scrolling
          }}
        >
          <div className="space-y-6">
            {/* Terms of Service Section */}
            <h3 style={{ marginTop: "40px" }}>Terms of Service</h3>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              <p>Welcome to Cook-E AI!</p>
              <p>
                These Terms of Service outline the rules and regulations for the
                use of Cook-E AI LLC's Website, located at
                https://www.cookeai.com, and all relevant subdomains.
              </p>
              <p>
                By accessing this website and its subdomains, we assume you
                accept these Terms of Service. Do not continue to use Cook-E AI
                if you do not agree to take all of the Terms of Service stated
                on this page. The following terminology applies to these Terms
                of Service, Privacy Statement and Disclaimer Notice, and all
                Agreements: "Client", "You" and "Your" refers to you, the person
                logging on to this website and compliant with the Company's
                Terms of Service. "The Company", "Ourselves", "We", "Our" and
                "Us", refer to our Company, Cook-E AI LLC. "Party", "Parties",
                or "Us", refer to both the Client and ourselves. All terms refer
                to the offer, acceptance, and consideration of payment necessary
                to undertake the process of our assistance to the Client in the
                most appropriate manner for the express purpose of meeting the
                Client's needs in respect of the provision of the Company's
                stated services, in accordance with and subject to prevailing
                law. Any use of the above terminology or other words in the
                singular, plural, capitalization and/or he/she or they, are
                taken as interchangeable and therefore as referring to the same.
              </p>
            </p>

            {/* Cookies Section */}
            <h3 style={{ marginTop: "40px" }}>Cookies</h3>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              We employ the use of cookies. By accessing Cook-E AI and its
              relevant subdomains, you agree to use cookies in agreement with
              Cook-E AI LLC's Privacy Policy. Most interactive websites use
              cookies to let us retrieve the user's details for each visit.
              Cookies are used by our website to enable the functionality of
              certain areas to make it easier for people visiting our website.
              Some of our affiliate/advertising partners may also use cookies.
            </p>

            {/* License Section */}
            <h3 style={{ marginTop: "40px" }}>License</h3>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              <p>
                Unless otherwise stated, Cook-E AI LLC and/or its licensors own
                the intellectual property rights for all material on Cook-E AI
                and its relevant subdomains. All intellectual property rights
                are reserved. You may access these websites for your own
                personal use subject to restrictions set in these terms and
                conditions.
              </p>
              <p>
                By using our Service, you are granted a limited, non-exclusive,
                non-transferable, non-sublicensable, revocable license to access
                and use the Service for your own personal or commercial
                purposes. This license includes the right to share and republish
                the content you create using our Service, provided that you
                clearly attribute the content to its original source,
                acknowledge Cook-E AI and/or any other website or API source
                used in the creation of the content, and comply with their
                respective terms of service.
              </p>
              <p>
                Cook-E AI LLC uses third-party APIs to provide its Service. By
                using our Service, you agree to abide by the terms and
                conditions applicable to these third-party services, and you
                acknowledge that Cook-E AI LLC is not liable for any violations
                of such Terms of Service made by you. Please ensure that you
                review the terms of service for any third-party APIs or websites
                used in our Service and that you comply with them. Cook-E AI LLC
                reserves the right to terminate your access to the Service if it
                finds that you are in breach of these Terms of Service or those
                of any third-party service.
              </p>
            </p>

            {/* Subscription and Refunds Section */}
            <h3 style={{ marginTop: "40px" }}>Subscription and Refunds</h3>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              Cook-E AI offers a subscription model for its services. By
              subscribing to our services, you agree to be billed on a recurring
              basis according to the plan you have selected. We only offer
              refunds for the unused portion of monthly plans, for up to 3 days
              after the payment has been made. If you decide to cancel your
              subscription, the cancellation will be effective at the end of
              your current billing cycle, and you will not be charged again.
            </p>

            {/* Communication Section */}
            <h3 style={{ marginTop: "40px" }}>Communication</h3>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              By signing up, you consent to receive communications from us
              regarding your account, product activities, and other marketing
              materials. You can unsubscribe from our marketing communications
              at any time by following the instructions provided in those
              communications or by contacting us directly at
              support@cookeai.com.
            </p>

            {/* Email Marketing Section */}
            <h3 style={{ marginTop: "40px" }}>Email Marketing</h3>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              By signing up you consent to us sending you commercial emails
              about our products, services, offers, and other promotions that we
              think may interest you. You can withdraw your consent at any time
              by sending a request to support@cookeai.com or by clicking the
              unsubscribe link in the emails. We use third-party software and
              platforms to manage our email marketing campaigns. These third
              parties may access and use your email address and other
              information to enable the sending of emails on our behalf. You
              consent to us sharing your information with third-party email
              marketing platforms to enable our email marketing activities.
            </p>

            {/* Changes to Terms of Service Section */}
            <h3 style={{ marginTop: "40px" }}>Changes to Terms of Service</h3>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              <p>
                Cook-E AI, LLC reserves the right, in its sole discretion, to
                modify or replace any of these Terms of Service or change,
                suspend, or discontinue the Service (including without
                limitation, the availability of any feature, database, or
                content) at any time by posting a notice on the Cook-E AI
                websites or by sending you an email. Your continued use of the
                Service following the posting of any changes to the Terms and
                Conditions constitutes acceptance of those changes.
              </p>
              <p>
                Please refer to the original Terms of Service above for sections
                on: Comments, Hyperlinking to our Content, iFrames, Content
                Liability, Reservation of Rights, Removal of links from our
                website, and Disclaimer. These sections remain unchanged and
                apply to both CookeAI.com and its relevant subdomains.
              </p>
              <p>
                Should you have any questions or concerns regarding these Terms
                and Conditions, please do not hesitate to contact us a
                tsupport@cookeai.com
              </p>
            </p>

            {/* Age Requirements Section */}
            <h3 style={{ marginTop: "40px" }}>Age Requirements</h3>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              By accessing the Services, you confirm that you are at least 13
              years old and meet the minimum age of digital consent in your
              country. If you are not old enough to consent to our Terms of
              Service in your country, your parent or guardian must agree to
              this Agreement on your behalf. Please ask your parent or guardian
              to read these terms with you. If you are a parent or legal
              guardian, and you allow your teenager to use the Services, then
              these terms also apply to you, and you are responsible for your
              teenager's activity on the Services. No assurances are made as to
              the suitability of the Services for you.
            </p>

            {/* Disclaimer Section */}
            <h3
              style={{
                color: "#2863e3",
                fontWeight: "bold",
                fontSize: "1.5rem",
              }}
            >
              Disclaimer
            </h3>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              ALL USE OF THE SERVICE AND ANY CONTENT IS UNDERTAKEN ENTIRELY AT
              YOUR OWN RISK. THE SERVICE (INCLUDING, WITHOUT LIMITATION, THE
              COOKEAI WEBSITES AND ANY CONTENT) IS PROVIDED "AS IS" AND "AS
              AVAILABLE" AND IS WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
              IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
              TITLE, NON-INFRINGEMENT, MERCHANTABILITY, AND FITNESS FOR A
              PARTICULAR PURPOSE, AND ANY WARRANTIES IMPLIED BY ANY COURSE OF
              PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE EXPRESSLY
              DISCLAIMED. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN
              IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO
              YOU.
            </p>

            {/* Miscellaneous Section */}
            <h3 style={{ marginTop: "40px" }}>Miscellaneous</h3>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              The Terms of Service are the entire agreement between you and
              Cook-E AI, LLC with respect to the Service, and supersede all
              prior or contemporaneous communications and proposals (whether
              oral, written or electronic) between you and Cook-E AI, LLC with
              respect to the Service. If any provision of the Terms and
              Conditions is found to be unenforceable or invalid, that provision
              will be limited or eliminated to the minimum extent necessary so
              that the Terms of Service will otherwise remain in full force and
              effect and enforceable. The failure of either party to exercise in
              any respect any right provided for herein shall not be deemed a
              waiver of any further rights hereunder. Cook-E AI, LLC shall not
              be liable for any failure to perform its obligations hereunder due
              to any cause beyond Cook-E AI, LLC's reasonable control. The Terms
              of Service are personal to you, and are not assignable or
              transferable by you except with Cook-E AI, LLC's prior written
              consent. Cook-E AI, LLC may assign, transfer or delegate any of
              its rights and obligations hereunder without consent. No agency,
              partnership, joint venture, or employment relationship is created
              as a result of the Terms of Service, and neither party has any
              authority of any kind to bind the other in any respect.
            </p>

            {/* Contact Information */}
            <h3 style={{ marginTop: "40px" }}>Contact Information</h3>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              Should you have any questions or concerns regarding these Terms
              and Conditions, please do not hesitate to contact us at
              support@cookeai.com
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="primary"
            onClick={() => onClose(false)}
            style={{
              backgroundColor: "#2863e3",
              color: "#ffffff",
              border: "1px solid #2863e3",
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TermsOfServiceModal;
