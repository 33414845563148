import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  recipes: [],
  prompt: "",
  dish1: "",
  dish2: "",
  dish3: "",
  images: {
    1: "",
    2: "",
    3: "",
  },
  dish1Image: "",
  dish2Image: "",
  dish3Image: "",
};

const dishSlice = createSlice({
  name: "dishes",
  initialState: {
    response: "",
    dish1: "",
    dish2: "",
    dish3: "",
    dish1Image: "",
    dish2Image: "",
    dish3Image: "",
    images: {
      1: "",
      2: "",
      3: "",
    },
  },
  reducers: {
    setDish1: (state, action) => {
      return { ...state, dish1: action.payload };
    },
    setDish2: (state, action) => {
      return { ...state, dish2: action.payload };
    },
    setDish3: (state, action) => {
      return { ...state, dish3: action.payload };
    },
    setDish1Image: (state, action) => {
      return { ...state, dish1Image: action.payload };
    },
    setDish2Image: (state, action) => {
      return { ...state, dish2Image: action.payload };
    },
    setDish3Image: (state, action) => {
      return { ...state, dish3Image: action.payload };
    },
    setDishImage: (state, action) => {
      return {
        ...state,
        images: {
          ...state.images,
          [action.payload.dishNumber]: action.payload,
        },
      };
    },
    setResponse: (state, action) => {
      return { ...state, response: action.payload };
    },
    resetDishState: () => {
      return {
        response: "",
        dish1: "",
        dish2: "",
        dish3: "",
      };
    },
  },
});

export const dishReducer = dishSlice.reducer;
export const {
  setDish1,
  setDish2,
  setDish3,
  setResponse,
  resetDishState,
  setDish1Image,
  setDish2Image,
  setDish3Image,
} = dishSlice.actions;
// export default reducer;
