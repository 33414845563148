import { Popover, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import {
  FacebookIcon,
  FacebookShareButton,
  PinterestShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { SHARE_STRING } from "../utils/constants";

function shareToPinterest(url, imageUrl) {
  const urlToShare = url;
  const description = "Check out this amazing content!"; // Customize the description
  const mediaUrl = imageUrl;

  window.PinUtils.pinOne({
    url: urlToShare,
    description: description,
    media: mediaUrl,
  });
}
const SocialShareButton = ({ shareUrl, image, title }) => {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button>
            <button
              type="button"
              className=" inline-flex text-primary-600 border-2 border-primary-600 justify-center rounded-md px-4 py-2 text-sm font-medium  focus-visible:ring-2 "
              disabled={!shareUrl}
              onClick={() => console.log("heheeh")}
            >
              Share
            </button>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-[200px] -translate-x-[35px] !-translate-y-[200px] transform px-4 sm:px-0 top-[80px]">
              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                <div className="relative  gap-8 bg-white p-2">
                  <FacebookShareButton
                    // url={"http://github.com"}
                    url={shareUrl}
                    title={title}
                    className="w-full rounded"
                  >
                    <div className="flex items-center gap-2 hover:bg-slate-300 cursor-pointer w-full p-1 rounded">
                      <FacebookIcon size={16} round />{" "}
                      <span className="text-sm">Facebook</span>
                    </div>
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={SHARE_STRING + shareUrl}
                    className=" w-full rounded"
                  >
                    <div className="flex items-center gap-2 hover:bg-slate-300 cursor-pointer w-full p-1 rounded">
                      <TwitterIcon size={16} round />{" "}
                      <span className="text-sm">Twitter</span>
                    </div>
                  </TwitterShareButton>
                  <WhatsappShareButton
                    url={SHARE_STRING + shareUrl}
                    className=" w-full rounded"
                  >
                    <div className="flex items-center gap-2 hover:bg-slate-300 cursor-pointer w-full p-1 rounded">
                      <WhatsappIcon size={16} round />{" "}
                      <span className="text-sm">Whatsapp</span>
                    </div>
                  </WhatsappShareButton>

                  {/* <PinterestShareButton
                    url={image}
                    media={image}
                    description={""}
                  >
                    {/* <img src={PinterestIcon} alt="Pinterest Share" />
                    Share on Pinterest
                  </PinterestShareButton> */}
                </div>
              </div>
            </Popover.Panel>
          </Transition>{" "}
        </>
      )}
    </Popover>
  );
};

export default SocialShareButton;
