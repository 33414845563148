import "slick-carousel/slick/slick.css";
import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "../misc/Headings.js";
import { Container, ContentWithPaddingXl } from "../misc/Layouts.js";
import { ReactComponent as StarIconBase } from "../images/star-icon.svg";
import { ReactComponent as ArrowLeftIcon } from "../images/arrow-left-3-icon.svg";
import { ReactComponent as ArrowRightIcon } from "../images/arrow-right-3-icon.svg";

const TextColumn = tw.div`w-full mx-auto flex flex-col items-center `;

const Subheading = tw(SubheadingBase)`text-center text-primary-600 font-bold`;

const Description = tw.p`mt-4 text-center text-sm md:text-base lg:text-lg font-medium leading-relaxed text-white max-w-3xl`;

const TestimonialSlider = styled(Slider)`
  ${tw`w-1/2 mt-6`}
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;

const Testimonial = tw.div`outline-none h-full flex! flex-col bg-white p-8 rounded-lg shadow-md w-full max-w-md mx-auto`;

const StarsContainer = styled.div`
  ${tw`flex justify-center md:justify-start mb-4`}
`;
const StarIcon = tw(
  StarIconBase
)`inline-block w-5 h-5 text-orange-400 fill-current mr-1 last:mr-0`;
const TestimonialHeading = tw.div`mt-4 text-xl font-bold text-gray-800`;
const Quote = tw.blockquote`mt-4 mb-8 sm:mb-10 leading-relaxed font-medium text-gray-600`;

const CustomerInfoAndControlsContainer = tw.div`mt-auto flex justify-between items-center flex-col sm:flex-row`;

const CustomerInfo = tw.div`flex flex-col sm:flex-row items-center justify-center lg:justify-start`;
const CustomerProfilePicture = tw.img`rounded-full w-16 h-16 sm:w-20 sm:h-20`;
const CustomerTextInfo = tw.div`text-center md:text-left sm:ml-6 mt-2 sm:mt-0`;
const CustomerName = tw.h5`font-bold text-xl text-gray-800`;
const CustomerTitle = tw.p`font-medium text-gray-500`;

const Controls = styled.div`
  ${tw`flex mt-8 sm:mt-0`}
  .divider {
    ${tw`my-3 border-r`}
  }
`;
const ControlButton = styled.button`
  ${tw`mx-3 p-4 rounded-full transition duration-300 bg-gray-200 hover:bg-primary-600 hover:text-white text-black focus:outline-none focus:shadow-inner`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

export default ({
  subheading = "Testimonials",
  heading1 = "Our Clients",
  heading2 = "Love Us.",
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad minim veniam.",
  textOnLeft = false,
  testimonials = [
    {
      stars: 5,
      profileImageSrc:
        "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
      heading: "Amazing User Experience",
      quote:
        "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
      customerName: "Charlotte Hale",
      customerTitle: "CEO, Delos Inc.",
    },
    {
      stars: 5,
      profileImageSrc:
        "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
      heading: "Love the Developer Experience and Design Principles!",
      quote:
        "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
      customerName: "Adam Cuppy",
      customerTitle: "Founder, EventsNYC",
    },
  ],
}) => {
  const [sliderRef, setSliderRef] = useState(null);

  return (
    <Container>
      <ContentWithPaddingXl>
        <TextColumn textOnLeft={textOnLeft}>
          <Subheading>{subheading}</Subheading>

          <Description>{description}</Description>
          <TestimonialSlider arrows={false} ref={setSliderRef}>
            {testimonials.map((testimonial, index) => (
              <Testimonial key={index}>
                <StarsContainer>
                  {Array.from({ length: testimonial.stars }).map(
                    (_, indexIcon) => (
                      <StarIcon key={indexIcon} />
                    )
                  )}
                </StarsContainer>
                <TestimonialHeading>{testimonial.heading}</TestimonialHeading>
                <Quote>{testimonial.quote}</Quote>
                <CustomerInfoAndControlsContainer>
                  <CustomerInfo>
                    <CustomerProfilePicture
                      src={testimonial.profileImageSrc}
                      alt={testimonial.customerName}
                    />
                    <CustomerTextInfo>
                      <CustomerName>{testimonial.customerName}</CustomerName>
                      <CustomerTitle>{testimonial.customerTitle}</CustomerTitle>
                    </CustomerTextInfo>
                  </CustomerInfo>
                  <Controls>
                    <ControlButton onClick={sliderRef?.slickPrev}>
                      <ArrowLeftIcon />
                    </ControlButton>
                    <div className="divider" />
                    <ControlButton onClick={sliderRef?.slickNext}>
                      <ArrowRightIcon />
                    </ControlButton>
                  </Controls>
                </CustomerInfoAndControlsContainer>
              </Testimonial>
            ))}
          </TestimonialSlider>
        </TextColumn>
      </ContentWithPaddingXl>
    </Container>
  );
};
