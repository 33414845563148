import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import logo from "../assets/images/logo.png";
import Transition from "../utils/Transition";

import { getAuth } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import profileImg from "../assets/images/COOK-E.png";
import homeImageBackground from "../assets/images/homeBackground.png";
import { switchLoginStatus } from "../redux/auth";
import { clearTokens } from "../redux/tokens";
import { playAudio } from "../utils/btnAudio";

function Header({ profilePhoto }) {
  const auth = getAuth();
  const location = useLocation();
  const { pathname: currentPath } = location;
  const currentUser = auth.currentUser;
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [photoURL, setPhotoURL] = useState("");

  useEffect(() => {
    if (profilePhoto) {
      setPhotoURL(profilePhoto);
    } else {
      const photoURL = localStorage.getItem("photoURL");

      if (
        photoURL !== null &&
        photoURL !== undefined &&
        photoURL !== "" &&
        photoURL !== "undefined"
      ) {
        setPhotoURL(photoURL);
      } else {
        setPhotoURL(profileImg);
      }
    }
  }, [profilePhoto]);

  const navigate = useNavigate();

  const { isLoggedIn } = useSelector((state) => state.auth);

  const profileMenuButtonRef = useRef();

  const toggleProfileMenu = () => {
    setIsProfileMenuOpen(!isProfileMenuOpen);
  };

  useEffect(() => {
    const clickOutsideHandler = ({ target }) => {
      if (
        isProfileMenuOpen &&
        profileMenuButtonRef.current &&
        !profileMenuButtonRef.current.contains(target)
      ) {
        setIsProfileMenuOpen(false);
      }
    };

    document.addEventListener("click", clickOutsideHandler);
    return () => document.removeEventListener("click", clickOutsideHandler);
  }, [isProfileMenuOpen]);

  const dispatch = useDispatch();
  const logout = () => {
    playAudio();
    // await signOut(auth);
    dispatch(clearTokens());
    dispatch(switchLoginStatus(false));
    navigate("/");
  };

  const trigger = useRef(null);
  const mobileNav = useRef(null);

  // close the mobile menu on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNav.current || !trigger.current) return;
      if (
        !mobileNavOpen ||
        mobileNav.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setMobileNavOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  // Handle light modes
  const [darkMode, setDarkMode] = useState(() => {
    const dark = localStorage.getItem("dark-mode");
    if (dark === null) {
      return true;
    } else {
      return dark === "true";
    }
  });

  const navArr = [
    { name: "Food Ideas", slug: "/discover-dishes" },
    { name: "Shop", slug: "/shop" },
    { name: "Cookbook", slug: "/personal-cookbook" },
    { name: "Help", slug: "/help" },
    { name: "The Chopping Block (Blog)", slug: "/blogs" },
  ];

  const onNavItemClick = (slug) => {
    if (currentPath == "/dishe-details" || currentPath == "get-recipe") {
      Swal.fire({
        title: "Leave Page?",
        text: "You might lose your token if you leave this page without saving the recipe",
        icon: "warning",
        showDenyButton: true,
        confirmButtonText: "Leave",
        denyButtonText: `Stay`,
        showCloseButton: true,
        showCancelButton: false,
        dangerMode: true,
      }).then(async (result) => {
        if (result.isDenied) {
          return;
        } else {
          navigate(slug);
        }
      });
    } else {
      navigate(slug);
    }
  };
  return (
    <header className=" shadow-xl shadow-gray-700 fixed  opacity-90   w-full z-30">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="flex items-center justify-between py-[12px]">
          <div className="absolute h-auto inset-0 box-content -z-1">
            <img
              className="absolute inset-0 w-full h-full object-cover"
              src={homeImageBackground}
              width="1440"
              height="577"
              alt="About"
            />
            {/* <div className="absolute inset-0 opacity-25   from-gray-400" aria-hidden="true"></div> */}
          </div>
          {/* Site branding */}
          <div className="shrink-0 mr-5">
            {/* Logo */}
            <Link to="/" className="block" aria-label="Cruip">
              <div className="flex space-x-3">
                {" "}
                <img src={logo} alt="logo" className="w-8 h-8" />
                <p className="flex justify-center items-center text-xl font-bold text-white">
                  COOK-E
                </p>
              </div>
            </Link>
          </div>

          {/* Desktop navigation */}
          <nav className="hidden md:flex md:grow">
            {/* Desktop menu links */}
            {true ? (
              <>
                {/* <ul className="flex  grow flex-wrap items-center font-medium">
              //   <li>
              //     <Link
              //       to="/discover-dishes"
              //       className="text-white hover:text-primary-600 dark:text-gray-300 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out"
              //     >
              //       Food Ideas
              //     </Link>
              //   </li>
              //   <li>
              //     <Link
              //       to="/shop"
              //       className="text-white hover:text-primary-600 dark:text-gray-300 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out"
              //     >
              //       Shop
              //     </Link>
              //   </li>
              //   <li>
              //     <Link
              //       to="/chopping-block"
              //       className="text-white hover:text-primary-600 dark:text-gray-300 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out"
              //     >
              //       Chopping Block
              //     </Link>
              //   </li>
              //   <li>
              //     <Link
              //       to="/personal-cookbook"
              //       className="text-white hover:text-primary-600 dark:text-gray-300 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out"
              //     >
              //       Cookbook
              //     </Link>
              //   </li>
              //   {/* 1st level: hover
            // </ul>*/}
                <ul className="flex grow flex-wrap items-center font-medium">
                  {navArr.map((item, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => onNavItemClick(item?.slug)}
                      >
                        <span
                          className={`  px-5 py-2 flex items-center transition duration-150 ease-in-out cursor-pointer hover:text-primary-600 dark:text-gray-300 dark:hover:text-gray-100 ${
                            currentPath == item.slug
                              ? " text-primary-600"
                              : "text-white hover:text-primary-600 dark:text-gray-300 dark:hover:text-gray-100"
                          }`}
                          // style={{
                          //   color:
                          // }}
                        >
                          {item.name}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </>
            ) : (
              <ul className="flex  grow flex-wrap items-center font-medium"></ul>
            )}

            {/* Desktop CTA on the right */}
            {!isLoggedIn && (
              <ul className="flex justify-end flex-wrap items-center border-2 border-primary-600 px-8 rounded-md">
                <li>
                  <Link to="/signin" className="btn-sm text-primary-600 ">
                    Login
                  </Link>
                </li>
              </ul>
            )}

            {/* Profile Menu */}

            {isLoggedIn && (
              <div class="relative ml-3 mt-1">
                <div>
                  <button
                    ref={profileMenuButtonRef}
                    onMouseEnter={toggleProfileMenu}
                    type="button"
                    className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                    id="user-menu-button"
                    aria-expanded="false"
                    aria-haspopup="true"
                  >
                    <span class="absolute -inset-1.5"></span>
                    <img
                      class="h-8 w-8 rounded-full object-contain"
                      src={photoURL || profileImg}
                      alt=""
                    />
                  </button>
                </div>

                {/* Profile dropdown */}
                {isProfileMenuOpen && (
                  <div
                    class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu-button"
                    tabindex="-1"
                  >
                    <button
                      href="/profile"
                      class="block px-4 py-2 text-sm text-gray-700"
                      role="menuitem"
                      tabindex="-1"
                      id="user-menu-item-0"
                      onClick={() => {
                        playAudio();
                        navigate(`/profile/${currentUser.uid}`);
                      }}
                    >
                      Your Profile
                    </button>
                    <button
                      href="/feedback"
                      class="block px-4 py-2 text-sm text-gray-700"
                      role="menuitem"
                      tabindex="-1"
                      id="user-menu-item-1"
                      onClick={() => {
                        playAudio();
                        navigate("/feedback");
                      }}
                    >
                      Contact Us
                    </button>
                    <button
                      href="/settings"
                      class="block px-4 py-2 text-sm text-gray-700"
                      role="menuitem"
                      tabindex="-1"
                      id="user-menu-item-1"
                      onClick={() => {
                        playAudio();
                        navigate("/settings");
                      }}
                    >
                      Settings
                    </button>

                    <button
                      class="block px-4 py-2 text-sm text-gray-700"
                      role="menuitem"
                      tabindex="-1"
                      id="user-menu-item-2"
                      onClick={logout}
                    >
                      Sign out
                    </button>
                  </div>
                )}
              </div>
            )}
          </nav>

          {/* Mobile menu */}
          <div className="inline-flex md:hidden ">
            {/* Hamburger button */}
            <button
              ref={trigger}
              className={`hamburger ${mobileNavOpen && "active"}`}
              aria-controls="mobile-nav"
              aria-expanded={mobileNavOpen}
              onClick={() => {
                playAudio();
                setMobileNavOpen(!mobileNavOpen);
              }}
            >
              <span className="sr-only">Menu</span>
              <svg
                className="w-6 h-6 fill-current text-primary-600 hover:animate-pulse dark:text-gray-300 dark:hover:text-gray-100 transition duration-150 ease-in-out"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="4" width="24" height="2" rx="1" />
                <rect y="11" width="24" height="2" rx="1" />
                <rect y="18" width="24" height="2" rx="1" />
              </svg>
            </button>

            {/*Mobile navigation */}
            <Transition
              show={mobileNavOpen}
              tag="ul"
              className="fixed top-0 h-screen z-20 left-0 w-full  -ml-16 overflow-scroll   shadow-lg"
              enter="transition ease-out duration-200 transform"
              enterStart="opacity-0 -translate-x-full"
              enterEnd="opacity-100 translate-x-0"
              leave="transition ease-out duration-200"
              leaveStart="opacity-100"
              leaveEnd="opacity-0"
            >
              <nav
                id="mobile-nav"
                ref={mobileNav}
                className="fixed top-0 h-screen z-0 left-0 w-full opacity-100  -ml-16 bg-black shadow-lg no-scrollbar"
              >
                {/* Background image */}
                <div className="absolute inset-0 h-auto box-content -z-1">
                  <img
                    className="absolute inset-0 opacity-20 w-full h-full object-cover "
                    src={homeImageBackground}
                    width="1440"
                    height="577"
                    alt="About"
                  />
                  {/* <div className="absolute inset-0 bg-gradient-to-t from-white dark:from-gray-900" aria-hidden="true"></div> */}
                </div>
                <div className="py-6  pl-[64px]">
                  {/* Logo */}
                  <Link
                    to="/"
                    className="flex justify-center py-6 my-4"
                    aria-label="Cruip"
                  >
                    <img src={logo} alt="logo" className="w-12 h-12" />
                  </Link>
                  {/* Links */}
                  {navArr.map((item, index) => (
                    <li key={index}>
                      <Link
                        to={item.slug}
                        className=" w-full flex   bg-white shadow-xl  bg-clip-padding bg-opacity-25  justify-center text-center text-white
                       dark:text-gray-300 dark:hover:text-gray-100 py-3 
                       
                       "
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                  {/* <ul className="space-y-4">
                    <li>
                      <Link
                        to="/discover-dishes"
                        className=" w-full flex   bg-white shadow-xl  bg-clip-padding bg-opacity-25  justify-center text-center text-white
                       dark:text-gray-300 dark:hover:text-gray-100 py-3 
                       
                       "
                      >
                        Food Ideas
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/shop"
                        className=" w-full flex bg-white shadow-xl  bg-clip-padding bg-opacity-25  justify-center text-center text-white
                       dark:text-gray-300 dark:hover:text-gray-100 py-3
                       
                       "
                      >
                        Shop
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/chopping-block"
                        className=" w-full flex bg-white shadow-xl  bg-clip-padding bg-opacity-25  justify-center text-center text-white
                       dark:text-gray-300 dark:hover:text-gray-100 py-3
                       
                       "
                      >
                        Chopping Block
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/personal-cookbook"
                        className=" w-full flex bg-white shadow-xl  bg-clip-padding bg-opacity-25  justify-center text-center text-white
                       dark:text-gray-300 dark:hover:text-gray-100 py-3
                       
                       "
                      >
                        CookBook
                      </Link>
                    </li>
                    {/* <li className="py-2 my-2 border-t border-b border-gray-200 dark:border-gray-800">
                      <span className="flex text-gray-600 dark:text-gray-400 py-2">Resources</span>
                      <ul className="pl-4">
                        <li>
                          <Link
                            to="/help"
                            className="text-sm flex font-medium text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100 py-2"
                          >
                            Help center
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/404"
                            className="text-sm flex font-medium text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100 py-2"
                          >
                            404
                          </Link>
                        </li>
                      </ul>
                    </li> 
                    {isLoggedIn ? (
                      <li>
                        <Link
                          to="/"
                          onClick={logout}
                          className="font-medium w-3/5 mx-[20%] flex items-center justify-center border border-transparent px-4 py-2 my-2 rounded text-white bg-teal-500 hover:bg-teal-400 transition duration-150 ease-in-out"
                        >
                          Logout
                        </Link>
                      </li>
                    ) : (
                      <li>
                        <Link
                          to="/signin"
                          className="font-medium w-3/5 mx-[20%] flex items-center justify-center border border-transparent px-4 py-2 my-2 rounded text-white bg-teal-500 hover:bg-teal-400 transition duration-150 ease-in-out"
                        >
                          Login
                        </Link>
                      </li>
                    )}
                  </ul> */}
                </div>
              </nav>
            </Transition>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
