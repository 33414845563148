import React, { useState } from "react";
import homeImageBackground from "../assets/images/homeBackground.png";
import { stripeCheckoutSession } from "../stripe/create_session";
import MembershipCard from "./MembershipCard";

import { query } from "firebase/database";
import { collection, doc, getDocs, updateDoc, where } from "firebase/firestore";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import save_image from "../assets/images/save.svg";
import { firestoredDB } from "../config/firebase";

import { updateUserSubscriptionPlan } from "../utils/stripeStatus";

function PricingTables({ notRequired }) {
  const uid = localStorage.getItem("uid");

  const [value, setValue] = useState(false);
  const { tokens } = useSelector((state) => state);
  const [priceOutput] = useState({
    plan1: {
      false: ["$", "14.99", "/mo"],
      true: ["$", "129.99", "/yr"],
      title: "Basic Membership",
    },
    plan2: {
      false: ["$", "24.99", "/mo"],
      true: ["$", "219.99", "/yr"],
      title: "Explorer Membership",
    },
    plan3: {
      false: ["$", "34.99", "/mo"],
      true: ["$", "307.99", "/yr"],
      title: "Foodie Membership",
    },
    plan4: {
      false: ["$", "4.99", ""],
      true: ["$", "4.99", ""],
      title: "A La Carte",
    },
  });

  // price, title, uid, total_search_tokens, total_download_tokens
  const handleBuySubscription = async (
    price,
    title,
    total_search_tokens,
    total_download_tokens,
    setLoading
  ) => {
    setLoading(true);
    if (tokens?.subscriptionStatus) {
      Swal.fire({
        icon: "info",
        title: `You Already have an active subscription`,
        showConfirmButton: false,
        timer: 5000,
        confirmButtonColor: "#f0481a",
      });
    } else {
      const interval = value === true ? "year" : "month";
      const response = await stripeCheckoutSession(
        price,
        title,
        uid,
        total_search_tokens,
        total_download_tokens,
        interval
      );

      if (response) {
        const firebaseRes = await updateUserSubscriptionPlan(
          firestoredDB,
          collection,
          query,
          where,
          getDocs,
          updateDoc,
          doc,
          uid,
          title
        );
        if (firebaseRes) {
          window.location.href = response.url;
        }
      }
    }
    setLoading(false);
  };

  return (
    <section className="h-full">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className={!notRequired && `pt-24 pb-6 md:pt-32`}>
          {!notRequired && (
            <div className="absolute h-auto inset-0  pt-16 box-content -z-1">
              <img
                className="absolute inset-0 w-full h-full object-cover"
                src={homeImageBackground}
                alt="About"
              />
              <div
                className="absolute inset-0 bg-gradient-to-t  from-gray-700 dark:from-gray-900"
                aria-hidden="true"
              ></div>
            </div>
          )}

          {/* Pricing tables */}
          <div className="relative">
            {/* Pricing toggle */}
            <div
              className="flex flex-col justify-center items-center w-fit mx-auto"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <img src={save_image} className="w-20 ml-auto" alt="" />
              <div className="inline-flex items-center">
                <div className="text-primary-600 font-medium mr-3">
                  Billed Monthly
                </div>
                <div className="form-switch">
                  <input
                    type="checkbox"
                    name="pricing-toggle"
                    id="pricing-toggle"
                    className="sr-only"
                    checked={value}
                    onChange={() => setValue(!value)}
                  />
                  <label className="bg-gray-600" htmlFor="pricing-toggle">
                    <span className="bg-gray-200" aria-hidden="true"></span>
                    <span className="sr-only">Enable to see yearly prices</span>
                  </label>
                </div>
                <div className="text-primary-600 font-medium ml-3">
                  Billed Annually
                </div>
              </div>
            </div>
            <div
              className={` pt-2  rounded-md  mx-auto grid gap-8 sm:grid-cols-1 ${
                !notRequired ? "md:grid-cols-2 " : "md:grid-cols-3 "
              }
                
                ${
                  !notRequired ? "lg:grid-cols-4 " : "lg:grid-cols-3 "
                } lg:gap-6 items-start lg:max-w-none`}
            >
              {/* Card 1 */}
              <MembershipCard
                title={priceOutput.plan1.title}
                download_limit={40}
                search_limit={300}
                view_react={true}
                priceOutput={priceOutput.plan1} ///priceOutput object
                price={priceOutput.plan1[value][1]} //price
                value={value} // index for priceOutput
                handleBuySubscription={handleBuySubscription}
                description={
                  "Ideal for individuals who want to find and dabble with new recipes."
                }
              />

              {/* Card 2 */}
              <MembershipCard
                title={priceOutput.plan2.title}
                download_limit={100}
                post_chopping_block={true}
                view_react={true}
                post={true}
                handleBuySubscription={handleBuySubscription}
                priceOutput={priceOutput.plan2}
                value={value}
                search_limit={700}
                price={priceOutput.plan2[value][1]}
                description={
                  "Ideal for individuals who want to explore and create the recipes of the world."
                }
              />

              {/* Card 3 */}
              <MembershipCard
                title={priceOutput.plan3.title}
                download_limit={150}
                search_limit={1200}
                post_chopping_block={true}
                view_react={true}
                post={true}
                handleBuySubscription={handleBuySubscription}
                priceOutput={priceOutput.plan3}
                value={value}
                price={priceOutput.plan3[value][1]}
                description={
                  "Ideal for individuals or businesses who want to explore, create and frequently share."
                }
              />
              {!notRequired && (
                <MembershipCard
                  title={priceOutput.plan4.title}
                  download_limit={20}
                  handleBuySubscription={handleBuySubscription}
                  priceOutput={priceOutput.plan4}
                  value={value}
                  search_limit={150}
                  price={priceOutput.plan4[value][1]}
                  description={
                    "Ideal for individuals or businesses who want to explore, create and frequently share."
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PricingTables;
