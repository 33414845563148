import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Swal from "sweetalert2";

// Asset imports
import homeImageBackground from "../assets/images/homeBackground.png";

// Component imports
import DisheOption from "../components/DisheOption";
import Header from "../components/Header";

// Redux action imports
import { resetDishState } from "../redux/dishes";
import { clearImage } from "../redux/image";
import { setIngredients } from "../redux/ingredients";
import { clearInstructions } from "../redux/instructions";
import { setPrompt } from "../redux/prompt";
import {
  clearAllDishImages,
  clearTitle,
  resetImageGenerationCount,
} from "../redux/title";
import { playAudio } from "../utils/btnAudio";

export default function DiscoverDishes() {
  const { ingredients } = useSelector((state) => state.ingredients);
  const {
    user: { emailVerified },
  } = useSelector((state) => state.auth.user);
  const abc = useSelector((state) => state.tokens);
  const {
    totalDownloadTokens,
    totalSearchTokens,
    usedDownloadTokens,
    usedSearchTokens,
    totalRecipeTokens,
    usedRecipeTokens,
  } = useSelector((state) => state.tokens);
  console.log(abc);

  const initialState = {
    Culture: ingredients?.Culture || "",
    IncludedIngredients: ingredients?.IncludedIngredients || "",
    ExcludedIngredients: ingredients?.ExcludedIngredients || "",
    Description: ingredients?.Description || "",
    Occasion: ingredients?.Occasion || "",
    Language: ingredients?.Language || "",
    FreeFlow: ingredients?.FreeFlow || "",
  };

  const [values, setValues] = useState(initialState);
  const [isDishePages, setDishesPage] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // If email is not verified

  useEffect(() => {
    if (!emailVerified) {
      navigate("/verify-email");
    }
    dispatch(resetDishState());
    dispatch(clearTitle());
    dispatch(clearInstructions());
    dispatch(clearImage());
  }, []);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    playAudio();
    e.preventDefault();
    // Data
    const {
      Culture,
      IncludedIngredients,
      ExcludedIngredients,
      Description,
      Occasion,
      Language,
      FreeFlow,
    } = values;

    // const generatePrompt = ({
    //   Culture,
    //   IncludedIngredients,
    //   ExcludedIngredients,
    //   Description,
    //   Occasion,
    //   FreeFlow,
    //   Language,
    // }) => {
    //   let prompt = `Now generate dishes which has following attributes
    //   Culture: ${Culture},
    //   IncludedIngredients: ${IncludedIngredients},
    //   ExcludedIngredients: ${ExcludedIngredients},
    //   Description: ${Description},
    //   Occasion: ${Occasion},
    //   FreeFlow: ${FreeFlow},
    //   Language: ${Language},

    //   Let's cook up something delicious!
    //   ${Culture} cuisine offers a wide array of hearty and delicious vegetarian dishes that can satisfy even the most discerning palates. These three creamy dishes featuring mushrooms and onions are perfect for a satisfying lunch, showcasing the depth and versatility of vegetarian cooking in the United States.

    //     ${Culture} dishes ${
    //     IncludedIngredients
    //       ? ` that include the ingredients ${IncludedIngredients}`
    //       : ""
    //   }${
    //     ExcludedIngredients ? `, but do not contain ${ExcludedIngredients}` : ""
    //   }.${Description ? ` The dishes should be ${Description}` : ""}${
    //     Occasion ? ` and be suitable for ${Occasion}.` : ""
    //   }${FreeFlow ? ` ${FreeFlow}.` : ""}${
    //     Language ? ` Please write the dish in ${Language}.` : ""
    //   }`;
    //   return prompt;
    // };

    // // Example usage
    // const prompt = generatePrompt({
    //   Culture: Culture,
    //   IncludedIngredients: IncludedIngredients,
    //   ExcludedIngredients: ExcludedIngredients,
    //   Description: Description,
    //   Occasion: Occasion,
    //   FreeFlow: FreeFlow,
    //   Language: Language,
    // });

    // let prompt = `${Culture} dishes ${
    //   IncludedIngredients ? `with ${IncludedIngredients}` : ""
    // }${ExcludedIngredients ? `(${ExcludedIngredients})` : ""}.

    //   Description: ${Description},
    //   Occasion: ${Occasion},
    //   FreeFlow: ${FreeFlow},
    //   Language: ${Language}`;

    let prompt = `Let's Cook Up Something Delicious!
    
    ###${Culture} dishes ${
      IncludedIngredients ? `with ${IncludedIngredients}` : ""
    }${ExcludedIngredients ? `(${ExcludedIngredients})` : ""}. ${
      Description ? ` should be ${Description}` : ""
    }  ${Occasion ? `and be suitable for ${Occasion}. ` : ""} ${
      FreeFlow ? FreeFlow + ". " : ""
    } ${Language ? `Please write the dish in ${Language}` : ""}`;

    // If no subscription is there
    if (!totalSearchTokens) {
      Swal.fire({
        icon: "info",
        title: "Please buy a subscription first",
        showConfirmButton: true,
        timer: 2000,
      });

      return;
    }

    if (isDishePages) {
      // If subscription ends
      if (usedSearchTokens == -1 || usedSearchTokens >= totalSearchTokens) {
        Swal.fire({
          icon: "info",
          title: "You are out of Search Tokens",
          showConfirmButton: true,
          timer: 2000,
        });

        return;
      }
    } else {
      if (usedRecipeTokens == -1 || usedRecipeTokens >= totalRecipeTokens) {
        Swal.fire({
          icon: "info",
          title: "You are out of Recipe Tokens",
          showConfirmButton: true,
          timer: 2000,
        });

        return;
      }
    }

    let flag = false;
    for (let i = 0; i < Object.keys(values).length; i++) {
      if (Object.values(values)[i].length > 0) {
        flag = true;
      }
    }

    if (flag == true) {
      dispatch(setPrompt(prompt));
      dispatch(setIngredients(values));
      dispatch(clearAllDishImages());
      dispatch(resetImageGenerationCount());

      isDishePages ? navigate("/get-dishes") : navigate("/get-recipe");
      // Swal.fire({
      //   title: "Are you sure?",
      //   text: "By proceeding, you will lose a search token and this can not be un done",
      //   icon: "warning",
      //   showDenyButton: true,
      //   confirmButtonText: "Yes, Proceed!",
      //   denyButtonText: `No`,
      //   showCloseButton: true,
      //   dangerMode: true,
      // }).then(async (result) => {
      //   if (result.isConfirmed) {
      //     isDishePages ? navigate("/get-dishes") : navigate("/get-recipe");
      //   }
      // });
    } else {
      swal({
        icon: "info",
        title: "Please fill atleast one field",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  return (
    <div className="flex flex-col relative min-h-screen overflow-hidden bg-hero-pattern">
      {/* Background image */}
      <div className="absolute h-auto inset-0 box-content -z-1">
        <img
          className="absolute inset-0 w-full h-full object-cover "
          src={homeImageBackground}
          width="1440"
          height="577"
          alt="About"
        />
        {/* <div
          className="absolute inset-0 bg-gradient-to-t  from-gray-700 dark:from-gray-900"
          aria-hidden="true"
        ></div> */}
      </div>

      <Header />

      <div className="pt-24 md:pt-32 pb-6 md:pb-10 2xl:pt-40 2xl:pb-16 justify-center items-center flex md:flex-row flex-col">
        <motion.div
          // className="md:-mt-20"
          // initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
          className="lg:w-[55%] rounded-xl md:w-[90%] w-[95%] p-2  md:pt-6 py-2 space-y-3 pb-4 md:pb-10 
                    bg-white/20 shadow-xl bg-clip-padding backdrop-blur-xl"
        >
          {/* Header */}
          <div className="flex mx-4 my-2 justify-center items-center">
            {/* <img src={title} alt='title' /> */}
            <h1
              className={`sm:text-3xl text-2xl font-black text-transparent bg-gradient-to-r from-teal-400 from-40% via-orange-50 to-70% to-orange-100 bg-clip-text text-center`}
            >
              Be As Picky As You Want!
            </h1>
          </div>

          {/* Buttons */}
          <div className="flex sm:flex-row flex-col w-full md:py-4 bg-gradient-to-r sm:justify-center justify-between items-center sm:space-x-2 md:space-x-8 space-y-1 sm:space-y-0">
            {/* Button 2 */}
            <div className="w-[80%] sm:w-40">
              <button
                onClick={() => {
                  playAudio();
                  setDishesPage(true);
                }}
                // onClick={() => navigate('/signin')}
                className="btn hover:animate-pulse bg-gradient-to-r from-orange-100 to-orange-50 md:w-40 w-full
                            font-medium  flex items-center justify-center text-white transition duration-150 ease-in-out"
              >
                Get Dishes
              </button>
            </div>
            {/* Button 1 */}
            <div className="w-[80%] sm:w-40">
              <button
                onClick={() => {
                  playAudio();
                  setDishesPage(false);
                }}
                className="btn hover:animate-pulse
                            bg-gradient-to-r from-teal-500 to-primary-600  md:w-40 w-full
                            font-medium  flex items-center justify-center text-white hover:bg-teal-400 transition duration-150 ease-in-out"
              >
                Get Recipe
              </button>
            </div>
          </div>

          {/* Fields */}
          <form onSubmit={onSubmit} className="">
            <div className="flex flex-col justify-center items-center">
              <DisheOption
                name="Culture"
                value={values.Culture}
                handleChange={handleChange}
                isDishePages={isDishePages}
                title="Culture or Type"
                placeholder="Italian, Somalian, Chinese, American"
              />
              <DisheOption
                name="IncludedIngredients"
                value={values.IncludedIngredients}
                handleChange={handleChange}
                isDishePages={isDishePages}
                title="Ingredients Included"
                placeholder="Chicken, Mushrooms, Red Chili Flakes"
              />
              <DisheOption
                name="ExcludedIngredients"
                value={values.ExcludedIngredients}
                handleChange={handleChange}
                isDishePages={isDishePages}
                title="Ingredients Excluded"
                placeholder="Broccoli, Kraft Cheese, Fish"
              />
              <DisheOption
                name="Description"
                value={values.Description}
                handleChange={handleChange}
                isDishePages={isDishePages}
                title="Description or Taste"
                placeholder="Spicy, Easy to Cook, 18th Century, Savory, Exotic"
              />
              <DisheOption
                name="Occasion"
                value={values.Occasion}
                handleChange={handleChange}
                isDishePages={isDishePages}
                title="For What Occasion"
                placeholder="First Date, Quick Dinner, Breakfast With The Kids, Lazy Sunday"
              />
              <DisheOption
                name="Language"
                value={values.Language}
                handleChange={handleChange}
                isDishePages={isDishePages}
                title="What Language"
                placeholder="French, Spanish, Arabic, Chinese, German, Italian, etc.."
                beta={true}
              />

              <DisheOption
                name="FreeFlow"
                value={values.FreeFlow}
                handleChange={handleChange}
                isDishePages={isDishePages}
                title="Free Flow"
                placeholder="Cook-E! Please find me something for breakfast that has bacon ..."
              />
            </div>
            <div className="mt-4 md:mt-0">
              {/* Button 1 */}
              <div className="flex justify-between text-[14px]">
                <button
                  type="reset"
                  onClick={() => {
                    playAudio();
                    setValues({
                      Culture: "",
                      IncludedIngredients: "",
                      ExcludedIngredients: "",
                      Description: "",
                      Occasion: "",
                      Language: "",
                      FreeFlow: "",
                    });
                  }}
                  className={`hover:animate-pulse
                              font-bold md:w-48 w-full mx-[5%] flex justify-center items-center
                              border-2 border-gray-50 rounded-md
                             text-white transition duration-150 ease-in-out  md:mt-5
                                `}
                >
                  Clear
                </button>

                <button
                  type="submit"
                  className={`btn hover:animate-pulse
                            !bg-gradient-to-r ${
                              isDishePages
                                ? "!from-orange-100 !to-orange-50"
                                : "!from-teal-500 !to-primary-600"
                            } font-bold md:w-48 w-full mx-[5%]
                            flex items-end justify-end rounded-md
                             text-white  transition duration-150 ease-in-out md:mt-5 px-10
                                `}
                >
                  {isDishePages ? "Search Dishes" : "Search Recipe"}
                </button>
              </div>
            </div>
          </form>
          {/* Discover */}
        </motion.div>
      </div>
    </div>
  );
}
