const updateUserTokensForTimestamp = async (
  firestoreDB,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  userId,
  payload
) => {
  try {
    const usersCollectionRef = collection(firestoreDB, "Users");
    const userQuery = query(usersCollectionRef, where("userId", "==", userId));
    const userSnapshot = await getDocs(userQuery);

    if (!userSnapshot.empty) {
      const userDoc = userSnapshot.docs[0];

      await updateDoc(doc(usersCollectionRef, userDoc.id), payload);

      console.log("Tokens Removed successfully!");
      return true;
    } else {
      console.log("No document found with the specified user ID.");
      return false;
    }
  } catch (error) {
    console.error("Error updating tokens:", error);
    return false;
  }
};

const removeEmptyTokensOnDate = async (
  firestoreDB,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  userId
) => {
  try {
    const usersCollectionRef = collection(firestoreDB, "Users");
    const userQuery = query(usersCollectionRef, where("userId", "==", userId));
    const userSnapshot = await getDocs(userQuery);

    if (!userSnapshot.empty) {
      const userDoc = userSnapshot.docs[0];
      const updatedUsedDownloadTokens = -1;
      const updatedUsedSearchTokens = -1;
      const updatedTotalSearchTokens = 0;
      const updatedTotalDownloadTokens = 0;
      const subscriptionStatus = false;
      const currentPeriodEnd = null;
      const subscriptionId = null;

      await updateDoc(doc(usersCollectionRef, userDoc.id), {
        usedDownloadTokens: updatedUsedDownloadTokens,
        usedSearchTokens: updatedUsedSearchTokens,
        totalSearchTokens: updatedTotalSearchTokens,
        totalDownloadTokens: updatedTotalDownloadTokens,
        subscriptionStatus,
        currentPeriodEnd,
        subscriptionId,
      });

      console.log("Tokens Removed successfully!");
      return true;
    } else {
      console.log("No document found with the specified user ID.");
      return false;
    }
  } catch (error) {
    console.error("Error updating tokens:", error);
    return false;
  }
};

export { updateUserTokensForTimestamp, removeEmptyTokensOnDate };
