import styled from "styled-components";

export const SearchInput = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.gray};
  margin-top: 20px;
  color: ${(props) => props.theme.primary};
  transition: 0.4s;
  margin-bottom: 30px !important;

  &:focus {
    border-color: ${(props) => props.theme.primary};
    outline: none;
  }

  &::placeholder {
    color: ${(props) => props.theme.gray};
  }
`;
