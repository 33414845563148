import { doc, writeBatch } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import BinIconOutlined from "../assets/icons/binIconOutlined";
import PencilIcon from "../assets/icons/pencilIcon";
import { firestoredDB } from "../config/firebase";
import { playAudio } from "../utils/btnAudio";

const CookBookLists = ({
  openModal,
  clickedListId,
  loading,
  listsData,
  onListClick,
  onDeleteIconClick,
  onPencilIconClick,
  search = "",
}) => {
  const { subscriptionId, subScriptionPlan, subscriptionStatus } = useSelector(
    (state) => state.tokens
  );
  const updateListOrder = async (orderedData) => {
    try {
      const batch = writeBatch(firestoredDB);

      orderedData.forEach((item, index) => {
        const docRef = doc(firestoredDB, "Lists", item.id);
        batch.update(docRef, { order: index });
      });

      await batch.commit();
      console.log("List order updated successfully");
    } catch (err) {
      console.error("Error updating list order: ", err);
    }
  };

  const [items, setItems] = useState(listsData);

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );
    setItems(reorderedItems);
    updateListOrder(reorderedItems);
  };
  const setData = () => {
    // if (items?.length == 0) {
    setItems(listsData);
    // }
  };
  useEffect(() => {
    setData();
  }, [listsData]);

  const handleOpenModal = () => {
    playAudio();
    if (
      subscriptionStatus === true &&
      subScriptionPlan === "Basic" &&
      items.length >= 10
    ) {
      Swal.fire({
        icon: "error",
        title:
          "You have reached the maximum number of lists for your subscription plan",
        showConfirmButton: true,
        confirmButtonText: "Upgrade Plan",
        confirmButtonColor: "#FF6B3B",
        showCancelButton: true,
        cancelButtonText: "Cancel",
        cancelButtonColor: "#FF6B3B",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = "/shop";
        }
      });

      return;
    }

    openModal();
  };

  return (
    <div
      // id="no-scrollbar"
      // className="no-scrollbar overflow-y-scroll max-h-[300px] sm:max-h-[calc(100%-50px)] flex-[30%]  flex-wrap h-auto md:py-5 w-full justify-start items-start  flex-row md:space-x-0 space-x-2 bg-white bg-opacity-30 rounded-xl"
      className=" max-h-[300px] sm:max-h-[calc(100%-50px)] flex-[30%]  flex-wrap h-auto md:py-5 w-full justify-start items-start  flex-row md:space-x-0 space-x-2 bg-white bg-opacity-30 rounded-xl overflow-y-scroll"
    >
      <div className="flex flex-col md:overflow-x-hidden overflow-x-scroll items-center justify-center px-2 no-scrollbar">
        <button
          onClick={handleOpenModal}
          className="rounded-full lg:w-52 w-full
bg-gradient-to-r from-orange-100 to-orange-50 hover:from-orange-50 hover:to-orange-100  md:mx-0 
font-medium  flex items-center justify-center border border-transparent lg:px-14 py-2.5
 my-2  text-white transition duration-150 ease-in-out backdrop-blur-lg "
        >
          Create List
        </button>
        <button
          onClick={() => onListClick(null)}
          className={`lg:w-52 w-full md:p hover:bg-gray-200
                                                md:mx-0 bg-clip-padding  ${
                                                  clickedListId === null
                                                    ? "bg-primary-600"
                                                    : "bg-opacity-25 bg-white"
                                                } 
                                             font-medium  flex items-center justify-center border border-transparent lg:px-14 py-2.5
                                              my-2 rounded-full text-gray-800  transition duration-150 ease-in-out`}
        >
          General
        </button>
        {loading ? (
          <p>Please wait ...</p>
        ) : items?.length === 0 ? (
          <p className="text-white text-center text-xl">No Lists</p>
        ) : (
          <div>
            {search?.length == 0 && (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {items?.map((list, idx) => {
                        return (
                          <Draggable
                            key={list.id}
                            draggableId={list.id}
                            index={idx}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div
                                  key={idx}
                                  className={`lg:w-52 w-full md:mx-0 bg-clip-padding px-4 ${
                                    clickedListId === list.id
                                      ? "bg-primary-600"
                                      : "bg-opacity-25 bg-white"
                                  } 
                            font-medium   flex items-start justify-between cursor-pointer hover:bg-gray-200 border border-transparent lg:px-4 py-2.5
                    my-2 rounded-full text-gray-800  transition duration-150 ease-in-out `}
                                  onClick={() => onListClick(list.id)}
                                >
                                  <div className="">{list.name} </div>
                                  <div className="flex mt-1 space-x-1">
                                    <div
                                      onClick={() => onDeleteIconClick(list.id)}
                                    >
                                      <BinIconOutlined />
                                    </div>
                                    <div
                                      onClick={() => onPencilIconClick(list.id)}
                                    >
                                      <PencilIcon />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CookBookLists;
