import { Button, Modal } from "flowbite-react";

function PrivacyPolicyModal({ open, onClose }) {
  return (
    <>
      <Modal show={open} onClose={() => onClose(false)}>
        <Modal.Header
          style={{
            backgroundColor: "#F9FAFB",
            padding: "2rem",
            borderBottom: "1px solid #aaaaaa",
            color: "#2863e3",
          }}
        >
          Privacy Policy
        </Modal.Header>
        <Modal.Body
          style={{
            maxHeight: "500px", // Set a maximum height
            overflowY: "auto", // Enable vertical scrolling
          }}
        >
          <div className="space-y-6">
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              At Cook-E AI, accessible from https://www.cookeai.com/, one of our
              main priorities is the privacy of our visitors. This Privacy
              Policy document contains types of information that is collected
              and recorded by Cook-E AI and how we use it.
            </p>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              This Privacy Policy applies only to our online activities and is
              valid for visitors to our website with regards to the information
              that they shared and/or collect in Cook-e AI. This policy is not
              applicable to any information collected offline or via channels
              other than this website.
            </p>
            <h3>Consent</h3>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              By using our website, you hereby consent to our Privacy Policy and
              agree to its terms.
            </p>
            <h3>Information we collect</h3>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              The personal information that you are asked to provide, and the
              reasons why you are asked to provide it, will be made clear to you
              at the point we ask you to provide your personal information.
            </p>
            <h3>How we use your information</h3>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              We use the information we collect in various ways, including to
              provide, operate, and maintain our website, improve, personalize,
              and expand our website, understand and analyze how you use our
              website, develop new products, services, features, and
              functionality, communicate with you for customer service and
              marketing purposes, send emails, and find and prevent fraud.
            </p>
            <h3>Log Files</h3>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              Cook-e AI follows a standard procedure of using log files. These
              files log visitors when they visit websites. The information
              collected by log files include internet protocol (IP) addresses,
              browser type, Internet Service Provider (ISP), date and time
              stamp, referring/exit pages, and possibly the number of clicks.
            </p>
            <h3>Cookies and Web Beacons</h3>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              Like any other website, Cook-e AI uses "cookies". These cookies
              are used to store information including visitors' preferences, and
              the pages on the website that the visitor accessed or visited.
            </p>
            <h3>Advertising Partners Privacy Policies</h3>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              Third-party ad servers or ad networks use technologies like
              cookies, JavaScript, or Web Beacons in their respective
              advertisements and links that appear on Cook-e AI, which are sent
              directly to users' browser.
            </p>
            <h3>CCPA Privacy Rights (Do Not Sell My Personal Information)</h3>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              Under the CCPA, California consumers have specific rights
              regarding their personal data.
            </p>
            <h3>GDPR Data Protection Rights</h3>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              Every user is entitled to specific data protection rights under
              the GDPR.
            </p>
            <h3>Children's Information</h3>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              Another part of our priority is adding protection for children
              while using the internet.
            </p>
            <h3>Changes to This Privacy Policy</h3>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              We may update our Privacy Policy from time to time. We advise you
              to review this page periodically for any changes.
            </p>
            <h3>Contact Us</h3>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              If you have any questions or suggestions about our Privacy Policy,
              do not hesitate to contact us.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{
            backgroundColor: "#F9FAFB",
            padding: "2rem",
            borderTop: "1px solid #555555",
            boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.25)",
          }}
        >
          <Button
            color="primary"
            onClick={() => onClose(false)}
            style={{
              backgroundColor: "#2863e3",
              color: "#ffffff",
              border: "1px solid #2863e3",
            }}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PrivacyPolicyModal;
