const getRandomJoke = async (jokesRef, getDocs, setJoke, type) => {
  try {
    const querySnapshot = await getDocs(jokesRef);
    const jokes = [];
    var alphaJoke = "";
    querySnapshot.forEach((doc) => {
      if (type == "alpha") {
        alphaJoke = doc.data().alpha;
      } else if (type == "beta") {
        alphaJoke = doc.data().beta;
      } else if (type == "gamma") {
        alphaJoke = doc.data().gamma;
      }
      alphaJoke = alphaJoke.split(`", "`);
      jokes.push(...alphaJoke);
    });
    if (jokes.length > 0) {
      const randomIndex = Math.floor(Math.random() * jokes.length);
      const randomJoke = jokes[randomIndex];
      setJoke(randomJoke);
    }
  } catch (error) {
    console.error("Error getting jokes:", error);
  }
};

export { getRandomJoke };
