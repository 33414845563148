import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { playAudio } from "../../utils/btnAudio";

export default function RecipeCardDetails({
  isOpen,
  openModal,
  close,
  closeModal,
  recipeCardData,
}) {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative bg-opacity-100  z-50"
          onClose={closeModal}
        >
          <div className="fixed top-28 inset-0 overflow-y-auto ">
            <div className="flex  items-center justify-center p-4 text-center h-scree">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className="w-full bg-[#2a93dd40] backdrop-blur-xl  max-w-6xl bg-opacity-60
                                 md:w-[80%] md:mx-auto 
                                 transform overflow-hidden rounded-2xl
                                 p-6 text-left align-middle shadow-xl transition-all"
                >
                  <div className="mt-2 ">
                    <div className="flex flex-col">
                      <div className="flex   justify-center items-center">
                        <img
                          className="mb-3  rounded-xl h-[350px]"
                          src={recipeCardData?.image}
                          alt="Icon 03"
                        />
                      </div>
                      <div className="text-gray-100 mb-1 h-[30rem] overflow-y-scroll  md:mb-3">
                        <pre className=""> {recipeCardData?.description}</pre>
                      </div>
                    </div>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex text-primary-600 border-2 border-primary-600 justify-center rounded-md px-4 py-2 text-sm font-medium  focus-visible:ring-2 "
                      onClick={() => {
                        playAudio();
                        close();
                      }}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
