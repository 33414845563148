import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { ColorRing } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

// Redux Actions
import { setUserData, switchLoginStatus } from "../../redux/auth";
import { clearMessage, setMessage } from "../../redux/message";

// Components and Assets
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import homeImageBackground from "../../assets/images/homeBackground.png";
import Header from "../../components/Header";
import { playAudio } from "../../utils/btnAudio";

const initialState = {
  email: "",
  password: "",
};

function SignIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { message } = useSelector((state) => state.message);

  const [values, setValues] = useState(initialState);
  const [visible, setIsVisible] = useState(false);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    dispatch(clearMessage());
  }, []);
  const getProfile = async (userId) => {
    const db = getFirestore();
    const usersCollection = collection(db, "Users");

    try {
      const q = query(usersCollection, where("userId", "==", userId));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          const userData = doc.data();

          localStorage.setItem("photoURL", userData.photoURL);
        });
      } else {
        console.log("User not found");
      }
    } catch (error) {
      console.error("Error retrieving user:", error);
    }
  };

  const handleLogin = async () => {
    setIsVisible(true);
    try {
      const user = await signInWithEmailAndPassword(
        getAuth(),
        values.email,
        values.password
      );

      dispatch(switchLoginStatus(true));
      dispatch(setUserData(user));
      localStorage.setItem("uid", user.user.uid);
      localStorage.removeItem("photoURL");

      getProfile(user.user.uid)
        .then(() => {
          navigate("/verify-email");
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      const errorMessage =
        {
          "auth/invalid-email": "Invalid email ID",
          "auth/user-not-found": "Please check your email",
          "auth/wrong-password": "Please check your password",
          "auth/too-many-requests": "Too many attempts, please try again later",
        }[err.code] || "An unexpected error occurred";

      dispatch(setMessage(errorMessage));
    }
    setIsVisible(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    playAudio();
    handleLogin();
  };

  return (
    <div className="flex flex-col min-h-screen  relative overflow-hidden ">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className=" ">
        {/*  Page illustration */}
        <div className="absolute  h-screen inset-0  box-content -z-1">
          <img
            className="relative inset-0 w-full h-[100%] object-cover "
            src={homeImageBackground}
            alt="About"
          />
        </div>

        <section className="relative  ">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.5 }}
            variants={{
              hidden: { opacity: 0, x: -50 },
              visible: { opacity: 1, x: 0 },
            }}
            className="max-w-7xl md:mx-auto px-4 md:px-6 "
          >
            <div
              className="pt-32 pb-10 lg:translate-y-0   lg:pb-16 
            flex justify-center items-center"
            >
              <div className="bg-white bg-opacity-10 px-2 shadow-xl py-5 opacity-90 md:w-[70%] lg:w-[45%] w-full rounded-xl">
                {/* Page header */}
                <div className="max-w-3xl mx-auto text-center pb-12 md:pb-10">
                  <h1 className="h3  text-gray-200">Sign In</h1>
                </div>

                {/* Form */}
                <div>
                  <form onSubmit={onSubmit} className="max-w-sm mx-auto ">
                    <div className="flex flex-wrap -mx-3 mb-4">
                      <div className="w-full px-3">
                        <label
                          className="block text-gray-300 text-sm font-medium mb-1"
                          htmlFor="email"
                        >
                          Email
                        </label>
                        <input
                          type="email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          className="form-input w-full rounded-full text-gray-700"
                          placeholder="Enter your email "
                          required
                        />
                      </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-4">
                      <div className="w-full px-3">
                        <label
                          className="block text-gray-300 text-sm font-medium mb-1"
                          htmlFor="password"
                        >
                          Password
                        </label>
                        <input
                          type="password"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          className="form-input w-full rounded-full text-gray-700"
                          placeholder="Password (at least 8 characters)"
                          required
                        />
                      </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-4">
                      <div className="w-full px-3">
                        <div className="flex justify-between">
                          {/* <label className="flex items-center">
                          <input type="checkbox" className="form-checkbox" />
                          <span className="text-gray-400 ml-2">
                            Keep me signed in
                          </span>
                        </label> */}
                          <Link
                            to="/reset-password"
                            className="text-orange-500 font-bold hover:text-gray-200 transition duration-150 ease-in-out"
                          >
                            Forgot Password?
                          </Link>
                        </div>
                      </div>
                    </div>
                    <p className="mt-2 mx-3   text-md font-bold text-center text-gray-700">
                      {message && (
                        <div className="text-red-600 h-full " role="alert">
                          {message}
                        </div>
                      )}
                    </p>
                    <div className="flex flex-wrap -mx-3 mt-6">
                      <div className="w-full px-3">
                        {/* <Link to="/services"> */}
                        <button
                          type="submit"
                          // className="btn text-white !bg-gradient-to-r from-orange-100 to-orange-50 w-full"
                          className="md:mt-10 !bg-gradient-to-r !from-orange-100 !to-orange-50 md:ml-[3%] mx-[5%] md:mx-0 w-[90%] lg:w-[100%] font-medium  flex items-center justify-center border border-transparent px-14 py-2.5 my-4 rounded text-white bg-teal-500 hover:bg-teal-400 transition duration-150 ease-in-out"
                        >
                          Sign in
                        </button>
                        {/* </Link> */}
                      </div>
                    </div>
                  </form>
                  {visible && (
                    <div className="z-50 absolute top-[50%] left-[50%] -translate-x-[50%]">
                      <ColorRing
                        visible={true}
                        height="100"
                        width="100"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        colors={[
                          "#e15b64",
                          "#f47e60",
                          "#f8b26a",
                          "#abbd81",
                          "#849b87",
                        ]}
                      />
                    </div>
                  )}
                  <div className="text-gray-200 text-center mt-6">
                    Don’t you have an account?{" "}
                    <Link
                      to="/signup"
                      className="text-orange-500 font-bold hover:text-gray-200 transition duration-150 ease-in-out"
                    >
                      Sign up
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </section>
      </main>
    </div>
  );
}

export default SignIn;
