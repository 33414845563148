import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { motion } from "framer-motion";
import { Configuration, OpenAIApi } from "openai";
import React, { useEffect, useState } from "react";
import { MdChatBubble, MdClose, MdSend } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { ResizableBox } from "react-resizable";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import homeImageBackground from "../assets/images/homeBackground.png";
import Header from "../components/Header";
import SocialShareButton from "../components/SocialShareButton";
import TextToSpeech from "../components/TextToSpeech";
import { auth, firestoredDB } from "../config/firebase";
import { setRecipeCard } from "../redux/recipeCard";
import { incrementDownloadTokens } from "../redux/tokens";
import { SHARE_STRING } from "../utils/constants";
import { extractDishTitle } from "../utils/extractTitle";
import {
  createShortUrlByFirebase,
  generateShortLink,
  generateShortLink_BySlnk,
  generateShortLink_byTly,
  storeShortUrlData,
} from "../utils/getShortnerLink";
import { updateUserTokens } from "../utils/updateTokens";
import { playAudio } from "../utils/btnAudio";
import Chatbot from "../components/Chatbot";
import {
  increaseChatCount,
  increaseChatCountBy2,
  setChatCount,
} from "../redux/chatbot";
// import Chatbot from "../components/Chatbot";
import Markdown from "react-markdown";
import styles from "../components/open-ai/GenerateInstructions.module.css";
import RecipeNotes from "../components/alerts/RecipeNotes";
import RecipeNotesComponent from "../components/recipeNotesComponent";
const DishDetailsPublic = () => {
  // import.meta.env.VITE_Open_AI_Key
  const configuration = new Configuration({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  });

  const openai = new OpenAIApi(configuration);

  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // https://www.facebook.com/share_channel/?link=Hey%2C+I+shared+a+recipe+with+you+on+cookeai.com.+click+here%0A%0A+https%3A%2F%2Fbit.ly%2F42HCrkB&app_id=966242223397117&source_surface=external_reshare&display&hashtag
  const shareText = JSON.stringify(
    `Hey, I shared a recipe with you on cookeai.com. click here\n\n`
  );

  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chatbotAnswerLoading, setChatbotAnswerLoading] = useState(false);
  const [numberOfChats, setNumberOfChats] = useState(0);
  const [shortUrl, setShortUrl] = useState(null);

  const [showChat, setShowChat] = useState(false);
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [chatLimit, setChatLimit] = useState(100);
  const [cookBookData, setCookBookData] = useState([]);
  const [alreadySaved, setAlreadySaved] = useState(false);
  const [publicDescription, setPublicDescription] = useState("");
  const [systemMessageCount, setSystemMessageCount] = useState(0);
  const [recipeNotesModal, setRecipeNotesModal] = useState(false);
  const [recipeNotes, setRecipeNotes] = useState([]);

  // Increment system message count whenever a new system message is added

  const { subScriptionPlan, subscriptionStatus } = useSelector(
    (state) => state.tokens
  );
  // const abc = useSelector((state) => state);

  const DOMAIN_NAME = "https://cookeai.com";

  const { user } = useSelector((state) => state?.auth?.user) ?? {};
  const { totalDownloadTokens, usedDownloadTokens } =
    useSelector((state) => state?.tokens) ?? {};

  const queryParams = new URLSearchParams(location.search);
  const senderUserId = queryParams.get("userid");
  const { dish_id } = params;

  const isCurrentUser = user?.uid === senderUserId;
  const isLoggedIn = !!user?.uid;

  const { recipeCard: recipeCardData } = useSelector(
    (state) => state.recipeCard
  );

  const cookbookCollectionRef = collection(firestoredDB, "CookBook");

  const getCookBookListOfReceiver = async () => {
    setLoading(true);
    try {
      const data = await getDocs(cookbookCollectionRef);

      const filteredData = data.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      const userData = filteredData.filter(
        (item) => item.userId === auth?.currentUser?.uid
      );

      setCookBookData(userData);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  const getCookBookById = async () => {
    //   setLoading(true); // Assuming you have a loading state
    if (recipeCardData?.id != dish_id) {
      try {
        const cookBookDocRef = doc(firestoredDB, "CookBook", dish_id); // Reference to the specific cookbook document
        const cookBookDoc = await getDoc(cookBookDocRef);

        if (cookBookDoc.exists()) {
          const cookBookData = {
            id: cookBookDoc.id,
            ...cookBookDoc.data(),
          };

          dispatch(setRecipeCard({ ...cookBookData }));
          setMessages(cookBookData?.botChat ?? []);
          dispatch(setChatCount(cookBookData?.botChat?.length ?? 0));

          if (user?.uid && !cookBookData?.shareUrl) {
            const shareUrl =
              DOMAIN_NAME +
              "/cookbook-details/dish-details/" +
              cookBookDoc.id +
              "?userid=" +
              user?.uid;

            generateShortLinkforDish(shareUrl, cookBookData);

            // await generateDescription(cookBookData?.description);
          } else {
            await generateDescription(cookBookData?.description, cookBookData);
          }
          // Alternatively, you can return cookBookData if you need this data returned from the function
        } else {
          console.log("No such cookbook exists!");
          // Handle the case where the cookbook doesn't exist
        }
      } catch (err) {
        console.error("Error fetching cookbook: ", err);
        // Handle any other errors
      }
    }
    //   setLoading(false);
  };

  const generateDescription = async (recipe, prevData) => {
    setLoading(true);
    try {
      const response = await openai.createChatCompletion({
        model: "gpt-4o",
        temperature: 0.89,
        max_tokens: 500,
        messages: [
          {
            role: "user",
            content: `Give me short description of this recipe ${recipe}, which don't reveal much details about this. just description without any extra lines`,
          },
        ],
      });
      setPublicDescription(response.data.choices[0].message.content);
      dispatch(
        setRecipeCard({
          ...prevData,
          publicDescription: response.data.choices[0].message.content,
        })
      );
    } catch (e) {}
    setLoading(false);
  };

  const updateChatLimit = () => {
    if (subscriptionStatus && subScriptionPlan === "Basic") {
      setChatLimit(10);
    }
  };
  const sendMessage = async (e) => {
    playAudio();
    e?.preventDefault();
    if (input.trim()) {
      setChatbotAnswerLoading(true);
      const msg = [...messages];
      // Logic for bot response goes here
      setInput("");
      msg.push({ role: "user", content: input });
      setMessages(msg);
      try {
        const response = await openai.createChatCompletion({
          model: "gpt-4o",
          temperature: 0.89,
          max_tokens: 500,
          messages: [
            {
              role: "system",
              content: `
              Imagine you are the latest and greatest cooking assistant AI, named Cook-E AI. You are going to give answers to user's queries about the dish: ${recipeCardData?.description}. if someone ask about a drink or any add-on with the dish, you should answer them properly and suggest them. and if someone go outside of scope of this dish and ask entirely irrelevant, you should simply apologies.
              
             PLease ensure to add line break after every paragraph.
              
              Please ensure that each heading starts on a new line.`,
            },
            ...messages,
            {
              role: "user",
              content: `${input}`,
            },
          ],
        });

        setChatbotAnswerLoading(false);
        const finalRes = [
          ...messages,
          { role: "user", content: input },
          { role: "system", content: response.data.choices[0].message.content },
        ];
        const finalResForDb = [
          { role: "user", content: input },
          { role: "system", content: response.data.choices[0].message.content },
        ];
        updateRecipeBotChat(finalResForDb);
        setMessages(finalRes);

        setNumberOfChats((prev) => prev + 2);
        setSystemMessageCount((prevCount) => prevCount + 1);
        dispatch(increaseChatCountBy2());
      } catch (e) {
        setChatbotAnswerLoading(false);
        setMessages([
          ...messages,
          { role: "user", content: input },
          { role: "system", content: "Error Generating Response!" },
        ]);
        console.log(e);
      }
    }
  };

  const onAddtoMyCookbookClick = async () => {
    playAudio();
    if (!isLoggedIn) {
      Swal.fire({
        icon: "info",
        title: "You are not logged in",
        showConfirmButton: true,
        confirmButtonText: "Log in",
        timer: 2000,
      }).then((res) => {
        navigate("/signin");
      });
    } else {
      const existAlready = cookBookData?.filter((item) => item?.id == dish_id);
      if (
        usedDownloadTokens == -1 ||
        usedDownloadTokens >= totalDownloadTokens
      ) {
        Swal.fire({
          icon: "info",
          title:
            "You don't have enough tokens, either upgrade your subscription or wait for next month",
          showConfirmButton: true,
          timer: 4000,
          showDenyButton: true,
          confirmButtonText: "Close!",
          denyButtonText: `Upgrade Subscription`,
          showCloseButton: true,
        }).then((res) => {
          if (res.isDenied) {
            navigate("/shop");
          }
        });
        return;
      }
      if (existAlready?.length > 0 || alreadySaved) {
        Swal.fire({
          icon: "info",
          title: "Dish is Already Saved in the cookbook",
          showConfirmButton: false,
          timer: 2000,
        });
        return;
      }

      try {
        const res = await addDoc(cookbookCollectionRef, {
          userId: auth?.currentUser?.uid,
          description: recipeCardData?.description || null,
          ingredients: recipeCardData?.description || null,
          imageUrl: recipeCardData?.imageUrl || null,
          title: recipeCardData?.title || "Dish AA",
          listId: null,
          sharedBy: senderUserId,
        });

        setAlreadySaved(true);
        Swal.fire({
          icon: "success",
          title: "Recipe added successfully to your cookbook",
          showConfirmButton: false,
          timer: 3000,
          confirmButtonColor: "#f0481a",
        });

        setLoading(false);
        if (
          updateUserTokens(
            firestoredDB,
            collection,
            query,
            where,
            getDocs,
            updateDoc,
            doc,
            auth?.currentUser?.uid,
            "download"
          )
        ) {
          dispatch(incrementDownloadTokens());
        }
      } catch (err) {
        setLoading(false);
        Swal.fire({
          icon: "info",
          title: err.message,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };

  const generateShortLinkforDish = async (longUrl, prevData) => {
    try {
      let { link, data } = await createShortUrlByFirebase(longUrl);
      setShortUrl(link);
      // dispatch(setRecipeCard({ ...prevData, shareUrl: link }));
      updateRecipeShareUrl(link);
      storeShortUrlData(data);
      return link;
    } catch (error) {
      console.error("Error creating TinyURL:", error.message);
    }
  };

  const onCopyLink = async () => {
    const shareUrl =
      DOMAIN_NAME +
      "/cookbook-details/dish-details/" +
      dish_id +
      "?userid=" +
      user?.uid;

    let url = "";
    // await generateShortLink_byTly(shareUrl);
    if (!recipeCardData?.shareUrl) {
      url = await generateShortLinkforDish(shareUrl);
      dispatch(setRecipeCard({ ...recipeCardData, shareUrl: url }));
      updateRecipeShareUrl(url);
    }

    playAudio();
    // const url = shortUrl == null ? recipeCardData?.shareUrl ?? "" : shortUrl;
    navigator.clipboard.writeText(recipeCardData?.shareUrl);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 10000);
  };

  useEffect(() => {
    updateChatLimit();
    getCookBookById();
    if (auth?.currentUser?.uid) {
      getCookBookListOfReceiver();
    }
  }, [dish_id]);

  function removeHTMLTags(inputString) {
    // Use a regular expression to remove HTML tags
    return inputString.replace(/<\/?[^>]+(>|$)/g, "");
  }

  const updateRecipeShareUrl = async (url) => {
    try {
      const movieDoc = doc(firestoredDB, "CookBook", dish_id);
      const abc = await updateDoc(movieDoc, {
        shareUrl: url,
      });
      await getCookBookById();
    } catch (error) {
      console.log("heree", error);
    }
  };

  const updateRecipeBotChat = async (chat) => {
    try {
      const existedMsg = recipeCardData?.botChat ?? [];
      const updatedMsg = [...existedMsg, ...chat];
      const newObj = { ...recipeCardData, botChat: updatedMsg };
      dispatch(setRecipeCard(newObj));

      const movieDoc = doc(firestoredDB, "CookBook", dish_id);
      const abc = await updateDoc(movieDoc, {
        botChat: updatedMsg,
      });
    } catch (error) {
      console.log("heree", error);
    }
  };

  return (
    <div className="flex flex-col relative min-h-screen overflow-hidden bg-hero-pattern">
      {/* <ChatBot dishDetails={recipeCardData?.description} /> */}
      {/* Background image */}
      <div className="absolute h-auto inset-0 box-content -z-1">
        <img
          className="absolute inset-0 w-full h-full object-cover "
          src={homeImageBackground}
          width="1440"
          height="577"
          alt="About"
        />
      </div>

      <Header />

      <div className="pt-24 md:pt-32 pb-6 md:pb-10 2xl:pt-40 2xl:pb-16 justify-center items-center flex md:flex-row flex-col">
        <motion.div
          // className="md:-mt-20"
          // initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
          className="lg:w-[60%] rounded-xl md:w-[90%] w-[95%] p-6  md:pt-6 py-2 space-y-3 pb-4 md:pb-10 
          bg-[#2a93dd40] shadow-xl bg-clip-padding backdrop-blur-xl"
        >
          <div>
            <div className="mt-2 ">
              <div className="flex flex-col">
                <div className="flex   justify-center items-center">
                  <img
                    className="mb-3  rounded-xl h-[350px]"
                    src={recipeCardData?.imageUrl}
                    alt="Recipe Image"
                  />
                </div>

                {!isCurrentUser && senderUserId && (
                  <div className={`text-gray-100 mb-1 md:mb-3`}>
                    {recipeCardData?.publicDescription ?? publicDescription}
                  </div>
                )}
                <div
                  className={`text-gray-100 mb-1 h-[30rem] ${
                    !isCurrentUser && senderUserId
                      ? "overflow-hidden blur-sm"
                      : "overflow-y-scroll"
                  }  md:mb-3`}
                >
                  <div class="w-full">
                    <div
                      class="text-gray-200 text-lg"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                      }}
                      className={styles.markdown}
                    >
                      <Markdown>
                        {recipeCardData?.description ?? "No description found"}
                      </Markdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-4 flex items-center justify-center gap-4">
              {user?.uid && isCurrentUser && (
                <>
                  <button
                    type="button"
                    className="inline-flex text-primary-600 border-2 border-primary-600 justify-center rounded-md px-4 py-2 text-sm font-medium  focus-visible:ring-2 "
                    onClick={() => {
                      playAudio();
                      navigate("/personal-cookbook");
                    }}
                  >
                    Back to List
                  </button>
                  <button
                    type="button"
                    className="inline-flex text-primary-600 border-2 border-primary-600 justify-center rounded-md px-4 py-2 text-sm font-medium  focus-visible:ring-2 "
                    disabled={copied}
                    onClick={onCopyLink}
                  >
                    {/* <CopyToClipboard
                      text={shareUrl}
                      onCopy={() => {
                        onCopyLink();
                      }}
                    > */}
                    <span>{copied ? "Copied" : "Copy Link"}</span>
                  </button>
                  <SocialShareButton
                    shareUrl={recipeCardData?.shareUrl ?? ""}
                    image={recipeCardData?.imageUrl}
                    title={extractDishTitle(recipeCardData?.title, 2)}
                  />
                </>
              )}
              {!isCurrentUser && senderUserId && (
                <button
                  type="button"
                  className="inline-flex text-primary-600 border-2 border-primary-600 justify-center rounded-md px-4 py-2 text-sm font-medium  focus-visible:ring-2 "
                  onClick={onAddtoMyCookbookClick}
                >
                  Add to my Cookbook
                </button>
              )}
            </div>
          </div>
          {user?.uid && isCurrentUser && recipeCardData?.description && (
            <TextToSpeech
              displayedText={removeHTMLTags(recipeCardData?.description)}
            />
          )}

          <RecipeNotesComponent
            recipeCardData={recipeCardData}
            dish_id={dish_id}
          />
        </motion.div>
      </div>
      <div className="">
        <button
          className={`fixed bottom-5 right-5 p-3 bg-gradient-to-r from-orange-100 to-orange-50 text-white rounded-full focus:outline-none transition duration-300 ${
            showChat
              ? "bg-gradient-to-r from-orange-100 to-orange-50"
              : "bg-orange-100"
          } hover:bg-blue-700`}
          onClick={() => {
            playAudio();
            setShowChat((prev) => !prev);
          }}
        >
          <MdChatBubble className="text-[20px] w-6 h-6" />
        </button>

        {/* Chatbot window */}
        {showChat && (
          <>
            <Chatbot
              messages={messages}
              sendMessage={sendMessage}
              chatbotAnswerLoading={chatbotAnswerLoading}
              chatLimit={chatLimit}
              input={input}
              setInput={setInput}
              setShowChat={setShowChat}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default DishDetailsPublic;
