import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  totalDownloadTokens: -1,
  usedDownloadTokens: 0,
  totalSearchTokens: -1,
  usedSearchTokens: 0,
  totalRecipeTokens: -1,
  usedRecipeTokens: 0,
  subscriptionStatus: false,
  subscriptionId: null,
  currentPeriodEnd: 0,
  subScriptionPlan: null,
};
const tokensSlice = createSlice({
  name: "tokens",
  initialState,

  reducers: {
    setAllDataForTokens: (state, action) => {
      const { payload } = action;

      return { ...state, ...payload };
    },
    setSearchTokens: (state, action) => {
      state.totalSearchTokens = parseInt(action.payload);
    },
    setDownloadTokens: (state, action) => {
      state.totalDownloadTokens = parseInt(action.payload);
    },
    setRecipeTokens: (state, action) => {
      state.totalRecipeTokens = parseInt(action.payload);
    },
    setUsedSearchTokens: (state, action) => {
      state.usedSearchTokens = parseInt(action.payload);
    },
    setUsedDownloadTokens: (state, action) => {
      state.usedDownloadTokens = parseInt(action.payload);
    },
    setUsedRecipeTokens: (state, action) => {
      state.usedRecipeTokens = parseInt(action.payload);
    },
    incrementDownloadTokens: (state) => {
      state.usedDownloadTokens += 1;
    },
    incrementSearchTokens: (state) => {
      state.usedSearchTokens += 1;
    },
    incrementRecipeTokens: (state) => {
      state.usedRecipeTokens += 1;
    },
    setSubscriptionId: (state, action) => {
      state.subscriptionId = action.payload;
    },
    updateCurrentPeriodEndTimestamp: (state, action) => {
      state.currentPeriodEnd = action.payload;
    },
    updateSubscriptionStatus: (state, action) => {
      state.subscriptionStatus = action.payload;
    },
    clearTokens: (state) => {
      return { ...state, ...initialState };
    },
    emptyTokens: (state) => {
      return { ...state, ...initialState };
    },
  },
});

export const {
  setDownloadTokens,
  setSearchTokens,
  incrementDownloadTokens,
  incrementSearchTokens,
  clearTokens,
  setUsedDownloadTokens,
  setUsedSearchTokens,
  emptyTokens,
  setSubscriptionId,
  updateCurrentPeriodEndTimestamp,
  setAllDataForTokens,
  updateSubscriptionStatus,
  incrementRecipeTokens,
} = tokensSlice.actions;

export const tokensReducer = tokensSlice.reducer;
