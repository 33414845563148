import React from "react";
import homeImageBackground from "../assets/images/homeBackground.png";
import Header from "../components/Header";
import HeroHome from "../components/HeroHome";

export default function Home() {
  return (
    <div className="flex flex-col bg-hero-pattern pb-12 ">
      {/* Background image */}
      {/* <div className="absolute h-auto inset-0  pt-16 box-content -z-1">
        <img
          className="absolute inset-0 w-full h-full object-cover "
          src={homeImageBackground}
          alt="About"
        />
        <div
          className="absolute inset-0 bg-gradient-to-t  from-gray-700 dark:from-gray-900"
          aria-hidden="true"
        ></div>
      </div> */}
      <Header />
      <div className="pt-36"></div>
      <HeroHome />
    </div>
  );
}
