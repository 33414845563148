import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import homeImageBackground from "../../assets/images/homeBackground.png";
import Header from "../../components/Header";
import ActivitySummary from "./Activities";
import UserProfileInfo from "./UserInfo";

import { ColorRing } from "react-loader-spinner";

import { getAuth } from "firebase/auth";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import ProfileActions from "./ProfileActions";
import Statistics from "./Statistics";

function Profile() {
  const auth = getAuth();
  const navigate = useNavigate();
  const currentUser = auth.currentUser;

  const [loading, setLoading] = useState(false);

  const [profile, setProfile] = useState(null);

  const { id } = useParams();
  const getProfile = async () => {
    setLoading(true);
    const db = getFirestore();
    const usersCollection = collection(db, "Users");
    const userId = id || currentUser.uid;

    try {
      const q = query(usersCollection, where("userId", "==", userId));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          const userData = doc.data();
          setProfile(userData);

          localStorage.setItem("photoURL", userData.photoURL);
        });
      } else {
        console.log("User not found");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error retrieving user:", error);
      setLoading(false);
    }
  };

  const uploadImageAndUpdateProfile = async (file, userId) => {
    const storage = getStorage();
    const storageRef = ref(storage, `profileImages/${userId}/${file.name}`);
    const db = getFirestore();

    try {
      // Upload file to Firebase Storage
      const snapshot = await uploadBytes(storageRef, file);
      const imageUrl = await getDownloadURL(snapshot.ref);

      // Update user document in Firestore with new image URL
      const userRef = collection(db, "Users");
      const userQuery = query(userRef, where("userId", "==", userId));
      const userSnapshot = await getDocs(userQuery);
      if (!userSnapshot.empty) {
        const userDoc = userSnapshot.docs[0];
        await updateDoc(doc(userRef, userDoc.id), { photoURL: imageUrl });
      }

      setProfile({ ...profile, photoURL: imageUrl });

      Swal.fire({
        icon: "success",
        title: "Profile image updated successfully",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      console.error("Error updating profile image:", error);
      throw error;
    }
  };

  const editProfile = async (data) => {
    try {
      const db = getFirestore();
      const userRef = collection(db, "Users");
      const userQuery = query(userRef, where("userId", "==", currentUser.uid));
      const userSnapshot = await getDocs(userQuery);
      if (!userSnapshot.empty) {
        const userDoc = userSnapshot.docs[0];
        await updateDoc(doc(userRef, userDoc.id), data);

        Swal.fire({
          icon: "success",
          title: "Profile updated successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        setProfile({ ...profile, ...data }); // Update profile state with new data
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Profile update failed",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const toggleVisibility = async (checked) => {
    try {
      const db = getFirestore();
      const userRef = collection(db, "Users");
      const userQuery = query(userRef, where("userId", "==", currentUser.uid));
      const userSnapshot = await getDocs(userQuery);
      if (!userSnapshot.empty) {
        const userDoc = userSnapshot.docs[0];
        await updateDoc(doc(userRef, userDoc.id), { visible: checked });
        setProfile({ ...profile, visible: checked }); // Update profile state with new visibility
      }
    } catch (error) {
      console.error("Error updating profile visibility:", error);
    }
  };

  const deleteProfile = async () => {
    try {
      const db = getFirestore();
      const userRef = collection(db, "Users");
      const userQuery = query(userRef, where("userId", "==", currentUser.uid));
      const userSnapshot = await getDocs(userQuery);
      if (!userSnapshot.empty) {
        const result = await Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        });

        if (result.isConfirmed) {
          const userDocRef = doc(db, "Users", userSnapshot.docs[0].id); // Correctly get the document reference
          await deleteDoc(userDocRef); // Delete the document
          Swal.fire({
            icon: "success",
            title: "Profile deleted successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          localStorage.removeItem("photoURL");
          localStorage.removeItem("uid");
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
          navigate("/signin");
        }
      }
    } catch (error) {
      console.error("Error deleting profile:", error);
    }
  };

  useEffect(() => {
    getProfile();
  }, [id]);

  return (
    <div
      className="flex flex-col min-h-screen overflow-hidden bg-cover bg-repeat-y"
      style={{
        backgroundImage: `url(${homeImageBackground})`,
      }}
    >
      {/*  Site header */}
      <Header profilePhoto={profile?.photoURL} />

      {/*  Page content */}
      <main className="grow">
        {loading && (
          <div className="z-50 absolute top-[50%] left-[50%] -translate-x-[50%]">
            <ColorRing
              visible={true}
              height="100"
              width="100"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
            />
          </div>
        )}
        {!loading && (
          <section className="relative">
            {!profile?.visible && (
              <p
                className="text-center text-black text-sm font-bold mt-16 p-4
                bg-yellow-500  mx-auto

              "
              >
                Your profile is not visible to others
              </p>
            )}
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
              transition={{ duration: 0.5 }}
              variants={{
                hidden: { opacity: 0, x: -50 },
                visible: { opacity: 1, x: 0 },
              }}
              className={`
                ${profile?.visible && "mt-16"}
              `}
            >
              <UserProfileInfo
                profile={profile}
                uploadImage={uploadImageAndUpdateProfile}
                editProfile={editProfile}
                setVisibility={toggleVisibility}
              />

              <ActivitySummary />

              {/*  */}

              <Statistics profile={profile} />

              <ProfileActions profile={profile} deleteProfile={deleteProfile} />

              {/* Des */}
            </motion.div>
          </section>
        )}
      </main>
    </div>
  );
}

export default Profile;
