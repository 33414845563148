import { useEffect, useState } from "react";

import PageHeader from "../../components/PageHeader/index";

import { motion } from "framer-motion";
import homeImageBackground from "../../assets/images/homeBackground.png";
import RecipesList from "../../components/BlogList/index";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import { SearchInput } from "./styles";

function Blogs() {
  const [articles, setArticles] = useState([]);
  const [filteredRecipes, setFilteredRecipes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        setLoading(true);
        const targetUrl =
          "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@Cookeai";
        const response = await fetch(targetUrl);
        const data = await response.json();

        setArticles(data.items);
        setFilteredRecipes(data.items);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };

    fetchArticles();
  }, []);
  const [search, setSearch] = useState("");

  function onChangeSearch(event) {
    setSearch(event.target.value);

    if (event.target.value.trim() === "") {
      setFilteredRecipes(articles);
      return;
    }

    const filtered = articles.filter((recipe) =>
      recipe.title.toLowerCase().includes(event.target.value.toLowerCase())
    );

    setFilteredRecipes(filtered);
  }

  return (
    <div className="flex flex-col relative min-h-screen overflow-hidden bg-hero-pattern">
      {/* Background image */}
      <div className="absolute h-auto inset-0 box-content -z-1">
        <img
          className="absolute inset-0 w-full h-full object-cover "
          src={homeImageBackground}
          width="1440"
          height="577"
          alt="About"
        />
      </div>

      <Header />

      <div className="pt-24 md:pt-32 pb-6 md:pb-10 2xl:pt-40 2xl:pb-16 justify-center items-center flex md:flex-row flex-col">
        <motion.div
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
          className="lg:w-[75%] rounded-xl md:w-[90%] space-y-3 px-12 py-6
                  bg-white/20 shadow-xl bg-clip-padding backdrop-blur-xl "
        >
          <PageHeader title="Blogs" subtitle="Check out our latest blogs" />
          <SearchInput
            onChange={onChangeSearch}
            value={search}
            placeholder="Enter your keywords"
            type="search"
          />

          {loading ? <Loader /> : <RecipesList recipes={filteredRecipes} />}
        </motion.div>
      </div>
    </div>
  );
}

export default Blogs;
