import { motion } from "framer-motion";
import React from "react";
import Swal from "sweetalert";
import homeImageBackground from "../../assets/images/homeBackground.png";
import Header from "../../components/Header";

import { useNavigate } from "react-router-dom";
import { playAudio } from "../../utils/btnAudio";

function Feedback() {
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    playAudio();
    e.preventDefault();
    Swal({
      icon: "success",
      title: "Thank you for your feedback",
      showConfirmButton: false,
      timer: 2000,
      confirmButtonColor: "#f0481a",
    });

    setTimeout(() => {
      navigate("/");
    }, 2000);
  };

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="grow">
        {/*  Page illustration */}
        <div className="absolute h-screen inset-0  pt-16 box-content -z-1">
          <img
            className="absolute inset-0 w-full h-full object-cover "
            src={homeImageBackground}
            width="1440"
            height="577"
            alt="About"
          />
          <div
            className="absolute inset-0 bg-gradient-to-t  from-gray-500 dark:from-gray-900"
            aria-hidden="true"
          ></div>
        </div>

        <section className="relative">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.5 }}
            variants={{
              hidden: { opacity: 0, x: -50 },
              visible: { opacity: 1, x: 0 },
            }}
            className="mt-40  bg-gradient-to-t from-gray-700 dark:from-gray-900 min-h-screen"
          >
            <div
              className="contact_us
            "
              id="contact_us"
            >
              <div className="flex flex-wrap justify-between align-center lg:px-20">
                <div
                  className="text-white lg:w-5/12 xl:w-4/12 2xl:w-3/16
                  contact_us_left
                "
                >
                  <h1 className="text-white text-xl font-bold lg:text-4xl lg:pt-20  2xl:text-5xl">
                    Share your Feedback
                  </h1>
                  <p className="text-white text-sm font-normal text-justify">
                    We value your feedback, and appreciate your contribution to
                    our growing product.
                  </p>
                </div>
                <div className="contact_us_right lg:w-6/12 ">
                  <form className="row">
                    <div className="col-6">
                      <input type="text" id="name" placeholder="Name" />
                    </div>
                    <div className="col-12">
                      <input
                        type="text"
                        id="emailAddress"
                        placeholder="Email Address"
                      />
                    </div>
                    {/* Type */}
                    <div className="col-12">
                      <select name="type" id="type">
                        <option value="feedback">General Inquiry</option>
                        <option value="complaint">Suggestion/Feedback</option>
                        <option value="suggestion">Report an Issue</option>
                      </select>
                    </div>
                    <div className="col-12">
                      <textarea
                        name="message"
                        id="message"
                        placeholder="Your message here!"
                        defaultValue={""}
                      />
                    </div>
                    <div className="col-12 submit_div">
                      <button
                        style={{ border: "none" }}
                        className="btn btn-primary "
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </motion.div>
        </section>
      </main>
    </div>
  );
}

export default Feedback;
