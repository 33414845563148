// React Hooks
import { useEffect, useRef, useState } from "react";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { setDish1, setDish2, setDish3, setResponse } from "../../redux/dishes";
import { incrementSearchTokens } from "../../redux/tokens";

// Third-party libraries
import { Configuration, OpenAIApi } from "openai";
import { PropagateLoader } from "react-spinners";
import ReactMarkdown from "react-markdown";

// Firebase
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { firestoredDB } from "../../config/firebase";

// Utils
import { getRandomJoke } from "../../utils/jokes";
import { StreamingParagraph } from "../../utils/streaming_effect";
import { updateUserTokens } from "../../utils/updateTokens";
import TextToSpeech from "../TextToSpeech";

export default function GenerateDishes({
  prompt,
  history,
  displayedText,
  setDisplayedText,
}) {
  const [result, setResult] = useState();
  const [loading, setLoading] = useState(false);
  const [oldResponse, setOldResponse] = useState("");
  const [intervalId, setIntervalId] = useState(null);
  const [showTextToSpeech, setShowTextToSpeech] = useState(false);
  const {
    user: { uid },
  } = useSelector((state) => state.auth.user);
  const { dish1, response } = useSelector((state) => state.dish);

  // import.meta.env.VITE_Open_AI_Key
  const configuration = new Configuration({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  });

  const openai = new OpenAIApi(configuration);

  const dispatch = useDispatch();
  const generateRecipe = async () => {
    setLoading(true);
    const response = await openai.createChatCompletion({
      model: "gpt-4o",
      temperature: 0.89,
      max_tokens: 500,
      messages: [
        {
          role: "system",
          content: `
          Imagine you are the latest and greatest cooking assistant AI, named Cook-E AI. You are going to be given inputs, in the form of a prompt, and you will then create 3 appropriate dishes and descriptions, with an engaging intro, and focusing mainly on the culture and flavor when describing the dishes.
          Here is an example in markdown format:
  
          **Let's cook up something delicious!**

          ### **Italian Dishes with Meat and Olive Oil (No Carrots) - 18th Century**
          
          Travel back in time to the 18th century and immerse yourself in the rich and flavorful world of Italian cuisine. During this period, Italian dishes were known for their simplicity, yet bold and robust flavors. Let's explore three classic Italian dishes that feature meat, olive oil, and do not include carrots, perfect for a hearty dinner
          
          **1. Osso Buco alla Milanese**

          Originating from Milan, Osso Buco alla Milanese is a traditional Italian dish that consists of cross-cut veal shanks braised with vegetables, white wine, and broth. The dish is slowly cooked until the meat is tender and falls off the bone, creating a rich and flavorful sauce. Olive oil is used to sear the meat and add depth to the sauce, which is traditionally served over creamy risotto or polenta.
  
          **2. Saltimbocca alla Romana**

          Hailing from Rome, Saltimbocca alla Romana is a classic Roman dish that features thin slices of veal topped with prosciutto and fresh sage leaves, then pan-fried in olive oil and white wine. The veal is tender and savory, with the saltiness of the prosciutto balancing the earthy flavor of the sage. This elegant dish is typically served with a side of sautéed greens or roasted potatoes, making it a perfect choice for a sophisticated dinner.

          **3. Pollo al Mattone**

          Pollo al Mattone, or "chicken under a brick," is a popular Tuscan dish that involves marinating a whole chicken in olive oil, herbs, and spices before grilling it under a weighted brick or skillet. The weight helps to flatten the chicken, ensuring even cooking and a crispy skin. The meat remains juicy and flavorful, infused with the aromas of rosemary, garlic, and lemon. This dish highlights the simplicity of Tuscan cuisine while delivering bold flavors that are sure to impress at any 18th-century dinner table.
        `,
        },
        ...history,
        {
          role: "user",
          content: `${prompt}`,
        },
      ],
    });
    setLoading(false);
    setResult(response.data.choices[0].message.content);

    dispatch(setResponse(response.data.choices[0].message.content));
    if (
      updateUserTokens(
        firestoredDB,
        collection,
        query,
        where,
        getDocs,
        updateDoc,
        doc,
        uid,
        "search"
      )
    ) {
      dispatch(incrementSearchTokens());
    }
    StreamingParagraph(
      response.data.choices[0].message.content,
      5,
      setDisplayedText
    );
    dispatch(setDish1(response.data.choices[0].message.content));
    setTimeout(() => {
      setShowTextToSpeech(true);
    }, 10000);
  };

  useEffect(() => {
    if (dish1?.length == 0) {
      setJoke("");
      getJoke();
      generateRecipe();
    } else {
      if (response) {
        setOldResponse(response);
      }
    }
  }, []);

  useEffect(() => {
    if (history?.length != 0) {
      setJoke("");
      getJoke();
      generateRecipe();
    }
  }, [history]);

  // joke
  const [joke, setJoke] = useState("");

  const jokesRef = collection(firestoredDB, "Jokes");

  const getJoke = () => {
    const randomIndex = Math.floor(Math.random() * 3);

    switch (randomIndex) {
      case 0:
        getRandomJoke(jokesRef, getDocs, setJoke, "alpha");
        break;
      case 1:
        getRandomJoke(jokesRef, getDocs, setJoke, "beta");
        break;
      case 2:
        getRandomJoke(jokesRef, getDocs, setJoke, "gamma");
        break;
    }
  };

  useEffect(() => {
    // Function to start the joke interval
    const startJokeInterval = () => {
      if (!intervalId) {
        const id = setInterval(() => {
          getJoke();
        }, 15000); // 15 seconds interval
        setIntervalId(id);
      }
    };

    // Function to clear the joke interval
    const clearJokeInterval = () => {
      if (intervalId) {
        clearInterval(intervalId);
        setIntervalId(null);
      }
    };

    if (loading) {
      startJokeInterval();
    } else {
      clearJokeInterval();
    }

    // Cleanup function to clear interval when component unmounts
    return () => {
      clearJokeInterval();
    };
  }, [loading]); // Dependency array includes 'loading' to re-run effect when it changes

  useEffect(() => {
    if (result) {
      var newResult = result.split("\n\n");
      const dish1 = result?.split("1.")[1]?.split("\n\n")[0]?.replace("**", "");
      const dish2 = result?.split("2.")[1]?.split("\n\n")[0]?.replace("**", "");
      const dish3 = result?.split("3.")[1]?.split("\n\n")[0]?.replace("**", "");

      dispatch(setDish1(dish1));
      dispatch(setDish2(dish2));
      dispatch(setDish3(dish3));
    }
  }, [result]);

  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [displayedText, loading]);

  const topRef = useRef(null);

  console.log(topRef, "topreff");
  return (
    <div
      className="flex w-full justify-center items-center flex-col flex-wrap"
      ref={topRef}
    >
      {/* <div ref={topRef} className="ref-class"></div> */}
      {loading ? (
        <div className="flex flex-col md:w-[80%]">
          <div className="my-6 w-full mx-auto flex items-center justify-center">
            <PropagateLoader color="#ee6713" />
          </div>

          <h1 className="my-6 h-full text-gray-200 mx-auto text-center md:text-2xl text-md">
            {joke.trim()}
          </h1>
        </div>
      ) : (
        <>
          <div className="w-full">
            <pre className="mb-7 text-gray-200 md:text-lg text-sm">
              <ReactMarkdown>{oldResponse || displayedText}</ReactMarkdown>
            </pre>
          </div>
          {showTextToSpeech && <TextToSpeech displayedText={displayedText} />}
        </>
      )}
    </div>
  );
}
