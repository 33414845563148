import React, { useState } from "react";
import "./Profile.css";
import { Switch, Modal } from "antd";
import profileImg from "../../assets/images/COOK-E.png";
import { format } from "date-fns";

import EditProfile from "./EditProfileModal";
import Swal from "sweetalert2";
import { playAudio } from "../../utils/btnAudio";

const UserProfileInfo = ({
  profile,
  uploadImage,
  editProfile,
  setVisibility,
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleFileChange = async (event) => {
    try {
      Swal.fire({
        icon: "success",
        title: "Uploading. It may take a few seconds to change",
        showConfirmButton: false,
        timer: 1500,
      });
      await uploadImage(event.target.files[0], profile.userId);
      // Optionally, refresh profile information here
    } catch (error) {
      alert("Failed to upload image");
    }
  };

  const handleEditData = (data) => {
    editProfile(data);
  };

  const handleVisibilityChange = (checked) => {
    playAudio();
    setVisibility(checked);
  };

  const createdAtDate = new Date(profile?.createdAt?.seconds * 1000);
  console.log(createdAtDate, "createdAtDate");
  return (
    <>
      {profile && (
        <div className="userInfo">
          <div className="userInfo__buttons">
            <button className="userInfo__button visibility">
              <span>Profile Visibility</span>
              <Switch
                defaultChecked={profile?.visible}
                onChange={handleVisibilityChange}
              />
            </button>
            <button
              className="userInfo__button edit"
              onClick={() => {
                playAudio();
                handleOpen();
              }}
            >
              Edit Profile
            </button>
            {/* <button className="userInfo__button edit">Delete</button> */}
          </div>

          {/* Profile Picture and Details */}
          <div className="userInfo__profile">
            <div className="userInfo__profilePictureContainer">
              <img
                className="userInfo__profilePicture"
                src={profile?.photoURL || profileImg}
                alt="Profile"
              />
              <button
                className="userInfo__editProfilePicture"
                onClick={() => {
                  playAudio();
                  document.getElementById("imageInput").click();
                }}
              >
                <input
                  type="file"
                  id="imageInput"
                  onChange={handleFileChange}
                  accept="image/*"
                  style={{
                    height: 0,
                    width: 0,
                    overflow: "hidden",
                    position: "absolute",
                    zIndex: -1,
                  }}
                />
                <span>Change</span>
              </button>
            </div>
            <div className="userInfo__details">
              <div className="userInfo__row">
                <span className="userInfo__title">User Name</span>
                <span className="userInfo__value">{profile?.username}</span>
              </div>
              <div className="userInfo__row">
                <span className="userInfo__title">Personal Name</span>
                <span className="userInfo__value">{profile?.personalName}</span>
              </div>
              <div className="userInfo__row">
                <span className="userInfo__title">Email</span>
                <span className="userInfo__value">{profile?.email}</span>
              </div>

              <div className="userInfo__row">
                <span className="userInfo__title">Joined</span>
                <span className="userInfo__value">
                  {profile?.createdAt?.seconds
                    ? format(createdAtDate, "MMM dd, yyyy")
                    : "N/A"}
                </span>
              </div>
            </div>
          </div>

          <EditProfile
            open={open}
            handleClose={handleClose}
            onSubmit={handleEditData}
            formData={{
              username: profile?.username,
              personalName: profile?.personalName,
            }}
          />
        </div>
      )}
    </>
  );
};

export default UserProfileInfo;
