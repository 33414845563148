import React from "react";

const ActivitySummary = ({ savedCount, sharedCount, createdCount }) => {
  savedCount = 20;
  sharedCount = 40;
  createdCount = 20;
  return (
    <div className="userInfo">
      <div className="bg-white shadow-2xl rounded-lg p-10">
        <h2 className="text-xl font-semibold text-gray-800 mb-4">
          Activity Summary
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 text-center">
          {/* Saved Recipes card */}
          <div className="flex flex-col items-center mb-4 rounded-lg p-8 bg-gray-200 border border-teal-600">
            <span className="text-2xl font-bold text-teal-600">
              {savedCount}
            </span>
            <span className="text-gray-600">Saved Recipes</span>
          </div>

          {/* Shared Recipes */}
          <div className="flex flex-col items-center mb-4 rounded-lg p-8 bg-gray-200 border border-orange-600">
            <span className="text-2xl font-bold text-orange-600">
              {sharedCount}
            </span>
            <span className="text-gray-600">Shared Recipes</span>
          </div>

          {/* Created Recipes */}
          <div className="flex flex-col items-center mb-4 rounded-lg p-8 bg-gray-200 border border-pink-600">
            <span className="text-2xl font-bold text-pink-600">
              {createdCount}
            </span>
            <span className="text-gray-600">Created Recipes</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivitySummary;
