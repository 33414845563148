import {
  addDoc,
  collection,
  doc,
  getDocs,
  updateDoc,
  where,
} from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import homeImageBackground from "../assets/images/homeBackground.png";
import { auth, firestoredDB, saveImageToStorage } from "../config/firebase";
import GenerateImageRecipe from "./open-ai/GenerateImageRecipe";
import GenerateInstructions from "./open-ai/GenerateInstructions";
import { query } from "firebase/database";
import { clearInstructions } from "../redux/instructions";
import {
  clearTitle,
  emptyRecipeAllImageByDishNumber,
  resetImageGenerationCount,
  setDishImage,
} from "../redux/title";
import { incrementDownloadTokens } from "../redux/tokens";
import { updateUserTokens } from "../utils/updateTokens";
// import firebase from 'firebase/app';
import "firebase/storage";
import { setImage } from "../redux/image";
import AddToCookbookModal from "./alerts/AddToCookbookModal";
import { playAudio } from "../utils/btnAudio";

function DisheSingle() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const moviesCollectionRef = collection(firestoredDB, "CookBook");

  const {
    user: { uid },
  } = useSelector((state) => state.auth.user);
  const {
    totalDownloadTokens,
    totalSearchTokens,
    usedDownloadTokens,
    usedSearchTokens,
    totalRecipeTokens,
    usedRecipeTokens,
  } = useSelector((state) => state.tokens);

  const { prompt: recipePrompt } = useSelector((state) => state.recipe);
  const { title } = useSelector((state) => state.title);
  const { dishNumber } = useSelector((state) => state.title);
  const abc = useSelector((state) => state);
  console.log(abc, "Abccc");
  const { image } = useSelector((state) => state.image);
  const { dish1 } = useSelector((state) => state.dish);
  const { prompt } = useSelector((state) => state.prompt);
  const { ingredients } = useSelector((state) => state.ingredients);
  const { instructions } = useSelector((state) => state.instructions);
  const [add, setAdd] = useState("Add To CookBook");
  const [openAddToCookbookModal, setOpenAddToCookbookModal] = useState(false);
  // history state
  const [history, setHistory] = useState([]);
  // const [title, setTitle] = useState("");
  const [alreadySaved, setAlreadySaved] = useState(false);
  const contentRef = useRef(null);
  const [displayedText, setDisplayedText] = useState("");
  const [oldInstructions, setOldInstructions] = useState("");
  const [loading, setLoading] = useState(false);
  const [listsData, setListsData] = useState([]);
  const [listDropDownOptions, setListDropDownOptions] = useState([]);
  const [selectedCookbookList, setSelectedCookbookList] = useState({ id: 1 });
  const [isFirstVariant, setIsFirstVariant] = useState(false);

  const onSubmitRecipe = async () => {
    setOpenAddToCookbookModal(false);

    if (usedDownloadTokens == -1 || usedDownloadTokens >= totalDownloadTokens) {
      Swal.fire({
        icon: "info",
        title: "You are out of Download Tokens",
        showConfirmButton: true,
        timer: 2000,
      });
      return;
    }

    if (alreadySaved) {
      Swal.fire({
        icon: "info",
        title: "Dish is Already Saved in the cookbook",
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    }
    setLoading(true);
    setAdd("Adding...");

    // Call the function with your data
    const { data } = await saveImageToStorage({ imageUrl: image });

    console.log("in", instructions);
    try {
      const res = await addDoc(moviesCollectionRef, {
        userId: auth?.currentUser?.uid,
        description: instructions || null,
        ingredients: instructions || null,
        imageUrl: data || null,
        title: title ? title : dish1,
        listId: selectedCookbookList?.id == 1 ? null : selectedCookbookList?.id,
      });

      setAlreadySaved(true);

      Swal.fire({
        icon: "success",
        title: "Recipe added successfully",
        showConfirmButton: false,
        timer: 3000,
        confirmButtonColor: "#f0481a",
      });
      setAdd("Add To CookBook");
      setLoading(false);
      if (
        updateUserTokens(
          firestoredDB,
          collection,
          query,
          where,
          getDocs,
          updateDoc,
          doc,
          uid,
          "download"
        )
      ) {
        dispatch(incrementDownloadTokens());
      }
    } catch (err) {
      setLoading(false);
      Swal.fire({
        icon: "info",
        title: err.message,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const findVariant = () => {
    if (usedRecipeTokens == -1 || usedRecipeTokens >= totalRecipeTokens) {
      Swal.fire({
        icon: "info",
        title: "You are out of Recipe Tokens",
        showConfirmButton: true,
        timer: 2000,
      });

      return;
    }

    if (alreadySaved) {
      setAlreadySaved(false);
      dispatch(setImage(""));
      dispatch(emptyRecipeAllImageByDishNumber(dishNumber));
      dispatch(resetImageGenerationCount());
      dispatch(
        setDishImage({
          dishNumber,
          url: "",
        })
      );
      dispatch(clearTitle());
      setHistory([
        ...history,
        {
          role: "user",
          content: recipePrompt,
        },
        {
          role: "system",
          content: instructions,
        },
        {
          role: "user",
          content:
            "Find a Variant. The response should be in detail as told. with a very short intro of dish.",
        },
      ]);
      return;
    }

    // if (!isFirstVariant) {
    console.log("hereee", dishNumber);
    dispatch(emptyRecipeAllImageByDishNumber(dishNumber));
    // setIsFirstVariant(true);
    // }
    Swal.fire({
      title: "Leave Recipe?",
      text: "This Will Conclude Your Token",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Leave - Do Not Save",
      denyButtonText: `Leave - But Save Recipe`,
      showCloseButton: true,
      dangerMode: true,
    }).then(async (result) => {
      if (result.isDenied) {
        await onSubmitRecipe();
        setAlreadySaved(false);
        dispatch(setImage(""));
        dispatch(emptyRecipeAllImageByDishNumber(dishNumber));
        dispatch(resetImageGenerationCount());
        dispatch(
          setDishImage({
            dishNumber,
            url: "",
          })
        );
        dispatch(clearTitle());

        setHistory([
          ...history,
          {
            role: "user",
            content: recipePrompt,
          },
          {
            role: "system",
            content: instructions,
          },
          {
            role: "user",
            content:
              "Find a Variant. The response should be in detail as told. with a very short intro of dish.",
          },
        ]);

        dispatch(clearInstructions());
        return;
      } else if (result.isConfirmed) {
        setAlreadySaved(false);
        dispatch(setImage(""));
        dispatch(emptyRecipeAllImageByDishNumber(dishNumber));
        dispatch(resetImageGenerationCount());
        dispatch(
          setDishImage({
            dishNumber,
            url: "",
          })
        );
        dispatch(clearTitle());
        setHistory([
          ...history,
          {
            role: "user",
            content: recipePrompt,
          },
          {
            role: "system",
            content: instructions,
          },
          {
            role: "user",
            content:
              "Find a Variant. The response should be in detail as told. with a very short intro of dish.",
          },
        ]);

        dispatch(clearInstructions());
        return;
      } else {
        return;
      }
    });
  };

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = contentRef.current.scrollHeight;
    }
  }, [displayedText]);

  const makeDropDownOptionsForListPopup = (lists) => {
    const options = [{ id: 1, name: "General" }];
    lists?.map((item, index) => {
      options?.push({
        id: item?.id,
        name: item?.name,
      });
    });
    setSelectedCookbookList(options[0]);
    setListDropDownOptions(options);
  };
  //Get Lists data
  const listCollectionRef = collection(firestoredDB, "Lists");

  const getLists = async () => {
    try {
      // const data = await getDocs(listCollectionRef);
      const data = await getDocs(listCollectionRef);
      const filteredData = data.docs
        .map((doc) => ({
          ...doc.data(),
          id: doc.id,
          createdAt: doc._document.createTime.timestamp.seconds,
        }))
        .sort((a, b) => a.createdAt - b.createdAt);

      const userData = filteredData.filter((item) => {
        return item.userId === auth?.currentUser?.uid;
      });
      makeDropDownOptionsForListPopup(userData);
      setListsData(userData);
    } catch (err) {
      console.error(err);
    }
  };

  const onAddToCookBookModalClick = async () => {
    playAudio();
    if (alreadySaved) {
      Swal.fire({
        icon: "info",
        title: "Dish is Already Saved in the cookbook",
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      setOpenAddToCookbookModal(true);
      // setLoading(true);
      if (listDropDownOptions?.length == 0) {
        await getLists();
      }
    }
  };

  return (
    <section className="relative ">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-24 md:pt-24 h-screen">
          {/* Background image */}
          <div className="absolute h-auto inset-0 pt-16 box-content -z-1">
            <img
              className="absolute inset-0 w-full h-full object-cover "
              src={homeImageBackground}
              width="1440"
              height="577"
              alt="About"
            />
          </div>

          <div
            ref={contentRef}
            className="text-lg bg-[#2a93dd40] px-4 py-4 rounded-xl overflow-y-scroll text-gray-400 h-[calc(100vh-240px)] md:h-[calc(100vh-180px)] backdrop-blur-md"
          >
            <div className="flex w-full justify-between">
              <div
                onClick={() => {
                  Swal.fire({
                    title: "Leave Recipe?",
                    text: "This Will Conclude Your Token",
                    icon: "warning",
                    showDenyButton: true,
                    confirmButtonText: "Leave - Do Not Save",
                    denyButtonText: `Leave - But Save Recipe`,
                    showCloseButton: true,
                    dangerMode: true,
                  }).then(async (result) => {
                    if (result.isDenied) {
                      return;
                    } else {
                      navigate("/get-dishes");
                    }
                  });
                }}
                className="my-3 cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-arrow-left"
                  width="40"
                  height="40"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#ff9300"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <line x1="5" y1="12" x2="19" y2="12" />
                  <line x1="5" y1="12" x2="11" y2="18" />
                  <line x1="5" y1="12" x2="11" y2="6" />
                </svg>
              </div>
            </div>
            <article className="">
              <figure className=" flex justify-center">
                <GenerateImageRecipe prompt={title} disheSinglePage />
              </figure>

              <GenerateInstructions
                prompt={recipePrompt}
                history={history ? history : null}
                title={title}
                displayedText={displayedText}
                setDisplayedText={setDisplayedText}
                oldInstructions={oldInstructions}
                setOldInstructions={setOldInstructions}
              />
            </article>
          </div>

          <div className="fixed flex md:space-x-3 md:flex-row flex-col w-fit left-0 right-0 z-90 bottom-4 m-auto">
            <div className="flex space-x-2 justify-center items-center ">
              <button
                // onClick={openModal}
                onClick={onAddToCookBookModalClick}
                disabled={loading}
                className="
            bg-gradient-to-r from-orange-100 to-orange-50  md:mx-0 
            font-medium  border border-transparent md:w-44 w-full p-2.5
             my-2 rounded text-white bg-teal-500 hover:bg-teal-400 transition duration-150 ease-in-out"
              >
                {add}
              </button>
              <button
                onClick={() => {
                  playAudio();
                  findVariant();
                }}
                className="
            bg-gradient-to-r from-orange-100 to-orange-50  md:mx-0 
            font-medium  flex items-center justify-center border border-transparent md:w-40 w-full p-2.5
             my-2 rounded text-white bg-teal-500 hover:bg-teal-400 transition duration-150 ease-in-out"
                disabled={loading}
              >
                Find Variant
              </button>
            </div>
            <div className="flex space-x-2 justify-center items-center">
              <button
                onClick={async () => {
                  playAudio();
                  if (!alreadySaved) {
                    Swal.fire({
                      title: "Leave Recipe?",
                      text: "This Will Conclude Your Token",
                      icon: "warning",
                      showDenyButton: true,
                      confirmButtonText: "Leave - Do Not Save",
                      denyButtonText: `Leave - But Save Recipe`,
                      showCloseButton: true,
                      dangerMode: true,
                    }).then(async (result) => {
                      if (result.isDenied) {
                        await onSubmitRecipe();
                        navigate("/get-dishes");
                      } else if (result.isConfirmed) {
                        navigate("/get-dishes");
                      } else {
                        return;
                      }
                    });
                  } else {
                    navigate("/get-dishes");
                  }
                }}
                className="
            bg-gradient-to-r from-orange-100 to-orange-50  md:mx-0 
            font-medium  flex items-center justify-center border border-transparent w-40 p-2.5
             my-2 rounded text-white bg-teal-500 hover:bg-teal-400 transition duration-150 ease-in-out"
                disabled={loading}
              >
                Back to Dishes
              </button>
              <button
                onClick={async () => {
                  playAudio();
                  if (!alreadySaved) {
                    Swal.fire({
                      title: "Leave Recipe?",
                      text: "This Will Conclude Your Token",
                      icon: "warning",
                      showDenyButton: true,
                      confirmButtonText: "Leave - Do Not Save",
                      denyButtonText: `Leave - But Save Recipe`,
                      showCloseButton: true,
                      dangerMode: true,
                    }).then(async (result) => {
                      if (result.isDenied) {
                        await onSubmitRecipe();
                        navigate("/discover-dishes");
                      } else if (result.isConfirmed) {
                        navigate("/discover-dishes");
                      } else {
                        return;
                      }
                    });
                  } else {
                    navigate("/discover-dishes");
                  }
                }}
                className="
            bg-gradient-to-r from-orange-100 to-orange-50  md:mx-0 
            font-medium  flex items-center justify-center border border-transparent w-40 p-2.5
             my-2 rounded text-white bg-teal-500 hover:bg-teal-400 transition duration-150 ease-in-out"
                disabled={loading}
              >
                Start Fresh
              </button>
            </div>
          </div>
        </div>
      </div>

      <AddToCookbookModal
        isOpen={openAddToCookbookModal}
        onDoneClick={() => onSubmitRecipe()}
        onCloseClick={() => {
          setOpenAddToCookbookModal(false);
          setLoading(false);
        }}
        listDropDownOptions={listDropDownOptions}
        selectedOption={selectedCookbookList}
        setSelectedOption={setSelectedCookbookList}
      />
    </section>
  );
}

export default DisheSingle;
