import { CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { MdDone } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { playAudio } from "../utils/btnAudio";

const MembershipCard = ({
  title,
  download_limit,
  handleBuySubscription,
  priceOutput,
  value,
  search_limit,
  price,
  description,
  view_react,
}) => {
  const navigate = useNavigate();

  const { isLoggedIn } = useSelector((state) => state.auth);

  // useState for loading
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(1);

  const handleInputChange = (event) => {
    const value = event.target.value;
    // Check if the entered value is within the desired range (1 to 9)
    if (/^[1-9]$/.test(value) || value === "") {
      setCount(value);
    }
  };

  const handleSubscription = () => {
    playAudio();
    if (!isLoggedIn) {
      navigate("/signin");
      return;
    }
    if (title !== "A La Carte") {
      handleBuySubscription(
        value === false ? price * count : price,
        title,
        search_limit * count,
        download_limit * count,
        setLoading
      );
    } else {
      handleBuySubscription(
        price * count,
        title,
        search_limit * count,
        download_limit * count,
        setLoading
      );
    }
  };

  return (
    <div
      className="relative flex flex-col h-full p-6 rounded-xl backdrop-filter backdrop-blur-xl shadow-xl border sm:border-gray-400 border-gray-200"
      data-aos="fade-up"
      data-aos-delay="700"
    >
      <div className="mb-4 pb-4 border-b border-white">
        <div className="font-bold text-xl text-orange-50 mb-1">{title}</div>
        <div className="inline-flex items-baseline mb-2">
          {priceOutput[value][2].includes("yr") ? (
            <span className="h2 text-orange-50">
              ${(+priceOutput[value][1] / 12).toFixed(2)}
            </span>
          ) : (
            <span className="h2 text-orange-50">${priceOutput[value][1]}</span>
          )}
          <span className="font-medium text-gray-200">/mo</span>
        </div>
        {priceOutput[value][2].includes("yr") && (
          <div className="text-gray-200 text-sm mb-2 text-right pr-0 text-orange-50">
            When billed annually
          </div>
        )}
        <div className="text-gray-200">{description}</div>
      </div>
      {/* <div className="font-medium mb-3">Features include:</div> */}
      <ul className="text-gray-200 -mb-3 grow">
        <li className="flex items-center  mb-3">
          <div className="border-2 bg-[#B5EEB3] border-green-600 rounded-full mr-2 ">
            <MdDone className="text-green-600 text-xl" />
          </div>

          <span>
            {download_limit * count} Recipe Searches / Downloads{" "}
            {title !== "A La Carte" && <span>Per Month</span>}
          </span>
        </li>

        <li className="flex items-center mb-3">
          <div className="border-2 bg-[#B5EEB3] border-green-600 rounded-full mr-2 ">
            <MdDone className="text-green-600 text-xl" />
          </div>

          <span>
            {search_limit * count} Dish searches{" "}
            {title !== "A La Carte" && <span>Per Month</span>}
          </span>
        </li>
        <li className="flex items-center  mb-3">
          <div className="border-2 bg-[#B5EEB3] border-green-600 rounded-full mr-2 ">
            <MdDone className="text-green-600 text-xl" />
          </div>

          <span>
            Access to Cookbook{" "}
            {(title === "Explorer Membership" ||
              title === "Foodie Membership") && (
              <span>, Edit & Share Recipes</span>
            )}
          </span>
        </li>

        {view_react != null && (
          <li className="flex items-center mb-3">
            <div className="border-2 bg-[#f4ef6f] border-yellow-300 rounded-full mr-2 ">
              <MdDone className="text-green-600 text-xl" />
            </div>

            <span>
              {title === "Basic Membership" && "View, React, and Download "}
              {title !== "Basic Membership" &&
                "View, React, Download and Post "}
              Recipes in Chopping Block (coming soon)
            </span>
          </li>
        )}
      </ul>
      <div className="p-3 mt-6">
        <div>
          {title == "A La Carte" && (
            <>
              <div className="flex justify-between items-center text-white">
                <span className="text-white">Buy Multiple:</span>
                <span>(1-9)</span>
              </div>
              <input
                value={count}
                onChange={(e) => handleInputChange(e)}
                type="number"
                className="w-full bg-transparent border border-white mb-2 mt-1 text-white"
              />
            </>
          )}
        </div>
        <button
          onClick={handleSubscription}
          className="btn-sm text-white bg-orange-50 hover:bg-orange-50 w-full h-10"
          href="#0"
        >
          <p>
            {loading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              "Get Started Now"
            )}
          </p>
        </button>
      </div>
    </div>
  );
};

export default MembershipCard;
