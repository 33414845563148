import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import YtVideo from "../../../assets/video.mp4";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "../misc/Headings.js";
import TeamIllustrationSrc from "../images/team-illustration-2.svg";
import { ReactComponent as SvgDotPattern } from "../images/dot-pattern.svg";
import YoutubeVideo from "../YoutubeVideo/YoutubeVideo.js";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row max-w-screen-xl mx-auto items-center gap-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative `;
const TextColumn = styled(Column)((props) => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.img((props) => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
]);

const DecoratorBlob = styled(SvgDotPattern)(() => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-600 -z-10`,
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;

const Steps = tw.ul`mt-12`;
const Step = tw.li`mt-8 flex flex-col md:flex-row items-center md:items-start`;
const StepText = tw.div`mt-3 md:mt-0 md:ml-6`;
const StepHeading = tw.h6`leading-none text-xl font-semibold`;
const StepDescription = tw.span`mt-3 max-w-xs leading-loose text-sm text-white font-medium`;

export default ({
  subheading = "Visual Flow",
  heading = "How It Works",
  imageSrc = TeamIllustrationSrc,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  imageDecoratorBlob = false,
  textOnLeft = true,
  steps = null,
  imageDecoratorBlobCss = null,
}) => {
  const defaultSteps = [
    {
      heading: "Step 1",
      description: "Input ingredients and preferences.",
    },
    {
      heading: "Step 2",
      description: "Receive and explore dish recommendations.",
    },
    {
      heading: "Step 3",
      description: "Save and manage recipes in your personal cookbook.",
    },
    {
      heading: "Step 4",
      description: "Engage with the community on The Chopping Block Blog.",
    },
  ];

  if (!steps) steps = defaultSteps;

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <video
            style={{
              borderRadius: "10px",
              border: "1px solid #f1f1f1",
              marginTop: "20px",
              height: "500px", // Increased container height
              objectFit: "cover", // Ensures the video maintains its aspect ratio
            }}
            controls
          >
            <source
              src={YtVideo}
              type="video/mp4"
              style={{
                padding: "10px",
                height: "500px", // Keeps internal video height the same
                borderRadius: "10px",
              }}
            />
            Your browser does not support the video tag.
          </video>
        </ImageColumn>

        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Steps>
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepText>
                    <StepHeading>
                      {step.heading}:{" "}
                      <StepDescription>{step.description}</StepDescription>
                    </StepHeading>
                  </StepText>
                </Step>
              ))}
            </Steps>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
