import { Configuration, OpenAIApi } from "openai";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setImage } from "../../redux/image";

import { makeStyles } from "@material-ui/core/styles";
import { LinearProgress, Tooltip } from "@mui/material";
import Refresh from "../../images/icons/refresh";
import {
  increaseImageGenerationCount,
  setDishImage,
  setOldDishImage,
  setRecipeAllImage,
} from "../../redux/title";
import { extractDishTitle } from "../../utils/extractTitle";

export default function GenerateImageRecipe({
  prompt,
  disheSinglePage = false,
}) {
  // log

  const { image } = useSelector((state) => state.image);
  const { dishNumber, images, imageGenerationCount, recipeAllImage } =
    useSelector((state) => state.title);

  const abc = useSelector((state) => state);

  const { subscriptionId, subScriptionPlan, subscriptionStatus } = useSelector(
    (state) => state.tokens
  );

  const [loading, setLoading] = useState(false);
  const [placeholder, setPlaceholder] = useState(
    "Search Bears with Paint Brushes the Starry Night, painted by Vincent Van Gogh.."
  );
  const configuration = new Configuration({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  });

  const dispatch = useDispatch();

  const openai = new OpenAIApi(configuration);

  const generateImage = async () => {
    try {
      setPlaceholder(`Search ${prompt}..`);
      setLoading(true);
      let title = extractDishTitle(prompt, 2);
      const res = await openai.createImage({
        prompt: `Give me a professional food commercial image of ${title} plated and set against an appropriate backdrop. No Text. Focus mainly on the dish`,
        n: 1,
        size: "1024x1024",
      });
      setLoading(false);
      // setResult(res.data.data[0].url);
      dispatch(setImage(res.data.data[0].url));
      if (dishNumber > 0 && dishNumber < 4) {
        dispatch(
          setDishImage({
            dishNumber,
            url: res.data.data[0].url,
          })
        );
        dispatch(
          setRecipeAllImage({
            dishNumber,
            url: res.data.data[0].url,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const shouldGenerateImage = () => {
    if (images?.[dishNumber]) {
      return false;
    } else {
      return true;
    }
  };
  useEffect(() => {
    if (prompt) {
      if (disheSinglePage) {
        if (shouldGenerateImage()) {
          generateImage();
        } else {
          dispatch(setImage(images?.[dishNumber]));
        }
      } else {
        generateImage();
      }
    }
  }, [prompt]);

  const changeImageToDisplay = (index) => {
    dispatch(setImage(recipeAllImage?.[dishNumber][index]));
    dispatch(
      setDishImage({
        dishNumber,
        url: recipeAllImage?.[dishNumber][index],
      })
    );
  };

  const useStyles = makeStyles({
    bar: {
      backgroundColor: "white",
    },
  });
  const classes = useStyles();

  return (
    <div className="app-main w-full flex flex-col justify-center items-center">
      {loading ? (
        <>
          <LinearProgress
            variant="indeterminate"
            title="test"
            classes={{ bar: classes.bar }}
            style={{
              width: "70%",
              marginRight: "4px",
              color: "white",
              marginTop: "30px",
            }}
          />
          {/* </div> */}
        </>
      ) : (
        <>
          {image?.length > 0 && prompt ? (
            <>
              <div div className="relative">
                <img
                  // className="result-image rounded-lg w-[512px] h-[512px]"
                  className="result-image rounded-lg max-w-[512px] max-h-[512px]"
                  src={image}
                  alt="result"
                />
                {subscriptionId && subscriptionStatus && (
                  <Tooltip
                    title={
                      subScriptionPlan === "Basic"
                        ? "Upgrade to Explorer or Foodie Membership generate new image"
                        : "Click here to generate new image"
                    }
                    placement="top"
                  >
                    <div
                      className={
                        "absolute bottom-[-5px] right-[-11px] bg-slate-200 rounded p-2 flex items-center gap-2"
                      }
                      onClick={() => {
                        if (
                          imageGenerationCount < 3 &&
                          subScriptionPlan !== "Basic"
                        ) {
                          dispatch(increaseImageGenerationCount());
                          generateImage();
                        }
                      }}
                    >
                      <Refresh
                        fill={imageGenerationCount == 3 ? "#777778" : "#F44378"}
                        className={`w-4 h-4 ${
                          imageGenerationCount == 3
                            ? "cursor-not-allowed"
                            : "cursor-pointer"
                        } `}
                      />
                      {imageGenerationCount > 0 && (
                        <p style={{ fontSize: "10px" }}>
                          {`${imageGenerationCount} / 3`}
                        </p>
                      )}
                    </div>
                  </Tooltip>
                )}
              </div>
              {recipeAllImage?.[dishNumber]?.length > 1 && (
                <div className="flex items-center gap-2 mt-4">
                  <Tooltip
                    title={"Click here to select old images"}
                    placement="top"
                  >
                    <p className="mr-4">Prev Images</p>
                  </Tooltip>
                  {recipeAllImage?.[dishNumber]?.map((item, index) => (
                    <p
                      className={`text-sm cursor-pointer ${
                        item === image && "text-red-500"
                      }`}
                      onClick={() => changeImageToDisplay(index)}
                    >
                      {index + 1}
                    </p>
                  ))}
                </div>
              )}
            </>
          ) : null}
        </>
      )}
    </div>
  );
}
