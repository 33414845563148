// React and Hooks
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  setAllDataForTokens,
  setDownloadTokens,
  setSearchTokens,
  setSubscriptionId,
  setUsedDownloadTokens,
  setUsedSearchTokens,
  updateCurrentPeriodEndTimestamp,
} from "./redux/tokens.js";

// Firebase

// Styles
import "./assets/css/style.css";
import GlobalStyles from "./styles/GlobalStyles";

// Pages and Components
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import PasswordChanged from "./pages/Auth/PasswordChanged";
import ResetPassword from "./pages/Auth/ResetPassword";
import RessetPasword from "./pages/Auth/RessetPassword";
import SignIn from "./pages/Auth/SignIn";
import SignUp from "./pages/Auth/SignUp";
import VerifyEmail from "./pages/Auth/VerifyEmail";
import ChoppingBlock from "./pages/ChoppingBlock/ChoppingBlock";
import DiscoverDishes from "./pages/DiscoverDishes";
import DisheDetails from "./pages/DisheDetails";
import GetDishesPage from "./pages/GetDishesPage";
import GetRecipe from "./pages/GetRecipe";
import Home from "./pages/Home";
import PageNotFound from "./pages/PageNotFound";
import PersonalCookbook from "./pages/PersonalCookbook";
import Shop from "./pages/Shop/Shop";

import Swal from "sweetalert2";
import SuccessPage from "./pages/Shop/SuccessPage";
import { checkExpiration } from "./utils/checkSubscriptionExpiration.js";
import SaaSProductLandingPage from "./pages/Landing/SaaSProductLandingPage.js";

// Helpers
import Blog from "./pages/Blogs/Blog/index.jsx";
import Blogs from "./pages/Blogs/index.jsx";
import DishDetailsPublic from "./pages/DishDetailsPublic.jsx";
import Contact from "./pages/Help/Contact";
import Feedback from "./pages/Help/Feedback";
import Help from "./pages/Help/Help";
import Profile from "./pages/Profile/Profile";
import {
  renderAuthRoute,
  renderAuthenticatedRoute,
} from "./utils/routerHelpers";
import RecipeShortUrl from "./pages/RecipeShortUrl.jsx";
import Paint from "./pages/Paint.jsx";

// Main App
function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const db = getFirestore();
      const userId = localStorage.getItem("uid");
      if (!userId) return;

      try {
        const usersQuery = query(
          collection(db, "Users"),
          where("userId", "==", userId)
        );
        const querySnapshot = await getDocs(usersQuery);
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            const userData = doc.data();
            dispatch(setAllDataForTokens(userData));

            const res = checkExpiration(userData?.currentPeriodEnd);
            console.log(userData, res);
            if (res) {
              setTimeout(() => {
                dispatch(setDownloadTokens(0));
                dispatch(setSearchTokens(0));
                dispatch(setUsedSearchTokens(-1));
                dispatch(setUsedDownloadTokens(-1));
                dispatch(setSubscriptionId(null));
                dispatch(updateCurrentPeriodEndTimestamp(null));
                // window.location.reload();
              }, 5000);
            }
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [dispatch]);

  const { isLoggedIn } = useSelector((state) => state?.auth);

  return (
    <>
      <GlobalStyles />

      <div
        style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <div style={{ flexGrow: 1 }}>
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<SaaSProductLandingPage />} />
            <Route
              path="/signin"
              element={renderAuthRoute(<SignIn />, isLoggedIn)}
            />
            {/* <Route path="/paint" element={<Paint />} /> */}
            <Route
              path="/signup"
              element={renderAuthRoute(<SignUp />, isLoggedIn)}
            />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/verify-email" element={<VerifyEmail />} />

            {/* User Account Routes */}
            <Route path="/change-password" element={<RessetPasword />} />
            <Route path="/password-changed" element={<PasswordChanged />} />

            {/* Shopping and Orders */}
            <Route path="/shop" element={<Shop />} />
            <Route path="/success" element={<SuccessPage />} />

            {/* User-Specific Routes (DIsh details page use for sharing) */}
            <Route
              path="/cookbook-details/dish-details/:dish_id"
              element={<DishDetailsPublic />}
            />

            {/* User-Specific Routes (require authentication) */}
            <Route
              path="/shop"
              element={renderAuthenticatedRoute(Shop, isLoggedIn)}
            />

            <Route
              path="/discover-dishes"
              element={renderAuthenticatedRoute(DiscoverDishes, isLoggedIn)}
            />
            <Route
              path="/personal-cookbook"
              element={renderAuthenticatedRoute(PersonalCookbook, isLoggedIn)}
            />
            <Route
              path="/get-dishes"
              element={renderAuthenticatedRoute(GetDishesPage, isLoggedIn)}
            />
            <Route
              path="/dishe-details"
              element={renderAuthenticatedRoute(DisheDetails, isLoggedIn)}
            />
            <Route
              path="/get-recipe"
              element={renderAuthenticatedRoute(GetRecipe, isLoggedIn)}
            />
            <Route
              path="/chopping-block"
              element={renderAuthenticatedRoute(ChoppingBlock, isLoggedIn)}
            />
            <Route
              path="/success"
              element={renderAuthenticatedRoute(SuccessPage, isLoggedIn)}
            />
            <Route
              path="/profile/:id"
              element={renderAuthenticatedRoute(Profile, isLoggedIn)}
            />

            <Route path="/help" element={<Help />} />

            <Route
              path="/contact"
              element={renderAuthenticatedRoute(Contact, isLoggedIn)}
            />

            <Route
              path="/feedback"
              element={renderAuthenticatedRoute(Feedback, isLoggedIn)}
            />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blog/:id" element={<Blog />} />

            <Route path="/share/:id" element={<RecipeShortUrl />} />

            {/* Fallback for Unknown Routes */}
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </div>
        {/* <Footer /> */}
      </div>
    </>
  );
}

export default App;
