import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "../../components/LandingPage/helpers/AnimationRevealPage.js";

import Hero from "../../components/LandingPage/hero/TwoColumnWithInput.js";
import Features from "../../components/LandingPage/features/ThreeColWithSideImage.js";
import MainFeature from "../../components/LandingPage/features/TwoColWithButton.js";
import { List, ListItem, ListItemText } from "@mui/material";
import MainFeature2 from "../../components/LandingPage/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "../../components/LandingPage/features/TwoColWithSteps.js";
import Pricing from "../../components/LandingPage/pricing/ThreePlans.js";
import Testimonial from "../../components/LandingPage/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "../../components/LandingPage/faqs/SingleCol.js";
import GetStarted from "../../components/LandingPage/cta/GetStarted";
import pickyImage from "../../components/LandingPage/images/picky.png";
import blog from "../../components/LandingPage/images/blog.png";
import rich from "../../components/LandingPage/images/rich.png";
import homeImageBackground from "../../assets/images/homeBackground.png";
import cookbook from "../../components/LandingPage/images/cookbook1.png";
import macHeroScreenshotImageSrc from "../../components/LandingPage/images/steps.png";

import Home from "../Home.jsx";
import Footer from "../../components/Footer.js";
import YoutubeVideo from "../../components/LandingPage/YoutubeVideo/YoutubeVideo.js"; // Import the new component

export default () => {
  const Subheading = tw.span`
  uppercase 
  tracking-widest 
  font-bold 
  text-2xl 
  bg-gradient-to-r 
  from-orange-50 
  via-secondary-100 
  to-primary-600 
  bg-clip-text 
  text-transparent
`;

  const HighlightedText = tw.span`  uppercase 

  bg-gradient-to-r 
  from-orange-50 
  via-secondary-100 
  to-primary-600 
  bg-clip-text 
  text-transparent`;

  return (
    <div>
      <div className="h-auto inset-0 fixed  box-content -z-1">
        <img
          className="w-full h-full inset-0 object-cover "
          src={homeImageBackground}
          width="1440"
          height="577"
          alt="About"
        />
        <div
          className="absolute inset-0 bg-gradient-to-t  from-gray-700 dark:from-gray-900"
          aria-hidden="true"
        ></div>
      </div>
      <AnimationRevealPage>
        <Home />
        <YoutubeVideo videoUrl="https://www.youtube.com/embed/JLdwNDRVzPM?si=0C90Fg5iu5IhKbE5" />{" "}
        {/* Add your video URL here */}
        <Features
          subheading={<Subheading>Features</Subheading>}
          heading={
            <>
              Transform your <HighlightedText>experience</HighlightedText> with{" "}
              <HighlightedText>Cook-E AI.</HighlightedText>
            </>
          }
        />
        <div className="bg-white bg-opacity-10 px-2 shadow-xl py-5 opacity-90  w-full rounded-xl">
          <MainFeature
            subheading={<Subheading>Personalized Dish Inputs</Subheading>}
            heading={
              <>
                Be as Picky as You’d <HighlightedText>Like.</HighlightedText>
              </>
            }
            description={
              "Start by using our intuitive interface to specify your ingredients, dietary preferences, and cuisine types to get three personalized dish recommendations with mouth-watering introductions!"
            }
            imageSrc={pickyImage}
            imageBorder={true}
            imageDecoratorBlob={true}
            textOnLeft={true}
          />

          <MainFeature
            subheading={<Subheading>Manage Your Personal Cookbook</Subheading>}
            heading={
              <>
                Recipe <HighlightedText>Management.</HighlightedText>
              </>
            }
            description={
              <List>
                <ListItem style={{ marginBottom: "10px" }}>
                  <ListItemText primary="Access all your saved recipes in one place. Organize, edit and share keyword searchable recipes into tailored lists like Weeknight Dinners or Holiday Baking." />
                </ListItem>
                <ListItem style={{ marginBottom: "10px" }}>
                  <ListItemText primary="Ask your personal assistant for help with ingredient variants and cooking techniques, or learn about each dish's cultural background and historical significance!" />
                </ListItem>
                <ListItem style={{ marginBottom: "10px" }}>
                  <ListItemText primary="Share your recipe collections with friends and family via a simple link." />
                </ListItem>
              </List>
            }
            imageSrc={cookbook}
            imageBorder={true}
            imageDecoratorBlob={true}
            textOnLeft={false} // Second feature with text on the right
          />

          <MainFeature
            subheading={<Subheading>Engage with the Community</Subheading>}
            heading={
              <>
                The Chopping Block <HighlightedText>Blog.</HighlightedText>
              </>
            }
            description={
              "Join a vibrant community where we, as well as Explorer and Foodie members share recipes and collections. Contribute your own recipes, comment on others, and build your culinary network! (Coming Soon)"
            }
            imageSrc={blog}
            imageBorder={true}
            imageDecoratorBlob={true}
            textOnLeft={true} // Third feature with text on the left
          />

          <MainFeature
            subheading={<Subheading>Explore Detailed Recipe Pages</Subheading>}
            heading={
              <>
                Rich and Full <HighlightedText>Content.</HighlightedText>
              </>
            }
            description={
              "View the recipe’s image, a comprehensive list of ingredients, step-by-step cooking instructions, setup, and nutritional estimates."
            }
            imageSrc={rich}
            imageBorder={true}
            imageDecoratorBlob={true}
            textOnLeft={false} // Fourth feature with text on the right
          />
        </div>
        <FeatureWithSteps
          subheading={<Subheading>STEPS</Subheading>}
          heading={
            <>
              Easy to <HighlightedText>Get Started.</HighlightedText>
            </>
          }
          textOnLeft={false}
          imageSrc={macHeroScreenshotImageSrc}
          imageDecoratorBlob={true}
          // imageDecoratorBlobCss={tw`xl:w-40 xl:h-40  -translate-x-1/2 left-1/2`}
        />
        <Pricing
          subheading={<Subheading>Pricing</Subheading>}
          heading={
            <>
              Reasonable & Flexible <HighlightedText>Plans.</HighlightedText>
            </>
          }
        />
        <Testimonial
          subheading={<Subheading>Testimonials</Subheading>}
          heading={
            <>
              Our Clients <HighlightedText>Love Us.</HighlightedText>
            </>
          }
          testimonials={[
            {
              stars: 5,
              profileImageSrc:
                "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
              heading: "Amazing User Experience",
              quote:
                "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
              customerName: "Charlotte Hale",
              customerTitle: "Director, Delos Inc.",
            },
            {
              stars: 5,
              profileImageSrc:
                "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
              heading: "Love the Developer Experience and Design Principles !",
              quote:
                "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
              customerName: "Adam Cuppy",
              customerTitle: "Founder, EventsNYC",
            },
          ]}
        />
        <FAQ
          subheading={<Subheading>FAQS</Subheading>}
          heading={
            <>
              <HighlightedText>Questions?</HighlightedText>
            </>
          }
          faqs={[
            {
              question: "How can I manage my recipes in Cook-E?",
              answer:
                "In Cook-E, you can manage your recipes through the Cookbook Management feature. This allows you to change the list position of dishes, and reorganize based on preference. You can also edit, delete, and add new recipes.",
            },
            {
              question: "What is the Interactive Recipe Experience in Cook-E?",
              answer:
                "The Interactive Recipe Experience in Cook-E includes a speech function enabling users to listen to dishes and recipe sections. This feature is available in mid and top tier memberships and allows users to select from several voices via settings.",
            },
            {
              question: "Can I share recipes with friends on Cook-E?",
              answer:
                "Yes, Cook-E has a Social & Sharing feature. You can share recipes directly with friends, view recipes shared with you, and log your shared recipes. There's also a 'Share with another user' feature for direct recipe sharing using Cook-E usernames.",
            },
            {
              question: "What is the 'Ask a Question' feature in Cook-E?",
              answer:
                "The 'Ask a Question' feature in Cook-E provides context-aware answers to your queries about specific dishes, their process, ingredients, or utensils. This feature is available on the dish’s saved details page/pop-up for Tier 2 and 3 members.",
            },
            {
              question: "How does the Recipe Free Trial work in Cook-E?",
              answer:
                "New users in Cook-E get 5 recipe credits for free upon signing up. These credits can be used before any subscription message appears. If you upgrade your account before using all 5 recipes, the remaining credits roll into your subscription.",
            },
            {
              question: "How can I cancel my Cook-E subscription?",
              answer:
                "You can cancel your Cook-E subscription at any time. To do so, navigate to the Account Management page and select the 'Cancel Subscription' option. You will be asked to confirm your cancellation before it is processed.",
            },
            {
              question: "How can I change my Cook-E subscription?",
              answer:
                "You can change your Cook-E subscription at any time. To do so, navigate to the Account Management page and select the 'Change Subscription' option. You will be asked to confirm your subscription change before it is processed.",
            },
            {
              question: "What can I ask my Recipe Assistant?",
              answer:
                "Chat AI in Cook-E is available for all users. You can interact with Chat AI by asking questions like what drinks would pair well with this dish? what can I substitute the pickles for? or How do I roll the sushi together? and much more!",
            },
          ]}
        />
        <GetStarted />
        <Footer />
      </AnimationRevealPage>
    </div>
  );
};
