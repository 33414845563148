import React from "react";
import SignIn from "../pages/Auth/SignIn";
import Home from "../pages/Home";

const renderAuthRoute = (component, isLoggedIn) =>
  isLoggedIn ? <Home /> : component;

const renderAuthenticatedRoute = (Component, isLoggedIn) => {
  return isLoggedIn ? <Component /> : <SignIn />;
};

export { renderAuthRoute, renderAuthenticatedRoute };
