import { query } from "firebase/database";
import { collection, getDocs, getFirestore, where } from "firebase/firestore";
import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import homeImageBackground from "../../assets/images/homeBackground.png";
import Header from "../../components/Header";
import { auth } from "../../config/firebase.js";
import { setAllDataForTokens } from "../../redux/tokens";

const SuccessPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentUser = auth.currentUser;

  const getData = async () => {
    const db = getFirestore();
    const usersCollection = collection(db, "Users");
    const userId = currentUser?.uid;

    try {
      const q = query(usersCollection, where("userId", "==", userId));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          const userData = doc.data();
          console.log(userData);
          dispatch(setAllDataForTokens(userData));
        });
      } else {
        console.log("User not found");
      }
    } catch (error) {
      console.error("Error retrieving user:", error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getData();
      navigate("/discover-dishes");
    }, 2000);
  }, []);
  return (
    <div className="flex flex-col min-h-screen  relative overflow-hidden ">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className=" ">
        {/*  Page illustration */}
        <div className="absolute  h-screen inset-0  box-content -z-1">
          <img
            className="relative inset-0 w-full h-full object-cover "
            src={homeImageBackground}
            width="1440"
            height="577"
            alt="About"
          />
          {/* <div className="absolute inset-0 bg-gradient-to-t  from-gray-500 dark:from-gray-900" aria-hidden="true"></div> */}
        </div>
        <section className="relative">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.5 }}
            variants={{
              hidden: { opacity: 0, x: -50 },
              visible: { opacity: 1, x: 0 },
            }}
            className="max-w-7xl md:mx-auto px-4 md:px-6 "
          >
            <div
              className="pt-32 pb-10 md:translate-y-[20%]  lg:translate-y-0   lg:pb-16 
            flex justify-center items-center h-screen"
            >
              <div className="bg-white bg-opacity-10 px-2 shadow-xl py-5 opacity-90 md:w-[70%] lg:w-[45%] w-full rounded-xl backdrop-blur-md">
                {/* Page header */}
                <div className="max-w-3xl mx-auto text-center pb-12 md:pb-10">
                  <h1 className="h1  text-white">Payment Successfull</h1>
                </div>
              </div>
            </div>
          </motion.div>
        </section>
      </main>
    </div>
  );
};

export default SuccessPage;
