export const extractDishTitle = (sentence, type = 1) => {
  // Phrases to remove
  const phrasesType = {
    1: ["Of course!", "Certainly!", "Here's the"],
    2: [
      "Of course!",
      "Certainly!",
      "Here's the",
      "with detailed instructions",
      ":",
      "Recipe",
      "of",
      "for",
    ],
  };

  // Remove the phrases
  let title = sentence;
  phrasesType[type]?.forEach((phrase) => {
    title = title?.replace(new RegExp(phrase, "i"), "");
  });

  // Trim any leading or trailing whitespace
  title = title?.trim();
  title = title?.charAt(0).toUpperCase() + title?.slice(1);

  // Return the extracted title
  return title;
};
