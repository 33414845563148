import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import homeImageBackground from "../../../assets/images/homeBackground.png";
import { RecipeContainer, RecipeItemContainer } from "./styles";

import Header from "../../../components/Header";
import PageHeader from "../../../components/PageHeader";

function Blog() {
  const params = useParams();
  const [blog, setBlog] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    const blog = JSON.parse(localStorage.getItem("article"));

    console.log(blog);
    setBlog(blog);
    setIsLoading(false);
  }, []);

  return (
    <div className="flex flex-col relative min-h-screen overflow-hidden bg-hero-pattern">
      {/* Background image */}
      <div className="absolute h-auto inset-0 box-content -z-1">
        <img
          className="absolute inset-0 w-full h-full object-cover "
          src={homeImageBackground}
          width="1440"
          height="577"
          alt="About"
        />
      </div>

      <Header />

      <div className="pt-24 md:pt-32 pb-6 md:pb-10 2xl:pt-40 2xl:pb-16 justify-center items-center flex md:flex-row flex-col">
        <motion.div
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
          className="lg:w-[75%] rounded-xl md:w-[90%] space-y-3 px-12 py-6
                  bg-white/20 shadow-xl bg-clip-padding backdrop-blur-xl "
        >
          <RecipeContainer>
            {isLoading ? (
              <h2>Loading.....</h2>
            ) : (
              <RecipeItemContainer>
                <PageHeader title={blog.title} />
                <p className="published">
                  Published on {new Date(blog.pubDate).toDateString()}
                </p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${blog.description}`,
                  }}
                ></div>
              </RecipeItemContainer>
            )}
          </RecipeContainer>
        </motion.div>
      </div>
    </div>
  );
}

export default Blog;
