import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import AutoCompleteInput from "../AutoCompleteInput";
import { playAudio } from "../../utils/btnAudio";

const AddToCookbookModal = ({
  isOpen,
  onDoneClick,
  onCloseClick,
  listDropDownOptions,
  selectedOption,
  setSelectedOption,
}) => {
  const { subScriptionPlan, subscriptionStatus } = useSelector(
    (state) => state.tokens
  );

  const handleAddToList = () => {
    playAudio();
    if (
      subscriptionStatus &&
      subScriptionPlan === "Basic" &&
      selectedOption.name !== "General"
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You can only add dish to general list with basic plan. For saving to custom list, please upgrade to Explorer or Premium plan.",
      });
      return;
    }
    onDoneClick();
  };
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={onCloseClick}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform  rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Add to list
                  </Dialog.Title>

                  <div className="mt-2">
                    {/* <input className=' px-2 py-2 my-2' value={newList} onChange={handleChange} placeholder="Enter a new list name" /> */}
                    {listDropDownOptions ? (
                      <AutoCompleteInput
                        options={listDropDownOptions}
                        selected={selectedOption}
                        setSelected={setSelectedOption}
                      />
                    ) : (
                      <p>Loading lists, Please wait ...</p>
                    )}
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex text-white justify-center rounded-md border border-transparent !bg-orange-50 px-4 py-2 text-sm font-medium  hover:bg-orange-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={handleAddToList}
                    >
                      Add
                    </button>
                    <button
                      type="button"
                      className="inline-flex text-orange-700 justify-center rounded-md border border-transparent  px-4 py-2 text-sm font-medium  focus-visible:ring-2 "
                      onClick={() => {
                        playAudio();
                        onCloseClick();
                      }}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default AddToCookbookModal;
