import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import homeImageBackground from "../assets/images/homeBackground.png";
import { auth, firestoredDB } from "../config/firebase";
import { setRecipes } from "../redux/search";
import RecipeCard from "./RecipeCard";
import Swal from "sweetalert";
import CustomModal from "./alerts/CustomModal";
import EditListModal from "./alerts/EditListModal";
import RecipeCardDetails from "./alerts/RecipeCardDetails";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import CookBookLists from "./CookBookLists";
import { playAudio } from "../utils/btnAudio";
import { setCLickedListId } from "../redux/cookbook";
export default function CookBook() {
  const [loading, setLoading] = useState(false);
  const [cookBookData, setCookBookData] = useState([]);
  const [listsData, setListsData] = useState([]);
  const [search, setSearch] = useState("");
  // const [clickedListId, setCLickedListId] = useState(null);
  const [error, setError] = useState("");
  let [isOpen, setIsOpen] = useState(false);
  let [isOpenR, setIsOpenR] = useState(false);
  const [totalRecipeData, setTotalRecipeData] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  const handleChange = (e) => {
    setIsSearching(true);
    setSearch(e.target.value);
  };
  const dispatch = useDispatch();
  //Get Lists data
  const listCollectionRef = collection(firestoredDB, "Lists");

  const { clickedListId } = useSelector((state) => state.cookbook);

  const getLists = async () => {
    setLoading(true);
    try {
      // Ensure the user is authenticated
      if (!auth?.currentUser?.uid) {
        console.error("User is not authenticated");
        setLoading(false);
        return;
      }

      const userId = auth.currentUser.uid;
      const q = query(listCollectionRef, where("userId", "==", userId));
      const data = await getDocs(q);

      const filteredData = data.docs
        .map((doc) => ({
          ...doc.data(),
          id: doc.id,
          createdAt: doc._document.createTime.timestamp.seconds,
        }))
        .sort((a, b) => a.createdAt - b.createdAt);

      filteredData.sort((a, b) => a?.order - b?.order);
      setListsData(filteredData);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };
  useEffect(() => {
    getLists();
  }, [auth?.currentUser]);

  useEffect(() => {
    getLists();
  }, [clickedListId]);

  //Get Cookbook data
  const cookbookCollectionRef = collection(firestoredDB, "CookBook");
  // auth?.currentUser?.uid
  const getCookBookList = async () => {
    setLoading(true);
    try {
      const data = await getDocs(cookbookCollectionRef, orderBy("order"));
      const totalRecords = data.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      // const totalRecords = totalRecordsAll?.filter(
      //   (item) => item.userId === auth?.currentUser?.uid
      // );
      const userData =
        clickedListId === null
          ? totalRecords.filter(
              (item) =>
                item.userId === auth?.currentUser?.uid && item.listId === null
            )
          : totalRecords.filter(
              (item) =>
                item.userId === auth?.currentUser?.uid &&
                item.listId === clickedListId
            );

      // // Sort userData based on the 'bookmarked' key
      userData.sort((a, b) => a?.order - b?.order);
      userData.sort((a, b) =>
        a.bookmarked === b.bookmarked ? 0 : a.bookmarked ? -1 : 1
      );

      setCookBookData(userData);
      // reverse the list
      // setCookBookData(userData.reverse());
      setTotalRecipeData(totalRecords);
      dispatch(setRecipes(userData));
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCookBookList();
  }, []);
  useEffect(() => {
    getCookBookList();
  }, [clickedListId]);

  useEffect(() => {
    if (search == "") {
      if (isSearching) {
        dispatch(setCLickedListId(null));
        setIsSearching(false);
      }
      const userData = totalRecipeData?.filter(
        (item) => item.userId === auth?.currentUser?.uid && item.listId === null
      );

      // // Sort userData based on the 'bookmarked' key
      userData.sort((a, b) => a?.order - b?.order);
      userData.sort((a, b) =>
        a.bookmarked === b.bookmarked ? 0 : a.bookmarked ? -1 : 1
      );
      setCookBookData(userData);
    } else {
      const filteredByKeyword = totalRecipeData?.filter((cook) =>
        cook?.description?.toLowerCase().includes(search?.toLowerCase())
      );
      // console.log(totalRecipeData,search?.toLowerCase(),filteredByKeyword)
      const filteredByUser = filteredByKeyword?.filter(
        (item) => item.userId === auth?.currentUser?.uid
      );

      // const searchedItem = totalRecipeData?.filter((cook) =>
      //   cook?.description?.toLowerCase().includes(search?.toLowerCase())
      // );
      setCookBookData(filteredByUser);
    }
  }, [search]);

  const [newList, setNewList] = useState("");
  const handleChange2 = (e) => {
    console.log("here");
    setNewList(e.target.value);
  };

  const listsCollectionRef = collection(firestoredDB, "Lists");

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // Do nothing if dropped outside the list
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;

    // Find the index of the last bookmarked item
    const lastBookmarkedIndex = cookBookData.reduce(
      (lastIndex, item, index) => (item.bookmarked ? index : lastIndex),
      -1
    );

    // Check if the item is being moved above any bookmarked item
    if (destination.index <= lastBookmarkedIndex) {
      // Adjust the destination index to be after the last bookmarked item
      destination.index = lastBookmarkedIndex + 1;
    }

    // Reorder the items
    const reorderedItems = reorder(
      cookBookData,
      source.index,
      destination.index
    );

    // Set the state with reordered items
    setCookBookData(reorderedItems);

    // Update the order in Firestore
    updateCookBookOrder(reorderedItems);
  };

  const onSubmitList = async () => {
    console.log("submitting");
    try {
      if (!newList) {
        alert("Please enter a valid value");
      } else {
        await addDoc(listsCollectionRef, {
          userId: auth?.currentUser?.uid,
          name: newList,
        });
        getCookBookList();
        getLists();
        Swal({
          icon: "success",
          title: "List added successfully",
          showConfirmButton: false,
          timer: 2000,
          confirmButtonColor: "#f0481a",
        });
      }
    } catch (err) {
      console.log(err.message);

      Swal({
        icon: "info",
        title: err.message,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  function closeModal() {
    console.log("closingggg");
    const alreadyExist = listsData?.filter(
      (item) => item?.name?.toLowerCase() === newList?.toLowerCase()
    );
    if (alreadyExist?.length > 0) {
      setError("A list is already exist with this name");
    } else {
      setError("");
      onSubmitList();
      setIsOpen(false);
      setNewList("");
    }
  }

  function close() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  function closeModalR() {
    setIsOpenR(false);
  }

  function closeR() {
    setIsOpenR(false);
  }

  function openModalR() {
    setIsOpenR(true);
  }

  const [listId, setList] = useState("");
  const handleChange3 = (e) => {
    setList(e.target.value);
  };

  const { recipeCard } = useSelector((state) => state.recipeCard);

  let [deleteR, setDeleting] = useState(false);

  const deleteList = async (id) => {
    Swal({
      title: "Are you sure you want to delete?",
      text: "Once deleted you can not undo",
      icon: "warning",
      buttons: ["No, cancel It!", "Yes, I am sure!"],
      dangerMode: true,
    }).then(async (isConfirm) => {
      if (isConfirm) {
        // navigate("/discover-dishes");
        setDeleting(true);
        try {
          const ifRecipesExistInList = totalRecipeData?.filter(
            (item) => item?.listId == id
          );

          if (ifRecipesExistInList?.length > 0) {
            Swal({
              icon: "error",
              title:
                "List can't be deleted as there are some recipes associated with this List!",
              showConfirmButton: false,
              timer: 3000,
            });
          } else {
            // const listDoc = doc(firestoredDB, "Lists", id);

            // // Fetch recipes associated with the list
            // const recipesQuery = query(cookbookCollectionRef, where("listId", "==", id));

            // const recipesSnapshot = await getDocs(recipesQuery);

            // const batch = writeBatch(firestoredDB);

            // // Update recipes to set listId to null
            // recipesSnapshot.forEach((recipeDoc) => {
            //   batch.update(recipeDoc.ref, { listId: null });
            // });

            // // Commit the batch update
            // await batch.commit();

            const movieDoc = doc(firestoredDB, "Lists", id);
            await deleteDoc(movieDoc);
            getLists();
            Swal({
              icon: "success",
              title: "List deleted successfully",
              showConfirmButton: false,
              timer: 3000,
              confirmButtonColor: "#f0481a",
            });
          }
        } catch (error) {
          Swal({
            icon: "info",
            title: error.message,
            showConfirmButton: false,
            timer: 2000,
          });
        }
        setDeleting(false);
      } else return;
    });
  };

  //Edit modal
  const updateListName = async () => {
    try {
      const movieDoc = doc(firestoredDB, "Lists", clickedListId);
      await updateDoc(movieDoc, { name: newListName });
      const updatedData = listsData?.map((item) => {
        if (item?.id == clickedListId) {
          return { ...item, name: newListName };
        }
        return item;
      });

      setListsData(updatedData);
      // getLists();
      Swal({
        icon: "success",
        title: "List renamed successfully",
        showConfirmButton: false,
        timer: 5000,
        confirmButtonColor: "#f0481a",
      });
    } catch (error) {
      Swal({
        icon: "info",
        title: error.message,
        showConfirmButton: false,
        timer: 6000,
      });
    }

    // getCookBoookData()
  };

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  function closeEditListModalAndSubmit() {
    // onSubmitEditListName()
    const alreadyExist = listsData?.filter(
      (item) => item?.name?.toLowerCase() === newListName?.toLowerCase()
    );

    if (alreadyExist?.length > 0) {
      setError("A list is already exist with this name");
    } else {
      setError("");
      updateListName();
      setIsEditModalOpen(false);
      setNewListName("");
    }
  }

  function closeEditListModal() {
    setIsEditModalOpen(false);
    setNewListName("");
  }

  function openEditListModal() {
    setIsEditModalOpen(true);
  }

  const [newListName, setNewListName] = useState("");
  const handleChangeEditListName = (e) => {
    setNewListName(e.target.value);
  };

  const updateBookmarkedStatus = async (id, status) => {
    try {
      const movieDoc = doc(firestoredDB, "CookBook", id);

      await updateDoc(movieDoc, {
        bookmarked: status,
      });
      const updatedData = cookBookData?.map((item, index) => {
        if (item?.id == id) {
          return {
            ...item,
            bookmarked: status,
          };
        } else return item;
      });

      //   userData.sort((a, b) =>
      //   a.bookmarked === b.bookmarked ? 0 : a.bookmarked ? -1 : 1
      // );
      setCookBookData(updatedData);
    } catch (error) {
      console.log(error);
      Swal({
        icon: "info",
        title: error.message,
        showConfirmButton: false,
        timer: 10000,
      });
    }

    // getCookBoookData()
  };

  const updateCookBookOrder = async (orderedData) => {
    setLoading(true); // Assuming you have a loading state
    try {
      // Create a batch to update all documents in one go
      const batch = writeBatch(firestoredDB);

      // Loop over your ordered data
      orderedData.forEach((item, index) => {
        // Check if the document ID exists in the ordered array
        const docRef = doc(firestoredDB, "CookBook", item.id);

        if (orderedData.some((orderedItem) => orderedItem.id === item.id)) {
          // If the document is in the ordered array, update the order
          batch.update(docRef, { ...item, order: index });
        }
      });

      // Commit the batch
      await batch.commit();
    } catch (err) {
      console.error("Error updating documents: ", err);
      // Handle the error appropriately
    }
    setLoading(false);
  };

  console.log(cookBookData, "cookBookData");
  return (
    <section className="relative  min-h-screen">
      <div className=" mx-0 px-4 sm:px-6">
        <div className="pt-20 pb-10 md:pt-20 md:pb-0">
          <div className="max-w-5xl mx-auto">
            {/* Background image */}
            <div className="absolute h-auto inset-0 box-content -z-1">
              <img
                className="absolute inset-0 w-full h-[100%] object-cover "
                src={homeImageBackground}
                alt="About"
              />
              {/* <div className="absolute inset-0 bg-gradient-to-t  from-gray-700 dark:from-gray-900" aria-hidden="true"></div> */}
            </div>

            {/* Background image */}
            <motion.div
              // className="md:-mt-20"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
              transition={{ duration: 0.5 }}
              variants={{
                hidden: { opacity: 0, x: -50 },
                visible: { opacity: 1, x: 0 },
              }}
              className={`no-scrollbar mt-5 flex flex-col items-center px-1 py-5   
                            ${
                              isOpenR
                                ? ""
                                : "bg-gradient-to-r from-[#66BFFF40] to-[#66BFFF3E]"
                            } shadow-lg
                             rounded-xl bg-opacity-50
                        w-full flex-col  md:h-[calc(100vh-120px)] overflow-y-hidden`}
            >
              {isOpenR ? (
                <></>
              ) : (
                <>
                  {" "}
                  <div className="px-1 pb-2">
                    <h1
                      className={`sm:text-3xl text-2xl font-black text-transparent bg-gradient-to-r from-teal-400 from-40% via-orange-50 to-70% to-orange-100 bg-clip-text`}
                    >
                      Personal Cookbook
                    </h1>
                  </div>
                  {/* Search block */}
                  <div className=" w-full lg:w-8/12 bg-gradient-to-r from-teal-400 from-40% via-orange-50 to-70% to-orange-100 md:mt-2 p-[2px] rounded-full">
                    <div className="flex justify-center">
                      <input
                        value={search}
                        name="search"
                        onChange={handleChange}
                        type="tel"
                        className=" w-full h-full  pl-4 !rounded-full py-3 focus:outline-none focus:bg-[#FFFFFF99]"
                        placeholder="Search what you want..."
                        aria-label="Phone number"
                      />
                    </div>
                  </div>
                  <div
                    className="md:w-[90%] md:mx-[5%] flex w-full md:flex-row
                 flex-col pt-4 justify-start items-start space-x-0 space-y-3
                  md:space-y-0 md:space-x-2 h-[88%]"
                  >
                    <CookBookLists
                      openModal={openModal}
                      clickedListId={clickedListId}
                      loading={loading}
                      listsData={listsData}
                      onListClick={(id) => {
                        playAudio();
                        dispatch(setCLickedListId(id));
                      }}
                      onDeleteIconClick={(id) => deleteList(id)}
                      search={search}
                      onPencilIconClick={(id) => {
                        dispatch(setCLickedListId(id));
                        setIsEditModalOpen(true);
                      }}
                    />

                    {/* Left Side */}

                    {/* Right Side */}
                    <div className="no-scrollbar flex-[70%] h-[98%] overflow-y-scroll overflow-x-hidden px-4 sm:pb-10">
                      {cookBookData.length === 0 ? (
                        <p className="text-white text-center text-xl">
                          Your Cookbook is Empty
                        </p>
                      ) : (
                        <>
                          <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable">
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                >
                                  {cookBookData?.map((recipe, idx) => {
                                    return (
                                      <Draggable
                                        key={recipe.id}
                                        draggableId={recipe.id}
                                        index={idx}
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            // {...provided.dragHandleProps}
                                          >
                                            <RecipeCard
                                              onClickRC={openModalR}
                                              key={idx}
                                              image={recipe?.imageUrl}
                                              db={firestoredDB}
                                              id={recipe.id}
                                              title={recipe.title}
                                              shareUrl={recipe?.shareUrl}
                                              description={recipe.description}
                                              lists={listsData}
                                              getCookBoookData={getCookBookList}
                                              handleChangeL={handleChange3}
                                              listName={listId}
                                              listValue={listId}
                                              clickedListId={clickedListId}
                                              notes={recipe?.notes}
                                              bookmarked={
                                                recipe?.bookmarked ?? false
                                              }
                                              updateBookmarkedStatus={
                                                updateBookmarkedStatus
                                              }
                                              dragHandleProps={
                                                provided.dragHandleProps
                                              } // Pass drag
                                            />
                                          </div>
                                        )}
                                      </Draggable>
                                    );
                                  })}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </>
                      )}
                      {loading && (
                        <p className="text-white text-center text-xl">
                          Loading data... Please wait
                        </p>
                      )}
                    </div>
                  </div>
                </>
              )}
            </motion.div>

            <CustomModal
              isOpen={isOpen}
              close={close}
              openModal={openModal}
              newList={newList}
              handleChange={handleChange2}
              closeModal={closeModal}
              error={error}
            />
            <EditListModal
              isOpen={isEditModalOpen}
              close={closeEditListModal}
              openModal={openEditListModal}
              newList={newListName}
              handleChange={handleChangeEditListName}
              closeModal={closeEditListModalAndSubmit}
              error={error}
            />

            <RecipeCardDetails
              isOpen={isOpenR}
              close={closeR}
              openModal={openModalR}
              recipeCardData={recipeCard}
              closeModal={closeModalR}
            />

            {/* <EditRecipe isOpen={isOpen2} close={close2} closeModal={closeModal2} /> */}
          </div>
        </div>
      </div>
    </section>
  );
}
