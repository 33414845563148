import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  count: 0,
};
const chatbotSlice = createSlice({
  name: "chatbot",
  initialState,

  reducers: {
    setChatCount: (state, { payload }) => {
      console.log(payload, "payyyy");
      state.count = payload;
    },
    increaseChatCount: (state, { payload }) => {
      state.count += 1;
    },
    increaseChatCountBy2: (state, { payload }) => {
      state.count += 2;
    },
  },
});

export const { setChatCount, increaseChatCount, increaseChatCountBy2 } =
  chatbotSlice.actions;
export const chatbotReducer = chatbotSlice.reducer;
