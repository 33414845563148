import React, { useState } from "react";
import { MdClose, MdSend } from "react-icons/md";
import Markdown from "react-markdown";
import { useSelector } from "react-redux";
import { ResizableBox } from "react-resizable";
import styles from "./Chatbot.module.css";
const Chatbot = ({
  messages,
  sendMessage,
  chatbotAnswerLoading,
  chatLimit,
  input,
  setInput,
  setShowChat,
}) => {
  const { count } = useSelector((state) => state.chatbot);

  const [chatbotWidth, setChatbotWidth] = useState(350);
  const [chatbotHeight, setChatbotHeight] = useState(450);
  const onResize = (event, { size }) => {
    setChatbotWidth(size.width);
    setChatbotHeight(size.height);
  };

  return (
    <ResizableBox
      height={chatbotHeight}
      width={chatbotWidth}
      className="!fixed bottom-2 right-5"
      minConstraints={[250, 350]}
      maxConstraints={[1100, 1000]}
      resizeHandles={["n", "w", "nw"]}
      onResize={onResize}
    >
      <div className=" w-full h-full bg-white shadow-md rounded-t-lg overflow-hidden transition-transform duration-300 transform translate-y-0">
        <div className="h-[40px] bg-gradient-to-r from-orange-100 to-orange-50 flex items-center justify-end gap-5 pr-2 ">
          {/* <p className=" bottom-2 right-2 text-[12px] text-black ml-4">
                  <b> Chat Count:</b> {systemMessageCount + 1}/{chatLimit}
                </p> */}
          <MdClose
            className="text-white w-4 h-4 cursor-pointer"
            onClick={() => setShowChat(false)}
          />
        </div>
        {/* Messages display area */}
        <div className="relative flex flex-col flex-grow overflow-y-auto py-2 px-3 h-[80%]">
          <div
            className={`mb-2 px-4 py-2 rounded max-w-[90%] ${"bg-green-200 self-start"}`}
          >
            <p className="text-sm  text-gray-600">{"Ask me anything..."}</p>
          </div>
          {/* <div> */}
          {messages?.map((message, index) => (
            <div
              key={index}
              className={`relative mb-2 px-4 py-2 rounded max-w-[80%] ${
                message?.role === "user"
                  ? "bg-blue-200 self-end"
                  : "bg-green-200 self-start"
              }`}
            >
              {message?.role === "user" ? (
                <p className="text-sm mr-6 text-gray-600">{message?.content}</p>
              ) : (
                <div
                  className={styles.markdown}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  <Markdown className={" text-gray-600 mr-6 p-2"}>
                    {message?.content}
                  </Markdown>
                </div>
              )}

              {/* <p className="absolute bottom-2 right-2 text-[8px] text-black ml-4">
                {count}/{chatLimit}
              </p> */}
            </div>
          ))}
          {/* <p className=" bottom-2 right-2 text-[10px] text-black ml-4">
                    {systemMessageCount + 1}/{chatLimit}
                  </p> */}
          {/* </div> */}

          {/* {message?.role === "system" && ( */}
          {/* <p className="absolute bottom-2 right-2 text-[10px] text-black ml-4">
                  {systemMessageCount + 1}/{chatLimit}
                </p> */}
          {/* )} */}
          {chatbotAnswerLoading && (
            <div
              className={`mb-2 px-4 py-2 rounded max-w-[80%] ${"bg-green-200 self-start"}`}
            >
              <p className="text-sm">{"Loading..."}</p>
            </div>
          )}
        </div>

        {/* Input area */}
        <form onSubmit={sendMessage}>
          <div className="flex border-t-2 border-gray-200 p-2 absolute bottom-0 w-full bg-white">
            <p className="absolute top-[-14px] right-3 text-[8px] text-black ml-4 bg-white">
              {count}/{chatLimit}
            </p>
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              className="flex-grow px-2 py-1 text-sm border rounded focus:outline-none focus:border-blue-300"
              placeholder={
                count >= chatLimit ? "Limit Reached..." : "Type your message..."
              }
              disabled={messages?.length < chatLimit ? false : true}
            />
            <button
              onClick={sendMessage}
              type={"submit"}
              className={`ml-2 px-4 py-2  text-white rounded focus:outline-none cursor-pointer  ${
                input
                  ? "bg-gradient-to-r from-orange-100 to-orange-50"
                  : "bg-slate-300"
              }`}
              disabled={!input}
            >
              <MdSend />
            </button>
          </div>
        </form>
      </div>
    </ResizableBox>
  );
};

export default Chatbot;
