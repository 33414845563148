import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import homeImageBackground from "../assets/images/homeBackground.png";
import { setImage } from "../redux/image";
import { clearInstructions } from "../redux/instructions";
import { setPrompt } from "../redux/recipes";
import { clearTitle, setDishNumber } from "../redux/title";
import GenerateDishes from "./open-ai/GenerateDishes";
import { playAudio } from "../utils/btnAudio";
// import parse from 're'

export default function DishesPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { prompt } = useSelector((state) => state.prompt);
  const { title } = useSelector((state) => state.title);
  const { dish1, dish2, dish3, response } = useSelector((state) => state.dish);
  const { ingredients } = useSelector((state) => state.ingredients);
  const {
    totalRecipeTokens,
    usedRecipeTokens,
    usedSearchTokens,
    totalSearchTokens,
  } = useSelector((state) => state.tokens);

  const [history, setHistory] = useState([]);
  const contentRef = useRef(null);
  const [displayedText, setDisplayedText] = useState("");

  console.log(dish1, dish2, dish3);
  const {
    Culture,
    FreeFlow,
    IncludedIngredients,
    ExcludedIngredients,
    Description,
    Occasion,
    Language,
  } = ingredients;

  const getPromptForDish = (dish1) => {
    "Give me a detailed recipe with cooking instructions for Dish {X}.";
    return `Give me a detailed recipe with cooking instructions for Dish ${dish1} that ${FreeFlow}  ${
      IncludedIngredients
        ? `and  includes the ingredients ${IncludedIngredients}`
        : ""
    },  ${
      ExcludedIngredients ? `but does not contain ${ExcludedIngredients}` : ""
    }.  ${Description ? `It should be ${Description}` : ""}  ${
      Occasion ? `and be suit no able for ${Occasion}` : ""
    }  ${Language ? `Please write the dish in ${Language}` : ""}`;
  };

  const findVariant = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to view these dishes again, and your search token will conclude",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes, I am sure!",
      denyButtonText: `No, Cancel it!`,
      // showCloseButton: true,
      dangerMode: true,
    }).then(async (result) => {
      if (result.isDenied) {
        return;
      } else {
        if (usedSearchTokens == -1 || usedSearchTokens >= totalSearchTokens) {
          Swal.fire({
            icon: "info",
            title: "You are out of Search Tokens",
            showConfirmButton: true,
            timer: 2000,
          });

          return;
        }

        setDisplayedText("");
        setHistory([
          ...history,
          {
            role: "user",
            content: prompt,
          },
          {
            role: "system",
            content: response,
          },
          {
            role: "user",
            content:
              "Find 3 varient dishes with the same ingredients as before.",
          },
        ]);
      }
    });
  };

  // handle back button
  const handleBack = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to view these dishes again, and your search token will conclude",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes, I am sure!",
      denyButtonText: `No, Cancel it!`,
      // showCloseButton: true,
      dangerMode: true,
    }).then(async (result) => {
      if (result.isDenied) {
        return;
      } else {
        navigate("/discover-dishes");
      }
    });
  };

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = contentRef.current.scrollHeight;
    }
  }, [displayedText]);

  const onFinalClick = (dishNumber) => {
    if (usedRecipeTokens == -1 || usedRecipeTokens >= totalRecipeTokens) {
      Swal.fire({
        icon: "info",
        title: "You don't have enough recipe tokens",
        showConfirmButton: true,
        timer: 2000,
      });
      return;
    }
    // Swal.fire({
    //   title: "Are you sure?",
    //   text: "By proceeding, you will lose a search token and this can not be un done",
    //   icon: "warning",
    //   showDenyButton: true,
    //   confirmButtonText: "Yes, Proceed!",
    //   denyButtonText: `No`,
    //   showCloseButton: true,
    //   dangerMode: true,
    // }).then(async (result) => {
    //   if (result.isConfirmed) {
    dispatch(setDishNumber(dishNumber));
    dispatch(setImage(""));
    dispatch(clearInstructions());
    dispatch(clearTitle());
    navigate(`/dishe-details`);
    //   }
    // });
  };

  return (
    <section className="relative min-h-screen ">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="pt-24 md:pt-28">
          <div className="absolute h-auto inset-0  pt-16 box-content -z-1">
            <img
              className="absolute inset-0 w-full h-full object-cover "
              src={homeImageBackground}
              width="1440"
              height="577"
              alt="About"
            />
            <div
              className="absolute inset-0 bg-gradient-to-t  from-gray-700 dark:from-gray-900"
              aria-hidden="true"
            ></div>
          </div>
          {/* h-[calc(100%+2rem)] */}
          <div className="max-w-4xl mx-auto md:h-[calc(100vh-200px)] h-[calc(100vh-240px)]">
            <div className="h-full">
              <article
                ref={contentRef}
                className="overflow-x-hidden bg-[#2a93dd40] backdrop-blur-m rounded-xl px-8 h-full backdrop-blur-md overflow-y-auto"
              >
                {/* Article content */}
                <div className="text-lg text-gray-400">
                  <div className="flex w-full justify-between">
                    <div onClick={handleBack} className="my-3 cursor-pointer">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-arrow-left"
                        width="40"
                        height="40"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#ff9300"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                        <line x1="5" y1="12" x2="11" y2="18" />
                        <line x1="5" y1="12" x2="11" y2="6" />
                      </svg>
                    </div>

                    {title && (
                      <div
                        onClick={() => {
                          navigate(-1);
                        }}
                        className="my-3 cursor-pointer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-arrow-right"
                          width="40"
                          height="40"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="#ff9300"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <line x1="19" y1="12" x2="5" y2="12" />
                          <line x1="13" y1="18" x2="19" y2="12" />
                          <line x1="13" y1="6" x2="19" y2="12" />
                        </svg>
                      </div>
                    )}
                  </div>
                  {/* <p className="mb-4 text-gray-200 text-xl md:text-2xl font-bold">
                    {prompt}
                  </p> */}
                  <div className="">
                    <GenerateDishes
                      prompt={`${prompt}`}
                      history={history ? history : null}
                      displayedText={displayedText}
                      setDisplayedText={setDisplayedText}
                    />
                  </div>
                </div>
              </article>

              <div className="fixed flex md:flex-row flex-col md:space-x-6 left-0 right-0 w-fit z-90 bottom-4 m-auto">
                <div className="flex justify-center items-center space-x-6 ">
                  <button
                    onClick={() => {
                      playAudio();
                      dispatch(setPrompt(getPromptForDish(dish1)));
                      onFinalClick(1);
                    }}
                    className="bg-gradient-to-r from-orange-100 to-orange-50  md:mx-0 font-medium  border border-transparent w-40 p-2.5 my-2 rounded text-white bg-teal-500 hover:bg-teal-400 transition duration-150 ease-in-out"
                  >
                    Dish 1
                  </button>
                  <button
                    onClick={() => {
                      playAudio();
                      dispatch(setPrompt(getPromptForDish(dish2)));
                      onFinalClick(2);
                    }}
                    className="
                    bg-gradient-to-r from-orange-100 to-orange-50  md:mx-0 
                    font-medium  flex items-center justify-center border border-transparent w-40 p-2.5
                    my-2 rounded text-white bg-teal-500 hover:bg-teal-400 transition duration-150 ease-in-out"
                  >
                    Dish 2
                  </button>
                </div>
                <div className="flex space-x-6  justify-center items-center ">
                  <button
                    onClick={() => {
                      playAudio();
                      dispatch(setPrompt(getPromptForDish(dish3)));
                      onFinalClick(3);
                    }}
                    className="
                  bg-gradient-to-r from-orange-100 to-orange-50  md:mx-0 
                    font-medium  flex items-center justify-center border border-transparent w-40 p-2.5
                     my-2 rounded text-white bg-teal-500 hover:bg-teal-400 transition duration-150 ease-in-out"
                  >
                    Dish 3
                  </button>
                  <button
                    onClick={() => {
                      playAudio();
                      findVariant();
                    }}
                    className="
                    bg-gradient-to-r from-orange-100 to-orange-50  md:mx-0 
                    font-medium  flex items-center justify-center border border-transparent w-40 p-2.5
                      my-2 rounded text-white bg-teal-500 hover:bg-teal-400 transition duration-150 ease-in-out"
                  >
                    3 New Dishes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
