import React, { useState } from "react";
import PencilIcon from "../assets/icons/pencilIcon";
import Swal from "sweetalert2";
import { doc, updateDoc, where } from "firebase/firestore";
import { firestoredDB } from "../config/firebase";
import RecipeNotes from "./alerts/RecipeNotes";
import { v4 as uuidv4 } from "uuid";
import { setRecipeCard } from "../redux/recipeCard";
import { useDispatch } from "react-redux";

const RecipeNotesComponent = ({ recipeCardData, dish_id }) => {
  const dispatch = useDispatch();
  const [recipeNotesModal, setRecipeNotesModal] = useState(false);
  const [recipeNotes, setRecipeNotes] = useState([]);
  const [notesText, setNotesText] = useState("");
  const [loading, setLoading] = useState(false);

  const updateRecipeNotes = async () => {
    setLoading(true);
    const updated = [{ text: notesText, id: recipeNotes[0]?.id }];

    try {
      const movieDoc = doc(firestoredDB, "CookBook", dish_id);
      await updateDoc(movieDoc, {
        notes: updated,
      });
      setRecipeNotesModal(false);
      setRecipeNotes(updated);
      dispatch(setRecipeCard({ ...recipeCardData, notes: updated }));
      Swal.fire({
        icon: "success",
        title: "Notes updated successfully",
        showConfirmButton: false,
        timer: 3000,
        confirmButtonColor: "#f0481a",
      });
      setLoading(false);
    } catch (error) {
      Swal.fire({
        icon: "info",
        title: error.message,
        showConfirmButton: false,
        timer: 2000,
      });
      setLoading(false);
    }
  };
  const onEditNotesClick = () => {
    if (recipeCardData?.notes?.length > 0) {
      setRecipeNotes(recipeCardData?.notes);
      setNotesText(recipeCardData?.notes[0]?.text);
    } else {
      const uid = uuidv4();
      setRecipeNotes([{ id: uid, text: "" }]);
      setNotesText("");
    }
    setRecipeNotesModal(true);
  };
  return (
    <>
      <div className="flex items-center gap-2">
        <p className="text-xl font-semibold text-white">Notes: </p>
        <div onClick={onEditNotesClick} className="cursor-pointer">
          <PencilIcon />
        </div>
      </div>

      <p>{recipeCardData?.notes?.[0]?.text ?? "N/A"}</p>

      <RecipeNotes
        isOpen={recipeNotesModal}
        close={() => setRecipeNotesModal(false)}
        recipeCardData={() => console.log("close")}
        closeModal={() => console.log("close")}
        recipeNotes={recipeNotes}
        setRecipeNotes={setRecipeNotes}
        onSubmit={updateRecipeNotes}
        value={notesText}
        setValue={setNotesText}
        loading={loading}
      />
    </>
  );
};

export default RecipeNotesComponent;
