import React, { useState } from "react";

import PrivacyPolicyModal from "./Modal/PrivacyPolicy";
import TermsOfServiceModal from "./Modal/TermsModal";
import { playAudio } from "../utils/btnAudio";

const Footer = () => {
  const [openPrivacyModal, setPrivacyModalOpen] = useState(false);
  const [openTermsModal, setTermsModalOpen] = useState(false);
  return (
    <footer
      style={{
        textAlign: "center",
        padding: "20px",
        marginTop: "20px",

        display: "flex",
        justifyContent: "space-between",
      }}
      className="bg-gray-800 text-white"
    >
      <p>&copy; {new Date().getFullYear()} Cook-E</p>

      <button
        to="#"
        onClick={() => {
          playAudio();
          setPrivacyModalOpen(true);
        }}
        className="underline text-orange-50 hover:text-gray-200 hover:no-underline transition duration-150 ease-in-out"
      >
        Privacy Policy
      </button>

      <button
        to="#"
        onClick={() => {
          playAudio();
          setTermsModalOpen(true);
        }}
        className="underline text-orange-50 hover:text-gray-200 hover:no-underline transition duration-150 ease-in-out"
      >
        Terms and Conditions
      </button>

      <PrivacyPolicyModal
        open={openPrivacyModal}
        onClose={setPrivacyModalOpen}
      ></PrivacyPolicyModal>

      {/* Terms of Service Modal */}
      <TermsOfServiceModal
        open={openTermsModal}
        onClose={setTermsModalOpen}
      ></TermsOfServiceModal>
    </footer>
  );
};

export default Footer;
