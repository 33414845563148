import { Configuration, OpenAIApi } from "openai";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { PropagateLoader } from "react-spinners";
import { setInstructions } from "../../redux/instructions";
import { setTitle } from "../../redux/title";

import { query } from "firebase/database";
import { collection, doc, getDocs, updateDoc, where } from "firebase/firestore";
import { firestoredDB } from "../../config/firebase";
import { incrementRecipeTokens } from "../../redux/tokens";
import { extractDishTitle } from "../../utils/extractTitle";
import { getRandomJoke } from "../../utils/jokes";
import { StreamingParagraph } from "../../utils/streaming_effect";
import { updateUserTokens } from "../../utils/updateTokens";
import TextToSpeech from "../TextToSpeech";
import Markdown from "react-markdown";
import styles from "./GenerateInstructions.module.css";

export default function GenerateInstructions({
  prompt,
  title,
  history,
  displayedText,
  setDisplayedText,
  oldInstructions,
  setOldInstructions,
}) {
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [joke, setJoke] = useState("");
  const [intervalId, setIntervalId] = useState(null);
  const [shouldJokeChange, setShouldJokeChange] = useState(false);
  const [showTextToSpeech, setShowTextToSpeech] = useState(false);

  const {
    user: { uid },
  } = useSelector((state) => state.auth.user);
  const { recipe1 } = useSelector((state) => state.recipe);
  const { instructions } = useSelector((state) => state.instructions);

  const topRef = useRef(null);
  // import.meta.env.VITE_Open_AI_Key
  const configuration = new Configuration({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  });

  const abc = useSelector((state) => state.tokens);

  const openai = new OpenAIApi(configuration);

  const dispatch = useDispatch();

  const generateRecipe = async () => {
    setLoading(true);
    setShowTextToSpeech(false);

    const response = await openai.createChatCompletion({
      model: "gpt-4o",
      temperature: 0.8,
      messages: [
        {
          role: "system",
          content: `Imagine you are the latest and greatest cooking assistant AI, named Cook-E AI. You are going to be given a culinary dish description that was just created by the user, and your task is to provide a complete, detailed recipe and cooking instructions with the requested information. You will provide them with a very detailed answer for their selected dish, based on the selected dish description. Make sure to include a complete and detailed set of instructions that anyone could follow, full ingredients list, the total prep time and cook time, along with calories per serving, and a basic estimated nutritional value section.
  
  Here is an example in markdown format:
  
  **Classic Chicken Pot Pie**
  
  Indulge in the comforting flavors of Classic Chicken Pot Pie, a beloved dish featuring tender chicken, mixed vegetables, and a creamy sauce, all enclosed in a flaky pie crust. This American classic is the perfect choice for a cozy and heartwarming birthday meal.
  
  **Difficulty Level:** Intermediate
  
  **Prep Time:** 30 minutes
  
  **Cook Time:** 45 minutes
  
  **Total Time:** 1 hour 15 minutes
  
  **Servings:** 6
  
  **Calories per Serving:** Approximately 400 calories
  
  **Ingredients**
  
  **For the Pie Filling:**
  - 1 pound boneless, skinless chicken breast, cut into bite-sized pieces
  - 2 tablespoons butter
  - 1/2 cup diced onion
  - 1/2 cup diced carrots
  - 1/2 cup frozen peas
  - 1/2 cup diced potatoes
  - 1/4 cup all-purpose flour
  - 2 cups chicken broth
  - 1/2 cup milk
  - 1/2 teaspoon dried thyme
  - Salt and pepper to taste
  
  **For the Pie Crust:**
  - 2 1/2 cups all-purpose flour
  - 1 cup unsalted butter, cold and cut into cubes
  - 1 teaspoon salt
  - 1/2 cup ice water
  
  don't create any new line here inside instruction heading..all the points should be in 1 line
  **Instructions:**
  
 
  1. **Preheat and Prepare:** Preheat your oven to 400°F (200°C). gather the vegetables. wash the vegetables. 
  
  2. **Cook the Chicken:** In a large skillet, melt the butter over medium heat. Add the diced chicken and cook until it is no longer pink. Remove the chicken from the skillet and set it aside.
  
  3. **Prepare the Vegetables:** In the same skillet, add the onions, carrots, peas, and potatoes. Cook for about 5 minutes until the vegetables begin to soften.
  
  4. **Create the Sauce:** Sprinkle the flour over the vegetables and stir to combine. Cook for another minute. Slowly pour in the chicken broth and milk, stirring constantly to avoid lumps. Add the dried thyme, salt, and pepper. Cook until the mixture thickens.
  
  5. **Combine Chicken and Vegetables:** Add the cooked chicken back to the skillet and mix everything together. Remove from heat.
  
  6. **Make the Pie Crust:** In a large bowl, combine the flour and salt. Cut in the cold butter using a pastry cutter or fork until the mixture resembles coarse crumbs. Gradually add ice water and mix until the dough comes together.
  
  7. **Assemble the Pie:** Divide the dough in half. Roll out one half to fit the bottom of a pie dish. Pour the chicken and vegetable mixture over the crust. Roll out the other half of the dough and place it over the filling. Trim and crimp the edges. Cut slits on the top crust to vent.
  
  8. **Bake:** Place the pie in the preheated oven and bake for 30-35 minutes, or until the crust is golden brown and the filling is bubbling.
  
  9. **Serve and Enjoy:** Allow the Chicken Pot Pie to cool slightly before serving. Cut into slices and enjoy the creamy, savory goodness of this classic dish.
  


  #### Nutritional Information:
  
  **Estimated Nutritional Value (per serving):**
  - **Calories:** Approximately 400
  - **Carbohydrates:** 35g
  - **Protein:** 18g
  - **Fat:** 21g
  - **Fiber:** 3g
  - **Vitamin A:** 70% of the Daily Value (DV)
  - **Vitamin C:** 15% DV
  - **Calcium:** 6% DV
  - **Iron:** 10% DV
  
  **Health Note:** This dish is rich in protein and provides essential nutrients like Vitamin A and Vitamin C from the vegetables.
  
  **Share Your Creation:**
  
  Share your delightful Chicken Pot Pie creation on social media using #cookeai and #ChickenPotPie. Show off your culinary skills!
          `,
        },
        {
          role: "user",
          content: `${prompt}`,
        },
        ...history,
      ],
    });

    dispatch(setTitle(response.data.choices[0].message.content.split("\n")[0]));
    setLoading(false);

    setShouldJokeChange(false);
    if (
      updateUserTokens(
        firestoredDB,
        collection,
        query,
        where,
        getDocs,
        updateDoc,
        doc,
        uid,
        "recipe"
      )
    ) {
      dispatch(incrementRecipeTokens());
    }

    const reply = extractDishTitle(response.data.choices[0].message.content);
    console.log(response.data.choices[0].message.content);

    dispatch(setInstructions(reply));
    StreamingParagraph(reply, 20, setDisplayedText);

    console.log(reply);

    setResult(reply);
    setTimeout(() => {
      setShowTextToSpeech(true);
    }, 20000);
    document.querySelectorAll("strong").forEach(function (h4) {
      console.log(h4, "o09009");
      if (h4.textContent.trim() === "Instructions") {
        h4.classList.add("instructions-header");
      }
    });
  };

  // --------------------------- JOKES SECTION -----------------------------

  const jokesRef = collection(firestoredDB, "Jokes");

  const getJoke = () => {
    const randomIndex = Math.floor(Math.random() * 3);

    switch (randomIndex) {
      case 0:
        getRandomJoke(jokesRef, getDocs, setJoke, "alpha");
        break;
      case 1:
        getRandomJoke(jokesRef, getDocs, setJoke, "beta");
        break;
      case 2:
        getRandomJoke(jokesRef, getDocs, setJoke, "gamma");
        break;
    }
  };

  // ------------------------------------------------------------------------

  useEffect(() => {
    if (title.length == 0) {
      setJoke("");
      getJoke();
      generateRecipe();
      setDisplayedText("");
    }
  }, [history]);

  useEffect(() => {
    if (instructions) {
      setDisplayedText(instructions);
      setShowTextToSpeech(true);
    }
  }, []);

  useEffect(() => {
    // Function to start the joke interval
    const startJokeInterval = () => {
      if (!intervalId) {
        const id = setInterval(() => {
          getJoke();
        }, 15000); // 15 seconds interval
        setIntervalId(id);
      }
    };

    // Function to clear the joke interval
    const clearJokeInterval = () => {
      if (intervalId) {
        clearInterval(intervalId);
        setIntervalId(null);
      }
    };

    if (loading) {
      startJokeInterval();
    } else {
      clearJokeInterval();
    }

    // Cleanup function to clear interval when component unmounts
    return () => {
      clearJokeInterval();
    };
  }, [loading]); // Dependency array includes 'loading' to re-run effect when it changes

  console.log(displayedText, "displayedText");
  return (
    <div className="flex w-full justify-center items-center flex-col mt-10">
      <div ref={topRef}></div>
      {/* <h2>Generate an Recipe using Open AI API</h2> */}

      {loading ? (
        <div className="flex flex-col md:w-[80%]">
          <div className="my-6 w-full mx-auto flex items-center justify-center">
            <PropagateLoader color="#ee6713" />
          </div>
          <h1 className="my-6 h-full text-gray-200 mx-auto text-center md:text-2xl text-md">
            {joke.trim()}
          </h1>
        </div>
      ) : (
        <>
          <div class="w-full">
            <div
              class="text-gray-200 text-lg"
              style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
              className={styles.markdown}
            >
              <Markdown>{displayedText}</Markdown>
            </div>
          </div>
          {showTextToSpeech && <TextToSpeech displayedText={displayedText} />}
        </>
      )}
    </div>
  );
}
