import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

const Container = tw.div`flex justify-center items-center w-full py-10`;
const VideoWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  aspect-ratio: 16/9;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  &:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  iframe {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: inherit;
  }
`;

const YoutubeVideo = ({ videoUrl }) => {
  return (
    <Container>
      <VideoWrapper>
        <iframe
          src={videoUrl}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </VideoWrapper>
    </Container>
  );
};

export default YoutubeVideo;
