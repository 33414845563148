import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { firestoredDB } from "../config/firebase";
const { nanoid } = require("nanoid");

// 34ffc74edebd7f462575792a1934f94d6fc781bb
// 9bfd4e3eb190d61255be50aae5e90f236da83389
export const generateShortLink = async (longUrl) => {
  const apiUrl = "https://api-ssl.bitly.com/v4/shorten";
  const headers = {
    Authorization: "Bearer 34ffc74edebd7f462575792a1934f94d6fc781bb",
    "Content-Type": "application/json",
  };
  const payload = {
    long_url: longUrl,
  };

  try {
    const response = await axios.post(apiUrl, payload, { headers });
    const shareText = response.data?.link;

    return shareText;
  } catch (error) {
    console.error("Error creating TinyURL:", error.message);
  }
};
export const generateShortLink_byTly = async (longUrl) => {
  const apiUrl = "https://api.t.ly/api/v1/link/shorten";
  const headers = {
    Authorization:
      "Bearer A2ze7sGUYs4aiT8SLm8FDDdUSxxq6DXG51yC8oUGDQ7V7ix8qv8vBKaBzcfe",
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  const payload = {
    long_url: longUrl,
  };

  try {
    const response = await axios.post(apiUrl, payload, { headers });
    console.log(response);
    const shareText = response.data?.link;

    return shareText;
  } catch (error) {
    console.error("Error creating TinyURL:", error.message);
  }
};
export const generateShortLink_BySlnk = async (longUrl) => {
  const apiUrl = "http://0.0.0.0:8000/v1/api/link/shorten";
  const headers = {
    Authorization:
      "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjozMTg2OTg0MzgxLCJpYXQiOjE3MTgxODQzODEsImp0aSI6ImE3M2Q1NjcwOGYxMDQ3NjA4YzdiZmFiYjU1YzhlOGExIiwidXNlcl9pZCI6MjB9.EaFkKd3aSqCZwWNfC1uKOCa0zYocbnG0ML2jqkOyKzY",
    "Content-Type": "application/json",
  };
  const payload = {
    link: longUrl,
  };

  try {
    const response = await axios.post(apiUrl, payload, { headers });

    const shareText = response.data?.link;

    return shareText;
  } catch (error) {
    console.error("Error creating TinyURL:", error.message);
  }
};

function generateShortId(length = 7) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export const createShortUrlByFirebase = async (longUrl) => {
  const url = new URL(longUrl);
  const recipeId = url.pathname.split("/").pop();
  const userId = url.searchParams.get("userid");

  // const shortIdFull = uuidv4();
  // const shortId = shortIdFull?.slice(0, 6);
  // const shortId = generateShortId();
  const shortId = nanoid(11);
  const shortUrl = `http://cookeai.com/share/${shortId}`;

  return {
    link: shortUrl,
    data: {
      shortUrl: shortUrl,
      recipe_id: recipeId,
      user_id: userId,
      longUrl: longUrl,
      shortId: shortId,
    },
  };
};

export const storeShortUrlData = async (data) => {
  const moviesCollectionRef = collection(firestoredDB, "shortLinks");
  const res = await addDoc(moviesCollectionRef, data);
};
