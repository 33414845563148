import React from "react";
import Header from "../components/Header";
import RecipeDetails from "../components/RecipeDetails";

export default function GetRecipe() {
  return (
    <div className="flex flex-col     overflow-hidden bg-hero-pattern">
      <Header />
      <RecipeDetails />
    </div>
  );
}
