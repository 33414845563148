import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import arrow from "../../assets/images/arrow_down.png";
import { playAudio } from "../../utils/btnAudio";
import { v4 as uuidv4 } from "uuid";

export default function RecipeNotes({
  isOpen,
  close,
  recipeNotes = [],
  setRecipeNotes,
  onSubmit,
  value,
  setValue,
  loading,
}) {
  // const [selectedNote, setSelectedNote] = useState("");
  const onAddNotesClick = () => {
    // const uid = uuidv4();
    // setRecipeNotes([...recipeNotes, { id: uid, text: " " }]);
    // setSelectedNote("");
  };
  const onSubmitNotes = () => {
    // const updated = [{ text: selectedNote, id: recipeNotes[0]?.id }];
    // setRecipeNotes(updated);
    onSubmit();
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50 border" onClose={close}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto h-full">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className="w-full h-full mt-20 max-w-2xl xl:max-w-5xl transform overflow-hidden rounded-2xl
                                 bg-white p-6 text-left align-middle shadow-xl transition-all"
                >
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Notes
                  </Dialog.Title>
                  <div className="mt-2 flex flex-col">
                    {/* recipeNotes?.length == 0 */}
                    <div>
                      {false ? (
                        <div className="flex items-center justify-center h-[300px]">
                          <button
                            type="button"
                            className="inline-flex text-white justify-center rounded-md border border-transparent !bg-orange-50 px-4 py-2 text-sm font-medium  hover:bg-orange-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                            onClick={onAddNotesClick}
                            //   disabled={loading}
                          >
                            Add Notes
                          </button>
                        </div>
                      ) : (
                        <div className="w-full flex flex-col">
                          <textarea
                            rows={8}
                            className="resize-handle px-2 py-2 mt-2"
                            placeholder="Notes Go Here...."
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                            name={"Name"}
                          />
                          <p className="w-full text-right lg:flex justify-end pr-2 m-0 hidden">
                            <p>stretch the text area here</p>
                            <img
                              src={arrow}
                              className="w-4 h-4 rotate-[-120deg] ml-1"
                              alt=""
                            />
                          </p>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex text-white justify-center rounded-md border border-transparent !bg-orange-50 px-4 py-2 text-sm font-medium  hover:bg-orange-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:!bg-gray-300"
                      onClick={onSubmitNotes}
                      disabled={loading || value?.length == 0}
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      className="inline-flex text-orange-700 justify-center rounded-md border border-transparent  px-4 py-2 text-sm font-medium  focus-visible:ring-2 "
                      onClick={() => {
                        playAudio();
                        close();
                      }}
                    >
                      Cancel
                    </button>
                    {/* <button
                                            type="button"
                                            className="inline-flex text-white justify-center rounded-md border border-transparent bg-red-400 px-4 py-2 text-sm font-medium  hover:bg-orange-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={() => {
                                                deleteRecipe()
                                                close()
                                            }}
                                        >
                                            Delete
                                        </button> */}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
