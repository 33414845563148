import { playAudio } from "../../utils/btnAudio";
import { RecipeImageContainer, RecipeItemContainer } from "./styles";

import { useNavigate } from "react-router-dom";

function RecipeItem({ article }) {
  const navigate = useNavigate();
  let myRegex = /<img[^>]+src="([^">]+)"/g;

  let match = myRegex.exec(article.content);

  let thumbnail =
    match && match[1].includes("cdn")
      ? match[1]
      : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVXk7-kB2fclCNbOr_bYVEnwFHBnYVCPV1L_8QghQ6XQ&s";
  function removeImageTags(htmlContent) {
    // This regular expression matches all <img> tags
    const imgTagRegex = /<img[^>]*>/g;
    return htmlContent.replace(imgTagRegex, "");
  }

  return (
    <RecipeItemContainer target="_blank">
      <RecipeImageContainer>
        <img src={thumbnail} alt={article.title} />
      </RecipeImageContainer>

      <p className="title">{article.title}</p>

      <div
        className="description"
        dangerouslySetInnerHTML={{
          __html: `${removeImageTags(article.description).slice(0, 150)}....`,
        }}
      ></div>

      <a
        className="rounded-full lg:w-52 w-full
bg-gradient-to-r from-orange-100 to-orange-50 hover:from-orange-50 hover:to-orange-100  md:mx-0 
font-medium  flex items-center justify-center border border-transparent lg:px-14 py-2.5
 my-2  text-white transition duration-150 ease-in-out backdrop-blur-lg "
        onClick={() => {
          playAudio();
          localStorage.setItem("article", JSON.stringify(article));
          navigate("/blog/" + article.title.split(" ").join("-"));
        }}
      >
        View More
      </a>
    </RecipeItemContainer>
  );
}

export default RecipeItem;
