import React from "react";
import "./index.css";

const Loader = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <svg width="200" height="200" className="loading-svg">
        <circle id="dot1" class="loading-shape" />
        <circle id="dot2" class="loading-shape" />
        <circle id="dot3" class="loading-shape" />
        <circle id="dot4" class="loading-shape" />
      </svg>
    </div>
  );
};

export default Loader;
