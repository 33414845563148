import React from "react";

const Statistics = ({ profile }) => {
  return (
    <div className="userInfo">
      <div className="bg-white shadow-2xl rounded-lg p-10 mt-20">
        <h2 className="text-xl font-semibold text-gray-800 mb-4">
          Token Statistics
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 text-center">
          {/* Saved Recipes card */}
          <div className="flex flex-col items-center mb-4 rounded-lg p-8 bg-gray-200 border border-teal-600">
            <span className="text-2xl font-bold text-teal-600">
              {profile?.usedDownloadTokens || 0}/
              {profile?.totalDownloadTokens || 0}
            </span>
            <span className="text-gray-600">Download Tokens</span>
          </div>

          {/* Shared Recipes */}
          <div className="flex flex-col items-center mb-4 rounded-lg p-8 bg-gray-200 border border-orange-600">
            <span className="text-2xl font-bold text-orange-600">
              {profile?.usedSearchTokens || 0}/{profile?.totalSearchTokens || 0}
            </span>
            <span className="text-gray-600">Search Tokens</span>
          </div>

          {/* Created Recipes */}
          <div className="flex flex-col items-center mb-4 rounded-lg p-8 bg-gray-200 border border-pink-600">
            <span className="text-2xl font-bold text-pink-600">
              {profile?.usedRecipeTokens || 0}/{profile?.totalRecipeTokens || 0}
            </span>
            <span className="text-gray-600">Recipes Token</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
