import { motion } from "framer-motion";
import React from "react";
import homeImageBackground from "../../assets/images/homeBackground.png";
const HelpPageHeader = () => {
  return (
    <div
      className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32"
      style={{
        backgroundImage: `url(${homeImageBackground})`,
      }}
    >
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: -50 },
          visible: { opacity: 1, x: 0 },
        }}
        className="mt-10"
      >
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2
              className="text-4xl font-bold tracking-tight text-white sm:text-6xl
              
            "
            >
              Help
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              Get all of your questions answered in our forums or contact
              support.
            </p>
          </div>
          <div className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-white sm:grid-cols-2 md:flex lg:gap-x-10">
              {/* Links */}
              <a href="#">
                Getting started <span aria-hidden="true">&rarr;</span>
              </a>
              <a href="#faqs">
                FAQs <span aria-hidden="true">&rarr;</span>
              </a>

              <a href="feedback">
                Feedback <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default HelpPageHeader;
