import React from "react";

const IconWrapper = ({ children, classname = "" }) => {
  return (
    <div
      className={`rounded-full bg-slate-300 p-2 flex items-center cursor-pointer hover:bg-slate-200 ${classname}`}
      style={{ maxWidth: "max-content" }}
    >
      {children}
    </div>
  );
};

export default IconWrapper;
