import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { firestoredDB } from "../config/firebase";
import { removeEmptyTokensOnDate } from "./emptyAllTokens";
export const checkExpiration = (timestamp) => {
  if (!timestamp) return false;
  const userId = localStorage.getItem("uid");
  // Get the current date (month, day, and year)
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1; // Months are zero-indexed
  const currentDay = currentDate.getDate();
  const currentYear = currentDate.getFullYear();

  // Extract month, day, and year from your specific timestamp
  const specificTimestamp = timestamp; // Replace with your actual timestamp
  const specificDate = new Date(specificTimestamp);
  const specificMonth = specificDate.getMonth() + 1; // Months are zero-indexed
  const specificDay = specificDate.getDate();
  const specificYear = specificDate.getFullYear();
  // Check if the current date matches the specific timestamp (month, day, and year)
  const isDateMatch =
    currentMonth === specificMonth &&
    currentDay === specificDay &&
    currentYear === specificYear;

  // Execute the removeEmptyTokensOnDate function if the date matches

  if (isDateMatch) {
    removeEmptyTokensOnDate(
      firestoredDB,
      collection,
      query,
      where,
      getDocs,
      updateDoc,
      doc,
      userId
    );
    return true;
  }
  return false;
};
