import React, { useEffect, useState } from "react";

import { Col, Form, Input, Modal, Typography } from "antd";

function EditProfile({ open, handleClose, formData, onSubmit }) {
  const [form] = Form.useForm();

  const [error, setError] = useState("");

  useEffect(() => {
    // Set form default values
    form.setFieldsValue({
      username: formData?.username || "",
      personalName: formData?.personalName || "",
    });
  }, [formData, form]);

  const closeModal = () => {
    // rest form fields
    form.resetFields();
    handleClose();
  };

  const handleSubmit = () => {
    setError("");

    // Submit logic here
    if (
      !form.getFieldValue("username") ||
      !form.getFieldValue("personalName")
    ) {
      setError("Please fill all fields");
      return;
    }

    if (
      form.getFieldValue("username").length < 3 ||
      form.getFieldValue("username").length > 20
    ) {
      setError("username must be between 3 and 20 characters");
      return;
    }

    if (
      form.getFieldValue("username").toLowerCase() !==
      form.getFieldValue("username")
    ) {
      setError("username must be in lowercase");
      return;
    }

    onSubmit(form.getFieldsValue());
    closeModal();
  };

  return (
    <Modal
      open={open}
      onCancel={closeModal}
      onOk={handleSubmit}
      className="userInfo__modal"
      aria-labelledby="edit-name-modal-title"
      aria-describedby="edit-name-modal-description"
    >
      <Col>
        <Typography
          variant="h6"
          id="edit-name-modal-title"
          style={{ fontWeight: "bold", marginBottom: "1rem" }}
        >
          Edit Profile
        </Typography>
        <Form
          form={form}
          layout="vertical"
          name="userEditForm"
          onFinish={handleSubmit}
        >
          <Form.Item
            name="username"
            label="User Name"
            rules={[{ message: "Please input your user name!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="personalName"
            label="Personal Name"
            rules={[{ message: "Please input your personal name!" }]}
          >
            <Input />
          </Form.Item>

          {error && (
            <Typography
              color="error"
              style={{ marginBottom: "1rem", color: "red" }}
            >
              {error}
            </Typography>
          )}
        </Form>
      </Col>
    </Modal>
  );
}

export default EditProfile;
