const StreamingParagraph = (paragraph, wordDelay, setDisplayedText) => {
  let words = paragraph.split(" ");
  let currentIndex = 0;

  const interval = setInterval(() => {
    if (currentIndex >= words.length) {
      clearInterval(interval);
      return;
    }

    setDisplayedText((prevText) => {
      const newText = prevText.trim() + " " + words[currentIndex];
      currentIndex++;
      return newText;
    })
  }, wordDelay);
};

export { StreamingParagraph }