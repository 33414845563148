import { motion } from "framer-motion";
import React, { useState } from "react";
import { ColorRing } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import homeImageBackground from "../../assets/images/homeBackground.png";
import Header from "../../components/Header";

import { createUserWithEmailAndPassword } from "firebase/auth";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import Swal from "sweetalert";
import PrivacyPolicyModal from "../../components/Modal/PrivacyPolicy";
import TermsOfServiceModal from "../../components/Modal/TermsModal";
import { auth, firestoredDB } from "../../config/firebase";
import { clearMessage, setMessage } from "../../redux/message";
import { playAudio } from "../../utils/btnAudio";

const initialState = {
  fullName: "",
  email: "",
  password: "",
  confirmPassword: "",
};

function SignUp() {
  const [values, setValues] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [visible, setIsVisible] = useState(false);
  const { message } = useSelector((state) => state.message);
  const [openPrivacyModal, setPrivacyModalOpen] = useState(false);
  const [openTermsModal, setTermsModalOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleRegister = async () => {
    setIsVisible(true);

    try {
      if (values.password !== values.confirmPassword) {
        Swal({
          icon: "error",
          title: "Passwords do not match",
          showConfirmButton: false,
          timer: 2000,
          confirmButtonColor: "#f0481a",
        });
        setIsVisible(false);
        return;
      }

      const user = await createUserWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );
      const uid = user.user.uid;
      const userRef = collection(firestoredDB, "Users");
      let userName = "cookEUser" + Math.floor(Math.random() * 1000000);
      await addDoc(userRef, {
        userId: uid,
        email: values.email,
        username: userName,
        personalName: values.fullName,
        totalDownloadTokens: 0,
        usedDownloadTokens: 0,
        totalSearchTokens: 10,
        usedSearchTokens: 0,
        totalRecipeTokens: 3,
        usedRecipeTokens: 0,
        createdAt: serverTimestamp(), // Add the server timestamp
      });

      Swal({
        icon: "success",
        title: "Account created successfully with Free Trial",
        showConfirmButton: false,
        timer: 2000,
        confirmButtonColor: "#f0481a",
      });
      setTimeout(() => {
        navigate("/signin");
      }, 2000);
    } catch (error) {
      dispatch(setMessage(error.message.substring(9)));
    }
    setTimeout(() => {
      dispatch(clearMessage());
    }, 5000);
    setIsVisible(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    playAudio();

    handleRegister();
  };

  return (
    <div className="flex flex-col min-h-screen relative overflow-hidden">
      {/*  Site header */}
      <Header />
      {/*  Page content */}
      <main className="grow ">
        {/*  Page illustration */}
        <div className="absolute h-auto inset-0 box-content -z-1">
          <img
            className="absolute inset-0 w-full h-[100%] object-cover "
            src={homeImageBackground}
            alt="About"
          />
          {/* <div className="absolute inset-0 bg-gradient-to-t  from-gray-500 dark:from-gray-900" aria-hidden="true"></div> */}
        </div>

        <section className="relative ">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.5 }}
            variants={{
              hidden: { opacity: 0, x: -50 },
              visible: { opacity: 1, x: 0 },
            }}
            className="max-w-7xl mx-auto px-4  md:px-6"
          >
            <div className="pt-32 pb-10 flex justify-center items-center">
              <div className="bg-white bg-opacity-10 px-2 shadow-xl py-5 opacity-90 md:w-[70%] lg:w-[45%] w-full rounded-xl">
                {/* Page header */}
                <div className="max-w-3xl mx-auto text-center pb-4 sm:pb-12 md:pb-10">
                  <h1 className="text-3xl font-bold text-gray-200">
                    Welcome to <span className="text-orange-50">COOK-E</span>
                  </h1>
                </div>

                {/* Form */}
                <div className="max-w-sm mx-auto">
                  <form onSubmit={onSubmit}>
                    <div className="flex flex-wrap -mx-3 mb-4">
                      <div className="w-full px-3">
                        <label
                          className="block text-gray-300 text-sm font-medium mb-1"
                          htmlFor="full-name"
                        >
                          Name <span className="text-red-600">*</span>
                        </label>
                        <input
                          name="fullName"
                          value={values.fullName}
                          onChange={handleChange}
                          type="text"
                          className="form-input w-full text-gray-700"
                          placeholder="Your name"
                          required
                        />
                      </div>
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-4">
                      <div className="w-full px-3">
                        <label
                          className="block text-gray-300 text-sm font-medium mb-1"
                          htmlFor="email"
                        >
                          Email <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          className="form-input w-full text-gray-700"
                          placeholder="your email"
                          required
                        />
                      </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-4">
                      <div className="w-full px-3">
                        <label
                          className="block text-gray-300 text-sm font-medium mb-1"
                          htmlFor="password"
                        >
                          Password <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="password"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          className="form-input w-full text-gray-700"
                          placeholder="Password (at least 8 characters)"
                          required
                        />
                      </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-4">
                      <div className="w-full px-3">
                        <label
                          className="block text-gray-300 text-sm font-medium mb-1"
                          htmlFor="password"
                        >
                          Confirm Password{" "}
                          <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="password"
                          name="confirmPassword"
                          value={values.confirmPassword}
                          onChange={handleChange}
                          className="form-input w-full text-gray-700"
                          placeholder="Confirm Password"
                          required
                        />
                      </div>
                    </div>
                    <p className="my-2 mx-3   text-md font-bold text-center text-gray-700">
                      {message && (
                        <div className="text-white h-full " role="alert">
                          {message.includes("(auth/email-already-in-use).") ? (
                            <span>
                              Email already in use{" "}
                              <button
                                onClick={() => navigate("/signin")}
                                className="underline text-orange-50 hover:text-gray-200 hover:no-underline transition duration-150 ease-in-out"
                              >
                                Sign in
                              </button>
                            </span>
                          ) : (
                            message
                          )}
                        </div>
                      )}
                    </p>
                    <div className="text-[14px] text-gray-200 space-x-2 ">
                      <input
                        type="checkbox"
                        className="form-checkbox"
                        required
                      />
                      <span>
                        I agree to be Cook-E per the Cook-E{" "}
                        <button
                          to="#"
                          onClick={() => {
                            setPrivacyModalOpen(true);
                          }}
                          className="underline text-orange-50 hover:text-gray-200 hover:no-underline transition duration-150 ease-in-out"
                        >
                          Privacy Policy
                        </button>
                        .
                      </span>
                    </div>
                    {/* terms and services */}
                    <div className="flex items-center space-x-2 mt-2">
                      <input
                        type="checkbox"
                        className="form-checkbox"
                        required
                      />
                      <span className="text-gray-200 text-[14px]">
                        I agree to the Cook-E{" "}
                        <button
                          to="#"
                          onClick={() => setTermsModalOpen(true)}
                          className="underline text-orange-50 hover:text-gray-200 hover:no-underline transition duration-150 ease-in-out"
                        >
                          Terms of Service
                        </button>
                      </span>
                    </div>
                    <div className="flex flex-wrap -mx-3 mt-6">
                      <div className="w-full px-3">
                        <button
                          type="submit"
                          className="btn text-white bg-gradient-to-r from-orange-100 to-orange-50 w-full"
                        >
                          Sign Up
                        </button>
                        {/* </Link> */}
                      </div>
                    </div>
                    {/* <p className="mt-8 mx-3 text-xs font-bold text-center text-gray-700">
                    {message && (
                      <div
                        className={successful ? "text-green-600" : "text-red-500"}
                        role="alert"
                      >
                        {message}
                      </div>
                    )}
                  </p> */}
                  </form>
                  <div className="text-gray-400 flex space-x-2 justify-center text-center mt-6">
                    Already using Cook-E?{" "}
                    <div
                      to="signin"
                      onClick={() => navigate("/signin")}
                      className="text-orange-400 ml-2 hover:text-gray-200 transition duration-150 ease-in-out"
                    >
                      Sign in
                    </div>
                  </div>
                </div>
              </div>
              {visible && (
                <div className="z-50 absolute top-[50%] left-[50%] -translate-x-[50%]">
                  <ColorRing
                    visible={true}
                    height="100"
                    width="100"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={[
                      "#e15b64",
                      "#f47e60",
                      "#f8b26a",
                      "#abbd81",
                      "#849b87",
                    ]}
                  />
                </div>
              )}
              {/* {notification && (
                <Notification
                  status={notification.status}
                  title={notification.title}
                  message={notification.message}
                />
              )} */}
            </div>
          </motion.div>
        </section>

        {/* Privacy Policy Modal */}
        <PrivacyPolicyModal
          open={openPrivacyModal}
          onClose={setPrivacyModalOpen}
        ></PrivacyPolicyModal>

        {/* Terms of Service Modal */}
        <TermsOfServiceModal
          open={openTermsModal}
          onClose={setTermsModalOpen}
        ></TermsOfServiceModal>
      </main>
    </div>
  );
}

export default SignUp;
