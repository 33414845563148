import React, { useState } from "react";
import CancelSubscriptionModal from "../../components/alerts/CancelSubscriptionModal";
import { useSelector } from "react-redux";

import { cancelSubscriptionRenewal } from "../../stripe/cancel_renewal";
import { updateUserTokensForTimestamp } from "../../utils/emptyAllTokens";
import {
  updateCurrentPeriodEndTimestamp,
  updateSubscriptionStatus,
} from "../../redux/tokens";
import Swal from "sweetalert2";
import { collection, doc, getDocs, updateDoc, where } from "firebase/firestore";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { query } from "firebase/database";
import { firestoredDB } from "../../config/firebase";
import { playAudio } from "../../utils/btnAudio";

const ProfileActions = ({ deleteProfile }) => {
  const [openSubRemoveModal, setOpenSubsRemoveModal] = useState(false);
  const uid = localStorage.getItem("uid");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { tokens } = useSelector((state) => state);

  const cancelSubscription = async () => {
    try {
      const res = await cancelSubscriptionRenewal(tokens?.subscriptionId);

      if (res) {
        const payload = {
          ...tokens,
          currentPeriodEnd: res,
          subscriptionStatus: false,
          subScriptionPlan: null,
          subscriptionId: null,
        };

        const firebaseRes = updateUserTokensForTimestamp(
          firestoredDB,
          collection,
          query,
          where,
          getDocs,
          updateDoc,
          doc,
          uid,
          payload
        );
        if (firebaseRes) {
          // Format the date string

          const date = new Date(res * 1000);
          const options = { day: "numeric", month: "short", year: "numeric" };
          const formattedDate = date.toLocaleDateString(undefined, options);

          dispatch(updateCurrentPeriodEndTimestamp(res));
          dispatch(updateSubscriptionStatus(false));
          Swal.fire({
            icon: "success",
            title: `Renewal Cancelled, you can use your remaining tokens upto\n ${formattedDate}`,
            showConfirmButton: false,
            timer: 5000,
            confirmButtonColor: "#f0481a",
          });
          setTimeout(() => {
            navigate("/discover-dishes");
          }, 4000);
        }
      } else {
        Swal.fire({
          icon: "success",
          title: `Error, Try again`,
          showConfirmButton: false,
          confirmButtonColor: "#f0481a",
        });
      }
    } catch (e) {
      Swal.fire({
        icon: "info",
        title: "Error Connecting with Stripe, Try again!",
        showConfirmButton: false,
        confirmButtonColor: "#f0481a",
      });
    }
  };

  return (
    <div className="userInfo">
      <div className="userInfo__buttons  mb-4">
        {/* cancel renewal Button */}

        <button
          className="text-white  bg-orange-50"
          onClick={() => {
            playAudio();

            setOpenSubsRemoveModal(true);
          }}
          disabled={!tokens?.subscriptionStatus}
        >
          Cancel Renewal
        </button>

        <button className="userInfo__button deactivate">Deactivate</button>
        <button
          className="userInfo__button delete"
          onClick={() => {
            playAudio();
            deleteProfile();
          }}
        >
          Delete
        </button>
      </div>

      <CancelSubscriptionModal
        isOpen={openSubRemoveModal}
        close={cancelSubscription}
        closeModal={() => setOpenSubsRemoveModal(false)}
      />
    </div>
  );
};

export default ProfileActions;
