const stripe = require("stripe")(process.env.REACT_APP_STRIPE_TEST_KEY);

const stripeCheckoutSession = async (
  price,
  title,
  uid,
  total_search_tokens,
  total_download_tokens,
  interval = "month"
) => {
  const line_items = [
    {
      price_data: {
        currency: "usd",
        unit_amount: price * 100,
        recurring: {
          interval: interval,
        },
        product_data: {
          name: title,
        },
      },
      quantity: 1,
    },
  ];

  // Determine the base URL based on the environment
  const baseUrl =
    window.location.hostname === "localhost"
      ? "http://localhost:3000" // Replace with your local port if different
      : `${window.location.protocol}//${window.location.hostname}`;

  const session = await stripe.checkout.sessions.create({
    line_items,
    allow_promotion_codes: true,
    mode: "subscription",
    client_reference_id: uid,
    metadata: {
      userId: uid,
      used_search_tokens: 0,
      used_download_tokens: 0,
      total_search_tokens: total_search_tokens,
      total_download_tokens: total_download_tokens,
      total_recipe_tokens: total_download_tokens,
      subscriptionStatus: true,
      subScriptionPlan: title,
    },

    success_url: `${baseUrl}/success`,
    cancel_url: `${baseUrl}/shop`,
  });

  return session;
};

export { stripeCheckoutSession };
