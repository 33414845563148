import React, { useEffect } from "react";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { firestoredDB } from "../config/firebase";

const RecipeShortUrl = () => {
  useEffect(() => {
    const redirectToLongUrl = async () => {
      const shortId = window.location.pathname.split("/").pop();
      const q = query(
        collection(firestoredDB, "shortLinks"),
        where("shortId", "==", shortId)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0];
        const longUrl = doc.data().longUrl;
        window.location.href = longUrl;
      } else {
        console.log("No such document!");
      }
    };

    redirectToLongUrl();
  }, []);

  return <div>Redirecting...</div>;
};

export default RecipeShortUrl;
