import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  title: "",
  dishNumber: -1,
  images: {
    1: "",
    2: "",
    3: "",
  },
  imageGenerationCount: 0,
  recipeAllImage: {
    1: [],
    2: [],
    3: [],
  },
};

const titleSlice = createSlice({
  name: "title",
  initialState,
  reducers: {
    setTitle: (state, action) => {
      return { ...state, title: action.payload };
    },
    clearTitle: (state) => {
      return { ...state, title: "" };
    },
    setDishNumber: (state, action) => {
      return { ...state, dishNumber: action.payload };
    },
    increaseImageGenerationCount: (state, action) => {
      return {
        ...state,
        imageGenerationCount: state?.imageGenerationCount + 1,
      };
    },
    resetImageGenerationCount: (state, action) => {
      return { ...state, imageGenerationCount: 0 };
    },
    clearDishNumber: (state) => {
      return { ...state, dishNumber: -1 };
    },
    setDishImage: (state, action) => {
      return {
        ...state,
        images: {
          ...state.images,
          [action.payload.dishNumber]: action.payload.url,
        },
      };
    },
    setRecipeAllImage: (state, action) => {
      if (!state.recipeAllImage[action.payload.dishNumber]) {
        state.recipeAllImage[action.payload.dishNumber] = [];
      }
      state.recipeAllImage[action.payload.dishNumber].push(action.payload.url);
    },
    emptyRecipeAllImageByDishNumber: (state, action) => {
      console.log("=======>", action);
      if (!state.recipeAllImage[action.payload]) {
        state.recipeAllImage[action.payload] = [];
      }
      state.recipeAllImage[action.payload] = [];
    },
    // setRecipeAllImage: (state, action) => {
    //   let oldDataArray = state.recipeAllImage[action.payload.dishNumber];
    //   oldDataArray.push(action.payload.url);
    //   console.log(action, oldDataArray,"here-------------payload");
    //   return {
    //     ...state,
    //     recipeAllImage: {
    //       ...state.recipeAllImage,
    //       [action.payload.dishNumber]: oldDataArray,
    //     },
    //   };
    //   // return {
    //   //   ...state,
    //   //   oldImages: {
    //   //     ...state.oldImages,
    //   //     [action.payload.dishNumber]: action.payload.url,
    //   //   },
    //   // };
    // },
    clearAllDishImages: (state) => {
      return {
        ...state,
        images: {
          1: "",
          2: "",
          3: "",
        },
        recipeAllImage: {
          1: [],
          2: [],
          3: [],
        },
      };
    },
  },
});

export const titleReducer = titleSlice.reducer;
export const {
  setTitle,
  clearTitle,
  setDishNumber,
  clearDishNumber,
  setDishImage,
  clearAllDishImages,
  increaseImageGenerationCount,
  resetImageGenerationCount,
  setRecipeAllImage,
  emptyRecipeAllImageByDishNumber,
} = titleSlice.actions;
// export default reducer;
