import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import homeImageBackground from "../../assets/images/homeBackground.png";
import Header from "../../components/Header";
import { switchLoginStatus } from "../../redux/auth";
import { clearTokens } from "../../redux/tokens";

function VerifyEmail() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    user: { emailVerified },
  } = useSelector((state) => state.auth.user);

  const logout = () => {
    dispatch(clearTokens());
    dispatch(switchLoginStatus(false));
  };

  useEffect(() => {
    if (emailVerified) {
      navigate("/discover-dishes");
    }
  }, []);

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page illustration */}
      <div className="absolute h-screen inset-0  pt-16 box-content -z-1">
        <img
          className="absolute inset-0 w-full h-full object-cover "
          src={homeImageBackground}
          width="1440"
          height="577"
          alt="About"
        />
        <div
          className="absolute inset-0 bg-gradient-to-t  from-gray-500 dark:from-gray-900"
          aria-hidden="true"
        ></div>
      </div>

      <div className="mt-20 flex justify-center items-center">
        <div className="flex flex-col sm:w-[50%] w-[90%] justify-between my-10 rounded-lg bg-yellow-100 py-2 px-3 ">
          <div className="flex justify-center items-center flex-co">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-alert-triangle"
                width="36"
                height="36"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#ff4500"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 9v2m0 4v.01" />
                <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75" />
              </svg>
            </span>{" "}
            <p className="pl-3 pb-1">
              {" "}
              Your email has not been verified yet! We have sent you an email
              with the verification Link, Please verify it and login Again.{" "}
              <br />{" "}
              <strong>
                <Link
                  to="/signin"
                  className=" underline hover:opacity-60"
                  onClick={() => logout()}
                >
                  Logout Here
                </Link>
              </strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerifyEmail;
