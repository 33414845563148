import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

const initialState = {
  clickedListId: null,
};
const cookbookSlice = createSlice({
  name: "cookbook",
  initialState,

  reducers: {
    setCLickedListId: (state, action) => {
      console.log(action, "Action");
      state.clickedListId = action.payload;
    },
  },
});

export const { setCLickedListId } = cookbookSlice.actions;
export const cookbookReducer = cookbookSlice.reducer;
