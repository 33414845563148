import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "../misc/Headings.js";
import { SectionDescription } from "../misc/Typography.js";

import defaultCardImage from "../images/shield-icon.svg";
import shareRecipe from "../../../assets/landing/share.jpeg";
import blog from "../../../assets/landing/blog.jpeg";
import book from "../../../assets/landing/book.jpeg";
import community from "../../../assets/landing/community.jpeg";
import dish from "../../../assets/landing/dish.jpeg";
import edit from "../../../assets/landing/edit.jpeg";
import personal from "../../../assets/landing/personal.jpeg";
import variants from "../../../assets/landing/variants.jpeg";

import { ReactComponent as SvgDecoratorBlob3 } from "../images/svg-decorator-blob-3.svg";

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap md:justify-center max-w-screen-xl mt-20 flex-wrap mx-auto`}
`;
const Subheading = tw(SubheadingBase)`mb-4 text-center`;
const Heading = styled(SectionHeading)`
  ${tw`w-full text-center text-4xl font-bold text-white`}
  margin-top: 2rem;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
`;

const Description = styled(SectionDescription)`
  ${tw`w-full text-center text-gray-600 text-2xl`}
  margin-bottom: 2rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.6;
`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/3 w-full max-w-lg mb-8`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-col items-center sm:items-start text-center sm:text-left h-full mx-4 px-6 py-10 rounded-lg shadow-lg transition-transform transform hover:scale-105 p-4 sm:p-8 bg-gradient-to-br from-teal-50 to-primary-600 text-gray-800`}
  .imageContainer {
    ${tw`border-2 border-secondary-100 text-center rounded-full p-2 flex-shrink-0 bg-gradient-to-br from-orange-50 to-secondary-100`}
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  }

  .card {
    ${tw`mt-4 flex flex-row items-center`}
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none text-gray-500`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-gray-700 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({
  cards = null,
  heading = "Amazing Features",
  subheading = "Features",
  description = "Explore the exceptional features of our application designed to enhance your culinary journey.",
}) => {
  const defaultCards = [
    {
      imageSrc: personal,
      title: "User-Friendly Interface",
      description:
        "An intuitive and visually appealing interface that makes navigation and usage easy and enjoyable.",
    },
    {
      imageSrc: dish,
      title: "Personalized Dish Recommendations",
      description:
        "Direct User Input: Start by specifying your ingredients, dietary preferences, and cuisine types. Receive three rich, detailed dish recommendations. Find Alternatives if none of the recommendations fit your mood.",
    },
    {
      imageSrc: blog,
      title: "Explore Detailed Recipe Pages",
      description:
        "Every recipe comes with detailed instructions, ingredients, and cooking techniques. Learn about the cultural background and historical significance of each dish. Each recipe page features a high-quality image to guide and inspire your cooking.",
    },
    {
      imageSrc: book,
      title: "Save to Personal Cookbook",
      description:
        "Access all your saved recipes in one convenient location. Organize recipes into lists tailored to your needs, such as 'Weeknight Dinners' or 'Holiday Baking.' Quickly find recipes using keywords or ingredients.",
    },
    {
      imageSrc: variants,
      title: "Recipe Variants",
      description:
        "Explore variations to adjust recipes to your taste. Discover different ways to prepare your favorite dishes.",
    },
    {
      imageSrc: edit,
      title: "Edit and Customize Recipes",
      description:
        "Modify recipes to suit your preferences and save the changes. Personalize your culinary creations to fit your taste.",
    },
    {
      imageSrc: shareRecipe,
      title: "Share Recipes",
      description:
        "Share your recipe collections with friends and family. They can view and download recipes with a simple link.",
    },
    {
      imageSrc: community,
      title: "The Chopping Block Community",
      description:
        "Join a vibrant community where Cook-E as well as Explorer and Foodie level members share their recipes and collections! Contribute your own recipes, comment on others, and build your culinary network. (Coming Soon)",
    },
    {
      imageSrc: shareRecipe,
      title: "Share Recipes",
      description:
        "Share your recipe collections with friends and family. They can view and download recipes with a simple link.",
    },
  ];

  if (!cards) cards = defaultCards;

  return (
    <>
      <ThreeColumnContainer>
        {subheading && (
          <Subheading style={{ color: "#f48c06" }}>{subheading}</Subheading>
        )}

        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <div className="card">
                <span className="imageContainer">
                  <img src={card.imageSrc || defaultCardImage} alt="" />
                </span>
                <span className="textContainer">
                  <span className="title">{card.title || "Feature Title"}</span>
                </span>
              </div>
              <p className="description">
                {card.description ||
                  "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
              </p>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>

      <div
        style={{
          marginTop: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
      </div>
    </>
  );
};
