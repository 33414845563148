import React from "react";
import { useState } from "react";

const Faqs = () => {
  const [faqs, setFaqs] = useState([
    {
      heading: "How can I manage my recipes in Cook-E?",
      content:
        "In Cook-E, you can manage your recipes through the Cookbook Management feature. This allows you to change the list position of dishes, and reorganize based on preference. You can also edit, delete, and add new recipes.",
      shown: false,
    },
    {
      heading: "What is the Interactive Recipe Experience in Cook-E?",
      content:
        "The Interactive Recipe Experience in Cook-E includes a speech function enabling users to listen to dishes and recipe sections. This feature is available in mid and top tier memberships and allows users to select from several voices via settings.",
      shown: false,
    },
    {
      heading: "Can I share recipes with friends on Cook-E?",
      content:
        "Yes, Cook-E has a Social & Sharing feature. You can share recipes directly with friends, view recipes shared with you, and log your shared recipes. There's also a 'Share with another user' feature for direct recipe sharing using Cook-E usernames.",
      shown: false,
    },
    {
      heading: "What is the 'Ask a Question' feature in Cook-E?",
      content:
        "The 'Ask a Question' feature in Cook-E provides context-aware answers to your queries about specific dishes, their process, ingredients, or utensils. This feature is available on the dish’s saved details page/pop-up for Tier 2 and 3 members.",
      shown: false,
    },
    {
      heading: "How does the Recipe Free Trial work in Cook-E?",
      content:
        "New users in Cook-E get 5 recipe credits for free upon signing up. These credits can be used before any subscription message appears. If you upgrade your account before using all 5 recipes, the remaining credits roll into your subscription.",
      shown: false,
    },
    {
      heading: "How can I cancel my Cook-E subscription?",
      content:
        "You can cancel your Cook-E subscription at any time. To do so, navigate to the Account Management page and select the 'Cancel Subscription' option. You will be asked to confirm your cancellation before it is processed.",
      shown: false,
    },
    {
      heading: "How can I change my Cook-E subscription?",
      content:
        "You can change your Cook-E subscription at any time. To do so, navigate to the Account Management page and select the 'Change Subscription' option. You will be asked to confirm your subscription change before it is processed.",
      shown: false,
    },
    {
      heading: "What can I ask my Recipe Assistant?",
      content:
        "Chat AI in Cook-E is available for all users. You can interact with Chat AI by asking questions like what drinks would pair well with this dish? what can I substitute the pickles for? or How do I roll the sushi together? and much more!",
      shown: false,
    },
  ]);

  // Use setFaqs function to update your FAQs state
  // setFaqs([...faqs, ...additionalFaqs]);

  return (
    <div id="faqs">
      <div className="w-100 mt-20 mb-20" id="faq_section">
        <div className="flex justify-center text-center fw-bold fs-2">
          <div>
            <div className="text-4xl font-semibold">
              <span>
                Frequently Asked{" "}
                <span className="text-4xl text-indigo-700 font-semibold">
                  Questions
                </span>
              </span>
            </div>
          </div>
        </div>
        <div
          className="flex justify-center mt-20
        "
        >
          <div
            className="w-6/12 p-10 bg-indigo-200 rounded-lg shadow-lg
          "
          >
            {faqs.map((item, index) => {
              return (
                <div
                  key={index}
                  id={`accordion-flush-${index}`}
                  className="border-b border-indigo-300 py-4"
                  data-accordion="collapse"
                  data-active-classes="bg-white dark:bg-gray-900 text-gray-900 dark:text-white"
                  data-inactive-classes="text-gray-500 dark:text-gray-400"
                >
                  <h2 id={`accordion-flush-heading-${index}`}>
                    <button
                      type="button"
                      className={`flex justify-between items-center py-2 w-full font-medium text-left ${
                        item.shown === false ? "text-gray-900" : "text-gray-900"
                      } border-gray-200`}
                      data-accordion-target="#accordion-flush-body-1"
                      aria-expanded="true"
                      aria-controls={`accordion-flush-body-${index}`}
                      style={{
                        border: "none",

                        fontWeight: "300",
                      }}
                      onClick={() => {
                        let newFaqs = [...faqs];
                        faqs[index].shown = !faqs[index].shown;
                        setFaqs(newFaqs);
                      }}
                    >
                      <span className="font-semibold text-xl">
                        {item.heading}
                      </span>
                      <svg
                        data-accordion-icon
                        className={`w-6 h-6 ${
                          item.shown === true ? "rotate-180" : ""
                        } shrink-0`}
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </h2>
                  <div
                    id={`accordion-flush-body-${index}`}
                    className={`${
                      item.shown === true ? "bg-gray-100 p-10" : "hidden"
                    } pb-3`}
                    aria-labelledby={`accordion-flush-heading-${index}`}
                  >
                    <div className="py-2 border-teal-700">
                      <p className="mb-2 font-medium text-gray-800 dark:text-gray-400 text-justify">
                        {item.content}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
