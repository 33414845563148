import styled from "styled-components";
import { Link } from "react-router-dom";

export const RecipeItemContainer = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 600px;
  gap: 20px;
  border-radius: 30px;
  overflow: hidden;
  background: var(--indigo-300);

  align-items: center;
  padding: 30px;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

  &:nth-child(even) {
    background: var(--gray-50);
    p {
      color: var(--indigo-900);
    }
  }

  .title {
    color: var(--teal-900);
    font-weight: bold;
    font-size: 19px;
    text-align: center;
  }
  .description {
    color: var(--teal-900);
    font-size: 15px;
    text-align: center;
  }

  &img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:hover div img {
    transform: scale(1.1);
  }
`;

export const RecipeImageContainer = styled.div`
  width: 100%;
  height: 268px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.4s;
  }
`;
