import styled from "styled-components";
import PageHeader from "../../../components/PageHeader/index";
import { BasePageContainer } from "../../../styles/global";

export const RecipeContainer = styled(BasePageContainer)`
  margin-bottom: 60px;

  h3 {
    color: var(--gray-900);
    font-size: 30px;
    margin-top: 60px;
    margin-bottom: 30px;
  }

  h4 {
    font-weight: 500;
    font-size: 20px;
    line-height: 40px;
  }
  .desc {
    font-size: 18px;
    line-height: 25px;
    color: white;
  }
`;

export const RecipeItemContainer = styled("div")`
  display: flex;
  flex-direction: column;

  gap: 20px;
  border-radius: 30px;
  overflow: hidden;
  background: var(--indigo-300);

  align-items: center;
  padding: 30px;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

  .title {
    font-weight: bold;
    font-size: 40px;
    text-align: center;
    color: var(--gray-900);
    margin: 20px 0px;
  }
  p {
    color: var(--gray-800);
  }

  img {
    width: 300px;
    height: 300px;
    object-fit: cover;
    margin: 30px auto;
    border-radius: 10px;
  }

  ul,
  ol {
    list-style-type: disc;
    margin-left: 20px;
    margin-top: 20px;
    font-size: 20px;
    color: var(--gray-800);
  }

  .published {
    color: var(--gray-800);
    font-size: 18px;
    text-align: right;
  }

  strong,
  h4,
  em {
    color: var(--gray-800);
    padding: 10px 0px;
  }
`;
