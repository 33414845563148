import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert";
import DragHandleIcon from "../assets/icons/dragHandleIcon";
import { auth } from "../config/firebase";
import Star from "../images/icons/star";
import StarFilled from "../images/icons/starFilled";
import { setRecipeCard } from "../redux/recipeCard";
import { extractDishTitle } from "../utils/extractTitle";
import {
  createShortUrlByFirebase,
  generateShortLink,
  storeShortUrlData,
} from "../utils/getShortnerLink";
import SocialShareButton from "./SocialShareButton";
import EditRecipe from "./alerts/EditRecipe";
import { playAudio } from "../utils/btnAudio";
import Markdown from "react-markdown";
import styles from "../components/open-ai/GenerateInstructions.module.css";
import RecipeNotes from "./alerts/RecipeNotes";

export default function RecipeCard({
  handleChangeL,
  listValue,
  onClickRC,
  lists,
  listName,
  image,
  title,
  description,
  onClick,
  Customize,
  db,
  id,
  getCookBoookData,
  clickedListId,
  bookmarked,
  updateBookmarkedStatus,
  dragHandleProps,
  shareUrl,
  notes = [],
}) {
  const navigate = useNavigate();
  let [isOpen2, setIsOpen2] = useState(false);
  let [deleteR, setDeleting] = useState(false);
  const [recipeNotesModal, setRecipeNotesModal] = useState(false);
  const [updatedTitle, setUpdatedTitle] = useState(title);
  const [updatedDescription, setUpdatedDescription] = useState(description);
  const [socialShareUrl, setSocialShareUrl] = useState("");
  const [recipeNotes, setRecipeNotes] = useState(notes);
  const user = auth.currentUser;

  function closeModal2(id, imageUrl) {
    updateRecipeTitle(id, imageUrl);
  }

  function close2() {
    setIsOpen2(false);
  }

  function openModal2() {
    playAudio();
    Swal({
      title: "A Small Tip!",
      text: "Once you start editing the recipe, kindly don't play with the formatting and special characters as it is for better user experience.",
      icon: "warning",
      buttons: {
        confirm: {
          text: "Okay",
          value: true,
          visible: true,
          className: "btn btn-primary",
          closeModal: true,
        },
      },
      dangerMode: true,
    }).then((isConfirm) => {
      if (isConfirm) {
        setIsOpen2(true);
      }
    });
  }

  const deleteRecipe = async () => {
    playAudio();
    setDeleting(true);
    try {
      if (clickedListId !== null) {
        const docRef = doc(db, "CookBook", id);
        await updateDoc(docRef, {
          listId: null,
        });
        getCookBoookData();
      } else {
        const movieDoc = doc(db, "CookBook", id);
        await deleteDoc(movieDoc);
        getCookBoookData();
      }
      Swal({
        icon: "success",
        title: "Recipe deleted successfully",
        showConfirmButton: false,
        timer: 3000,
        confirmButtonColor: "#f0481a",
      });
    } catch (error) {
      Swal({
        icon: "info",
        title: error.message,
        showConfirmButton: false,
        timer: 2000,
      });
    }
    setDeleting(false);
  };

  const updateRecipeTitle = async (id, imageUrl) => {
    let listName = listValue === "General" ? null : listValue;
    listName = listName === "" ? null : listValue;

    try {
      const movieDoc = doc(db, "CookBook", id);

      await updateDoc(movieDoc, {
        title: updatedTitle,
        listId: listName,
        description: updatedDescription,
        imageUrl,
      });
      setIsOpen2(false);
      getCookBoookData();
      Swal({
        icon: "success",
        title: "Recipe updated successfully",
        showConfirmButton: false,
        timer: 3000,
        confirmButtonColor: "#f0481a",
      });
    } catch (error) {
      Swal({
        icon: "info",
        title: error.message,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };
  const updateRecipeNotes = async (notes) => {
    try {
      const movieDoc = doc(db, "CookBook", id);
      await updateDoc(movieDoc, {
        notes,
      });
      setRecipeNotesModal(false);
      Swal({
        icon: "success",
        title: "Notes updated successfully",
        showConfirmButton: false,
        timer: 3000,
        confirmButtonColor: "#f0481a",
      });
    } catch (error) {
      Swal({
        icon: "info",
        title: error.message,
        showConfirmButton: false,
        timer: 2000,
      });
    }

    // getCookBoookData()
  };

  const dispatch = useDispatch();

  const viewDetails = () => {
    dispatch(
      setRecipeCard({
        title,
        description,
        image,
      })
    );
    navigate(
      `/cookbook-details/dish-details/${id}?userid=${auth?.currentUser?.uid}`
    );
  };

  useEffect(() => {
    const generateLink = async () => {
      let shortUrl = shareUrl;
      if (!shareUrl) {
        const DOMAIN_NAME = "https://cookeai.com";
        if (user?.uid) {
          const shareUrl =
            DOMAIN_NAME +
            "/cookbook-details/dish-details/" +
            id +
            "?userid=" +
            user?.uid;
          let { link, data } = await createShortUrlByFirebase(shareUrl);
          // shortUrl = await generateShortLink(shareUrl);
          shortUrl = link;
          updateRecipeShareUrl(shortUrl);
          storeShortUrlData(data);
        }
      }
      setSocialShareUrl(shortUrl);
    };

    generateLink();
  }, [id]);

  const updateRecipeShareUrl = async (url) => {
    try {
      const movieDoc = doc(db, "CookBook", id);
      const abc = await updateDoc(movieDoc, {
        shareUrl: url,
      });
    } catch (error) {
      console.log("heree", error);
    }
  };


  return (
    <div
      className="flex 
        transition ease-in-out delay-100 pb-2 md:hover:translate-x-2 relative
        "
    >
      {/* {clickedListId == null && ( */}
      <div {...dragHandleProps} className="absolute top-2 z-10  right-0">
        <DragHandleIcon />
      </div>
      {/* )} */}
      <div className="rounded-xl h-auto w-full flex space-x-4 flex-col md:flex-col bg-opacity-40 bg-white md:p-6 p-4 relative">
        <div
          className="absolute top-2 left-2 flex cursor-pointer"
          onClick={() => updateBookmarkedStatus(id, !bookmarked)}
        >
          {bookmarked ? (
            <StarFilled fill={"yellow"} />
          ) : (
            <Star fill={"white"} />
          )}
        </div>
        <div className="flex gap-5">
          <div className="flex flex-[30%]  justify-center items-center">
            <img
              className="mb-3  rounded-xl w-full "
              width="100"
              height="100"
              src={image}
              alt="Icon 03"
            />
          </div>
          <div className="flex-[70%]">
            <div
              className={styles.markdown}
              style={{
                fontSize: "1rem",
              }}
            >
              {/* ignore first line as it is a heading */}

              <Markdown>{description.substring(0, 270) + "..."}</Markdown>
            </div>
          </div>
        </div>
        <div className="text-right flex flex-row-reverse justify-between flex-wrap gap-4 mt-2">
          {/* <button
            onClick={openModal2}
            type="button"
            className="inline-flex text-black justify-center rounded-md bg-primary-600 border-2 border-primary-600  px-4 py-2 text-sm font-medium  focus-visible:ring-2 hover:brightness-150"
          >
            Edit <span className="hidden sm:flex ml-1">Recipe</span>
          </button> */}
          {/* <button
            onClick={() => {
              setRecipeNotesModal(true);
              setRecipeNotes(notes);
            }}
            type="button"
            className="inline-flex text-black justify-center rounded-md !bg-primary-600 border-2 border-primary-600  px-4 py-2 text-sm font-medium  focus-visible:ring-2 hover:brightness-150"
          >
            Notes
          </button> */}

          <button
            onClick={() => {
              playAudio();
              viewDetails();
              // onClickRC();
            }}
            type="button"
            className="inline-flex text-white justify-center rounded-md border border-transparent !bg-[#2a93dd40] px-4 py-2 text-sm font-medium  focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 hover:brightness-150"
          >
            {" "}
            View <span className="hidden sm:flex ml-1">Details</span>
            {/* <span className="tracking-normal group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">-&gt;</span> */}
          </button>
          <SocialShareButton
            shareUrl={socialShareUrl}
            image={image}
            title={extractDishTitle(title, 2)}
          />

          <button
            onClick={deleteRecipe}
            type="button"
            className="inline-flex text-white justify-center rounded-md border border-transparent !bg-red-400 px-4 py-2 text-sm font-medium  hover:bg-orange-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
          >
            {" "}
            {deleteR ? "Deleting..." : "Delete"}
            <span className="hidden sm:flex ml-1">Recipe</span>
            {/* <span className="tracking-normal group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">-&gt;</span> */}
          </button>
        </div>
      </div>
      <EditRecipe
        listName={listName}
        listValue={listValue}
        lists={lists}
        handleChangeL={handleChangeL}
        isOpen={isOpen2}
        name="updatedTitle"
        handleCHange={(e) => setUpdatedTitle(e.target.value)}
        image={image}
        value={updatedTitle}
        nameD="updatedDescription"
        handleCHangeD={(e) => setUpdatedDescription(e.target.value)}
        valueD={updatedDescription}
        close={close2}
        closeModal={(imageUrl) => closeModal2(id, imageUrl)}
      />
      <RecipeNotes
        isOpen={recipeNotesModal}
        close={() => setRecipeNotesModal(false)}
        recipeCardData={() => console.log("close")}
        closeModal={() => console.log("close")}
        recipeNotes={recipeNotes}
        setRecipeNotes={setRecipeNotes}
        recipeTitle={updatedTitle}
        recipeDescription={updatedDescription}
        onSubmit={updateRecipeNotes}
      />
    </div>
  );
}
