import RecipeItem from "../BlogItem";
import { RecipesListContainer } from "./styles";

function RecipesList({ recipes }) {
  return (
    <RecipesListContainer>
      {recipes.map((item) => {
        return <RecipeItem key={item.id} article={item} />;
      })}
    </RecipesListContainer>
  );
}

export default RecipesList;
