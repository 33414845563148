const updateUserTokens = async (
  firestoreDB,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  userId,
  name
) => {
  try {
    const usersCollectionRef = collection(firestoreDB, "Users");
    const userQuery = query(usersCollectionRef, where("userId", "==", userId));
    const userSnapshot = await getDocs(userQuery);

    if (!userSnapshot.empty) {
      const userDoc = userSnapshot.docs[0];
      const userData = userDoc.data();
      const updatedUsedDownloadTokens = userData.usedDownloadTokens + 1;
      const updatedUsedSearchTokens = userData.usedSearchTokens + 1;

      const usedRecipeTokens = isNaN(userData.usedRecipeTokens)
        ? 0
        : userData.usedRecipeTokens;
      const updatedUsedRecipeTokens = usedRecipeTokens + 1;

      // const updatedUsedRecipeTokens = userData.usedRecipeTokens + 1;

      if (name === "download") {
        await updateDoc(doc(usersCollectionRef, userDoc.id), {
          usedDownloadTokens: updatedUsedDownloadTokens,
        });
      } else if (name === "recipe") {
        await updateDoc(doc(usersCollectionRef, userDoc.id), {
          usedRecipeTokens: updatedUsedRecipeTokens,
        });
      } else {
        await updateDoc(doc(usersCollectionRef, userDoc.id), {
          usedSearchTokens: updatedUsedSearchTokens,
        });
      }

      return true;
    } else {
      console.log("No document found with the specified user ID.");
      return false;
    }
  } catch (error) {
    console.error("Error updating tokens:", error);
    return false;
  }
};

export { updateUserTokens };
