import React, { useEffect, useState } from "react";

// import { ChevronUpIcon } from "@heroicons/react/20/solid";
import speakerIcon from "../assets/images/icons8-speaker.png";
import { playAudio } from "../utils/btnAudio";
const voiceSpeedRatio = {
  slow: {
    pitch: 1.0,
    rate: 0.7,
  },
  standard: {
    pitch: 1.2,
    rate: 1.0,
  },
  fast: {
    pitch: 1.5,
    rate: 1.5,
  },
};
const TextToSpeech = ({ displayedText }) => {
  const [voices, setVoices] = useState([]);
  const [selectedVoice, setSelectedVoice] = useState("");
  const [text, setText] = useState(displayedText);
  const [currentSpeed, setCurrentSpeed] = useState("standard");
  const [pitch, setPitch] = useState(voiceSpeedRatio?.standard?.pitch);
  const [rate, setRate] = useState(voiceSpeedRatio?.standard?.rate);
  const [chunks, setChunks] = useState([]);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [isPaused, setIsPaused] = useState(false); // New state to track pause status
  const [currentChunkIndex, setCurrentChunkIndex] = useState(0);
  const [openPanel, setOpenPanel] = useState(false);
  const synth = window.speechSynthesis;
  const [synthState, setSynthState] = useState(synth);
  var utterThis;

  useEffect(() => {
    const cleanedParagraph = displayedText?.replace("**", "").replace("##", "");

    setText(cleanedParagraph);
    const sentences = cleanedParagraph.split(".");
    setChunks(sentences);
    return () => {
      synth.cancel();
      setIsSpeaking(false);
    };
  }, [displayedText]);

  useEffect(() => {
    const populateVoiceList = () => {
      const allVoices = synth.getVoices();
      const englishVoices = allVoices.filter((voice) =>
        voice.lang.startsWith("en-US")
      );
      setVoices(englishVoices);
    };

    populateVoiceList();
    if (
      typeof speechSynthesis !== "undefined" &&
      speechSynthesis.onvoiceschanged !== undefined
    ) {
      speechSynthesis.onvoiceschanged = populateVoiceList;
    }

    // Cleanup function
    return () => {
      speechSynthesis.onvoiceschanged = null;
    };
  }, []);

  let abc = 0;
  const speakNextChunk = () => {
    if (currentChunkIndex < chunks.length) {
      utterThis = new SpeechSynthesisUtterance(displayedText);

      const selectedVoiceObj = voices.find(
        (voice) => voice.name === selectedVoice
      );
      if (selectedVoiceObj) {
        utterThis.voice = selectedVoiceObj;
      }
      utterThis.pitch = parseFloat(pitch);
      utterThis.rate = parseFloat(rate);

      setSynthState(utterThis);
      utterThis.onend = () => {
        abc = currentChunkIndex;
        setCurrentChunkIndex((prev) => prev + 1);
        speakNextChunk();
      };

      synth.speak(utterThis);
      setIsSpeaking(true);
    } else {
      setIsSpeaking(false);
    }
  };

  const speakRecipe = (event) => {
    playAudio();
    event.preventDefault();

    utterThis = new SpeechSynthesisUtterance(displayedText);

    const selectedVoiceObj = voices.find(
      (voice) => voice.name === selectedVoice
    );
    if (selectedVoiceObj) {
      utterThis.voice = selectedVoiceObj;
    }
    utterThis.pitch = parseFloat(pitch);
    utterThis.rate = parseFloat(rate);
    setSynthState(utterThis);
    synth.speak(utterThis);
    setIsSpeaking(true);
    setIsPaused(false);
    // setCurrentChunkIndex(0);
    // speakNextChunk();
  };

  const resumeAfterChange = (pitch, rate) => {
    // event.preventDefault();

    utterThis = new SpeechSynthesisUtterance(displayedText);
    const selectedVoiceObj = voices.find(
      (voice) => voice.name === selectedVoice
    );
    if (selectedVoiceObj) {
      utterThis.voice = selectedVoiceObj;
    }
    utterThis.pitch = parseFloat(pitch);
    utterThis.rate = parseFloat(rate);

    synth.speak(utterThis);
    setIsSpeaking(true);
    setIsPaused(false);
  };

  const handlePauseResume = () => {
    playAudio();
    if (synth.speaking) {
      if (synth.paused) {
        synth.resume();
        setIsPaused(false); // Update state when resumed
      } else {
        synth.pause();
        setIsPaused(true); // Update state when resumed
      }
    }
  };

  const handleStop = () => {
    if (synth.speaking) {
      synth.cancel();
      setIsSpeaking(false);
    }
  };

  const onSpeedButtonClick = async (speed) => {
    if (synth.speaking) {
      synth.pause();
      setPitch(voiceSpeedRatio[speed]?.pitch);
      setRate(voiceSpeedRatio[speed]?.rate);
      setCurrentSpeed(speed);

      // utterThis.pitch = parseFloat(voiceSpeedRatio[speed]?.pitch);
      // utterThis.rate = parseFloat(voiceSpeedRatio[speed]?.rate);
      // synth.resume();
      synth.cancel();

      setTimeout(() => {
        resumeAfterChange(
          voiceSpeedRatio[speed]?.pitch,
          voiceSpeedRatio[speed]?.rate
        );
      }, 3000);

      // setTimeout(() => {
      //   speakRecipe(); // Start a new speech with the updated settings
      // }, 1000);
    } else {
      setCurrentSpeed(speed);
      setPitch(voiceSpeedRatio[speed]?.pitch);
      setRate(voiceSpeedRatio[speed]?.rate);
    }
  };
  // const onSpeedButtonClick = (speed) => {
  //   if (synth.speaking) {
  //     setPitch(voiceSpeedRatio[speed]?.pitch);
  //     setRate(voiceSpeedRatio[speed]?.rate);
  //     setCurrentSpeed(speed);

  //     // Create a new utterance
  //     utterThis = new SpeechSynthesisUtterance(displayedText);
  //     const selectedVoiceObj = voices.find(
  //       (voice) => voice.name === selectedVoice
  //     );
  //     if (selectedVoiceObj) {
  //       utterThis.voice = selectedVoiceObj;
  //     }
  //     utterThis.pitch = parseFloat(pitch);
  //     utterThis.rate = parseFloat(rate);

  //     // Set up the onend event to resume speech
  //     utterThis.onend = () => {
  //       console.log("Speech ended, resuming...");
  //       speakRecipe();
  //     };

  //     // Cancel the current speech and start a new one after a delay
  //     synth.cancel();
  //     setTimeout(() => {
  //       synth.speak(utterThis);
  //       setIsSpeaking(true);
  //       setIsPaused(false);
  //     }, 1000); // Adjust the delay as needed
  //   } else {
  //     setCurrentSpeed(speed);
  //     setPitch(voiceSpeedRatio[speed]?.pitch);
  //     setRate(voiceSpeedRatio[speed]?.rate);
  //   }
  // };

  return (
    <>
      <div className="w-full  pt-16">
        <div className="flex  justify-start items-center w-full gap-4 flex-wrap">
          {!openPanel ? (
            <button
              onClick={() => {
                playAudio();
                setOpenPanel(true);
              }}
              className="
          !bg-gradient-to-r from-orange-100 to-orange-50  md:mx-0 
          font-medium  flex items-center justify-center border border-transparent md:w-40 w-full p-2.5
           my-2 rounded text-white bg-teal-500 hover:bg-teal-400 transition duration-150 ease-in-out"
              // disabled={loading}
            >
              Listen to Recipe{" "}
              <img
                src={speakerIcon}
                alt={"speaker icon"}
                width={24}
                height={24}
              />
            </button>
          ) : (
            <>
              <div className="flex items-center gap-2 ">
                {isSpeaking ? (
                  <>
                    {" "}
                    <button
                      type="button"
                      onClick={handlePauseResume}
                      className="
        !bg-gradient-to-r from-orange-100 to-orange-50  md:mx-0 
        font-medium  flex items-center justify-center border border-transparent w-[100px] p-2.5
         my-2 rounded text-white bg-teal-500 hover:bg-teal-400 transition duration-150 ease-in-out"
                      // disabled={!isSpeaking}
                    >
                      {isPaused ? "Resume" : "Pause"}
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        playAudio();
                        handleStop();
                      }}
                      className="
            !bg-gradient-to-r from-orange-100 to-orange-50  md:mx-0 
            font-medium  flex items-center justify-center border border-transparent w-[100px] p-2.5
             my-2 rounded text-white bg-teal-500 hover:bg-teal-400 transition duration-150 ease-in-out"
                    >
                      Stop
                    </button>
                  </>
                ) : (
                  <button
                    onClick={speakRecipe}
                    className="
          !bg-gradient-to-r from-orange-100 to-orange-50  md:mx-0 
          font-medium  flex items-center justify-center border border-transparent w-[100px] p-2.5
           my-2 rounded text-white bg-teal-500 hover:bg-teal-400 transition duration-150 ease-in-out"
                    // disabled={loading}
                  >
                    Play <speakerIcon />
                  </button>
                )}
              </div>
              <div>
                <label>
                  <select
                    value={selectedVoice}
                    onChange={(e) => {
                      setSelectedVoice(e.target.value);
                      handleStop();
                    }}
                    className="p-1.5 w-[200px] rounded"
                  >
                    {voices?.map((voice, index) => (
                      <option key={voice.name} value={voice.name}>
                        {`${voice.name} (${voice.lang})`}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <div className="flex items-center gap-4 flex-wrap">
                <button
                  type="button"
                  onClick={() => {
                    playAudio();
                    onSpeedButtonClick("slow");
                  }}
                  className={`
        ${
          currentSpeed == "slow"
            ? "!bg-teal-500 hover:bg-teal-400"
            : "!bg-gradient-to-r !from-orange-100 !to-orange-50"
        }  md:mx-0 
        font-medium  flex items-center justify-center border border-transparent w-[100px] p-2.5
         my-2 rounded text-white transition duration-150 ease-in-out`}
                  // disabled={!isSpeaking}
                >
                  {"Slow"}
                </button>
                <button
                  type="button"
                  onClick={() => {
                    playAudio();
                    onSpeedButtonClick("standard");
                  }}
                  className={`
        ${
          currentSpeed == "standard"
            ? "!bg-teal-500 hover:bg-teal-400"
            : "!bg-gradient-to-r !from-orange-100 !to-orange-50"
        }  md:mx-0 
        font-medium  flex items-center justify-center border border-transparent w-[100px] p-2.5
         my-2 rounded text-white transition duration-150 ease-in-out`}
                >
                  {"Standard"}
                </button>
                <button
                  type="button"
                  onClick={() => {
                    playAudio();
                    onSpeedButtonClick("fast");
                  }}
                  className={`
        ${
          currentSpeed == "fast"
            ? "!bg-teal-500 hover:bg-teal-400"
            : "!bg-gradient-to-r !from-orange-100 !to-orange-50"
        }  md:mx-0 
        font-medium  flex items-center justify-center border border-transparent w-[100px] p-2.5
         my-2 rounded text-white transition duration-150 ease-in-out`}
                >
                  {"Fast"}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default TextToSpeech;

{
  /* <div className="my-4 voice-range">
                      <label>
                        Pitch:
                        <input
                          type="range"
                          min="0.5"
                          max="2"
                          step="0.1"
                          value={pitch}
                          onChange={(e) => {
                            setPitch(e.target.value);
                            handleStop();
                          }}
                          className="mx-2 hover:outline-none"
                        />
                        {pitch}
                      </label>
                    </div>
                    <div>
                      <label>
                        Rate:
                        <input
                          type="range"
                          min="0.5"
                          max="3"
                          step="0.2"
                          value={rate}
                          onChange={(e) => {
                            setRate(e.target.value);
                            handleStop();
                          }}
                          className="mx-2"
                        />
                        {rate}
                      </label>
                    </div> */
}
