const updateUserSubscriptionPlan = async (
  firestoreDB,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  userId,
  plan
) => {
  let subScriptionPlan = "";
  if (plan === "Basic Membership") subScriptionPlan = "Basic";
  else if (plan === "Explorer Membership") subScriptionPlan = "Explorer";
  else if (plan === "Foodie Membership") subScriptionPlan = "Foodie";
  else if (plan === "A La Carte") subScriptionPlan = "A La Carte";

  const payload = {
    subScriptionPlan: subScriptionPlan,
  };

  try {
    const usersCollectionRef = collection(firestoreDB, "Users");
    const userQuery = query(usersCollectionRef, where("userId", "==", userId));
    const userSnapshot = await getDocs(userQuery);

    if (!userSnapshot.empty) {
      const userDoc = userSnapshot.docs[0];

      await updateDoc(doc(usersCollectionRef, userDoc.id), payload);

      console.log("Subscription plan updated successfully!");
      return true;
    } else {
      console.log("No document found with the specified user ID.");
      return false;
    }
  } catch (error) {
    console.error("Error updating subscription plan:", error);
    return false;
  }
};

export { updateUserSubscriptionPlan };
