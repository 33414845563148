import React from "react";

export default function DisheOption({
  title,
  name,
  value,
  handleChange,
  placeholder,
  isDishePages,
  beta,
}) {
  return (
    <div className="flex w-full md:my-2 my-1.5 md:space-x-2 md:space-y-0 space-y-1 md:flex-row sm:px-4 flex-col md:justify-center md:items-center">
      <div>
        <div
          className={`py-2 px-4 md:px-0 md:py-2.5 md:w-44 w-fit flex-[25%] rounded-full justify-center text-white flex 
                            bg-gradient-to-r ${
                              isDishePages
                                ? "from-orange-100 to-orange-50 "
                                : "from-teal-300 to-teal-500"
                            }`}
        >
          {title}
        </div>

        {/* only for the sake of styling, not visible */}
        {beta && (
          <label
            htmlFor=""
            className="text-sm text-transparent ml-2 hidden sm:block"
          >
            &#8226; Feature is in Early Beta
          </label>
        )}
      </div>
      <div className="flex-[65%] ">
        <input
          name={name}
          value={value}
          type="text"
          placeholder={placeholder}
          onChange={handleChange}
          className="rounded-full text-start  text-gray-900 placeholder-gray-400  bg-slate-100 pl-3 w-full py-2.5"
        />
        {beta && (
          <label htmlFor="" className="text-sm text-orange-50 ml-2">
            &#8226; Feature is in Early Beta
          </label>
        )}
      </div>
    </div>
  );
}
