import React, { useState } from "react";
import bigTitle from "../assets/images/bigTitle.png";
import justAsk from "../assets/images/justAsk.png";
import recipe from "../assets/images/recipe.png";
import appStore from "../assets/images/appStore.png";
import playStore from "../assets/images/playStore.png";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import homeImageBackground from "../assets/images/homeBackground.png";
import { useSelector } from "react-redux";
import { firestoredDB } from "../config/firebase";
import { addDoc, collection, getDocs, where } from "firebase/firestore";
import swal from "sweetalert";
import { isValidEmail } from "../utils/checkEmail";
import { query } from "firebase/database";
import support_icon from "../assets/images/Support.svg";
import { playAudio } from "../utils/btnAudio";

export default function HeroHome() {
  const navigate = useNavigate();

  const { isLoggedIn, user } = useSelector((state) => state.auth);
  // email usestate
  const [email, setEmail] = useState("");

  const subscribersRef = collection(firestoredDB, "Subscribers");

  const onSubmitEmail = async (e) => {
    e.preventDefault();

    if (!isValidEmail(email)) {
      swal({
        icon: "info",
        title: "Please enter a valid Email!",
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    }
    try {
      // Check if the email already exists in the "Subscribers" collection
      const q = query(subscribersRef, where("email", "==", email));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        swal({
          icon: "info",
          title: "Email already subscribed!",
          showConfirmButton: false,
          timer: 2000,
        });
        return;
      }

      await addDoc(subscribersRef, {
        // userId: user?.user?.uid,
        email: email,
      });

      swal({
        icon: "success",
        title: "You have successfully subscribed to Cook-E",
        showConfirmButton: false,
        timer: 2000,
        confirmButtonColor: "#f0481a",
      });
    } catch (err) {
      console.log(err.message);

      swal({
        icon: "info",
        title: err.message,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  return (
    <section className="relative h-full">
      <div className=" mx-0 px-4 flex justify-center items-center ">
        <div className=" h-full w-full">
          <div className="mx-auto flex h-full flex-col justify-center items-center w-full">
            {/* Background image */}

            <div className=" flex lg:flex-row flex-col h-[60%] w-[100%]">
              <motion.div
                // className="md:-mt-20"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ duration: 0.5 }}
                variants={{
                  hidden: { opacity: 0, x: -50 },
                  visible: { opacity: 1, x: 0 },
                }}
                className="md:flex-[60%] flex-col justify-center"
              >
                <div className="md:space-y-6 flex flex-col justify-between h-full py-2">
                  <img
                    src={bigTitle}
                    className="w-auto h-auto p-4"
                    alt="desc"
                  />
                  <img src={justAsk} className="w-auto h-auto p-4" alt="desc" />
                  <div>
                    <button
                      onClick={() => {
                        playAudio();
                        if (isLoggedIn) {
                          navigate("/discover-dishes");
                        } else {
                          navigate("/signin");
                        }
                      }}
                      className="md:mt-10 bg-gradient-to-r from-orange-100 to-orange-50 md:ml-[3%] mx-[5%] md:mx-0 w-[90%] lg:w-[40%] font-medium  flex items-center justify-center border border-transparent px-14 py-2.5 my-4 rounded text-white bg-teal-500 hover:bg-teal-400 transition duration-150 ease-in-out"
                    >
                      Get Started!
                    </button>
                  </div>

                  <div className="">
                    <form className="w-2/3 lg:w-1/2 ml-[3.5%] hidden lg:block md:mt-[10px]">
                      <div className="flex justify-center  ">
                        <input
                          type="tel"
                          className=" w-full h-full  pl-4 rounded-l-full  py-3"
                          placeholder="Email"
                          aria-label="Phone number"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <button
                          onClick={(e) => {
                            playAudio();
                            onSubmitEmail(e);
                          }}
                          className="text-white bg-gradient-to-r from-orange-100 to-orange-50
                                      shrink-0 rounded-r-full px-6 text-center"
                        >
                          Join
                        </button>
                      </div>
                      {/* Success message */}
                      <p
                        className=" text-white lg:text-left mt-2
                                 opacity-75 text-sm font-medium ml-3 text-start"
                      >
                        Join our mailing list for exclusive offers!
                      </p>
                    </form>
                  </div>
                </div>
              </motion.div>

              <motion.div
                // className="md:-mt-20"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ duration: 0.5 }}
                variants={{
                  hidden: { opacity: 0, x: +50 },
                  visible: { opacity: 1, x: 0 },
                }}
                className="md:flex-[40%] relative md:h-auto
                     flex flex-col justify-between items-center order-2"
              >
                <img
                  src={recipe}
                  className=" 
                          rounded-full w-auto h-[70%] 2xl:p-16 min-h-[415px] 2xl:h-full
                         px-10 py-2 
                         "
                />
                <form className="w-[90%] mx-[5%]  lg:w-1/2  lg:mt-28 block lg:hidden">
                  <div className="flex justify-center  ">
                    <input
                      type="tel"
                      className="form-input w-full h-full pl-4 rounded-l-full  py-2.5"
                      placeholder="Email"
                      aria-label="Phone number"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <button
                      onClick={(e) => {
                        playAudio();
                        onSubmitEmail(e);
                      }}
                      className="text-white bg-gradient-to-r from-orange-100 to-orange-50
                                      shrink-0 rounded-r-full px-6 text-center"
                    >
                      Join
                    </button>
                  </div>
                  {/* Success message */}
                  <p
                    className=" text-white lg:text-left lg:absolute mt-2
                                 opacity-75 text-sm font-medium text-start"
                  >
                    Join our mailing list for exclusive offers!
                  </p>
                </form>
                <div className="flex flex-col mt-10 justify-center pb-10">
                  <div className="flex space-x-4 justify-center items-center mb-5 md:mb-0">
                    <div>
                      <img src={playStore} />
                    </div>
                    <div>
                      <img src={appStore} />
                    </div>
                  </div>
                  <a
                    href="mailto:user@example.com"
                    className="text-white mt-1 w-fit p-1 lg:hidden absolute bottom-2 left-[50%] translate-x-[-50%] sm:translate-x-0 sm:right-0 ml-auto min-w-[205px]"
                  >
                    Hello
                    <img
                      src={support_icon}
                      alt=""
                      className="inline-block pr-2"
                    />
                    Support@cookeai.com
                  </a>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
